
// This file implements the "ducks" pattern, i.e. combines action constants,
// action creators and reducers in one file instead of having multiple files per
// function. As long as there are not too many values, this makes it nice and
// compact.

// Action constants

import { isAllThreatTypesTrue } from 'common/helpers/datahelpers';

export const THREATTYPESELECTOR_SELECTALL = 'THREATTYPESELECTOR_SELECTALL';
export const THREATTYPESELECTOR_SELECT = 'THREATTYPESELECTOR_SELECT';
export const THREATTYPESELECTOR_RESET = 'THREATTYPESELECTOR_RESET';
export const THREATTYPESELECTOR_SET_VALUES = 'THREATTYPESELECTOR_SET_VALUES';

const allThreatsTrue = {
  IC: true,
  IF: true,
  IP: true,
  S: true,
  SW: true,
  ZF: true,
  ZL: true,
  RS: true,
  SI: true,
  WM: true,
  ZR: true,
  BS: true,
  ZY: true,
  R: true,
  RW: true,
  F: true,
  T: true,
  H: true
}

export const allThreatsFalse = Object.assign(...Object.entries(allThreatsTrue).map(([k]) => ({[k]: false})));

// Reducer

const initialState = {
};

// Action creators

export const selectAllThreats = (cbGroupId, value) => ({
  type: THREATTYPESELECTOR_SELECTALL,
  cbGroupId, value
})

export const selectThreat = (cbGroupId, threat, value) => ({
  type: THREATTYPESELECTOR_SELECT,
  cbGroupId, threat, value
})

export const setValues = (cbGroupId, threatTypes) => ({
  type: THREATTYPESELECTOR_SET_VALUES,
  cbGroupId,
  threatTypes
})

export const resetCheckboxes = (cbGroupId) => ({
  type: THREATTYPESELECTOR_RESET,
  cbGroupId
})

// Reducer

const updateGroup = (slice = {threatTypes: {...allThreatsFalse}}, {cbGroupId, threat, value}) => ({
  ...slice,
  threatTypes: {
    ...slice.threatTypes, 
    [threat]: value
  }
})

const setAll = (cbGroupId, value, allSelected) => ({
  threatTypes: {...value},
  allSelected
})

const threatTypeSelectorReducer = (state = initialState, action) => {
  switch (action.type) {
    case THREATTYPESELECTOR_RESET:
      return {
        ...state,
        [action.cbGroupId]: setAll(action.cbGroupId, allThreatsTrue, true)
      }

    case THREATTYPESELECTOR_SELECT:
      return {
        ...state,
        [action.cbGroupId]: updateGroup(state[action.cbGroupId], action)
      }
    case THREATTYPESELECTOR_SELECTALL:
      return {
        ...state,
        [action.cbGroupId]: setAll(action.cbGroupId, action.value ? allThreatsTrue : allThreatsFalse, action.value)
      }
    case THREATTYPESELECTOR_SET_VALUES:
      return {
        ...state,
        [action.cbGroupId]: action.threatTypes
          ? {threatTypes: action.threatTypes, allSelected: isAllThreatTypesTrue(action.threatTypes)}
          : setAll(action.cbGroupId, allThreatsFalse, false)
      }
    default:
      return state

  }
}

export default threatTypeSelectorReducer;


