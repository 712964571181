export const SUPERADMINPAGE_SELECT_ADMIN = 'SUPERADMINPAGE_SELECT_ADMIN'

export const setSelectedAdmin = (id) => ({
  type: SUPERADMINPAGE_SELECT_ADMIN,
  id
})

const initialState = {
  selectedAdmin: undefined
}

const superAdminPage = (state = initialState, action) => {
    switch(action.type){
      case SUPERADMINPAGE_SELECT_ADMIN:
        return {
          ...state,
          selectedAdmin: action.id
        }

      default :
        return state;
    }
}

export default superAdminPage

