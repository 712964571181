import React from 'react';
import PropTypes from 'prop-types';
import 'common/css/inputElements.css';
import checkBoxEmtpy from 'images/dashboard/checkbox-blank.svg'
import checkBoxFilled from 'images/dashboard/checkbox-filled.svg'
import { FormLabel } from 'react-bootstrap'

/* Usage:

    // somewhere outside render():
    const handleChangeCheckbox = (e) => {
      console.log('Checkbox checked: ', e.target.checked)
    }
    const handleChangeRadio = (e) => {
      console.log('Radio value: ', e.target.value)
    }

    ...
     
    // inside render():
    <div>
      <WwaCheckbox id="checkbox-1" name="My Checkbox"  onChange={handleChangeCheckbox}/>
      <span className='wwa_radio_label'>Ice Crystals</span>
    </div>
    <div>
      <WwaRadio id="radio-1" name="radio-1-set"  value='aaa' onChange={handleChangeRadio}/>
      <span className='wwa_radio_label'>County</span>
      <WwaRadio id="radio-2" name="radio-1-set"  value='bbb' onChange={handleChangeRadio}/>
      <span className='wwa_radio_label'>City</span>
    </div>

*/

const nop = () => {}

const WwaCheckbox = ({id, onChange, checked, disabled=false}) => {
  return (
    <img 
      src={checked ? checkBoxFilled : checkBoxEmtpy} 
      onClick={disabled ? nop : onChange}
      id={id} 
      checked={!checked}
      alt={id}
      style={{opacity: disabled?'0.6':'1'}}
    />
  )
}

// this checkbox is _checked_ when the 'checked' value is false!
const WwaCheckboxInvertedReduxForm = ({id, onChange, disabled=false, input}) => {
  const checked = input.value;
  return (
    <img 
      src={!checked ? checkBoxFilled : checkBoxEmtpy} 
      onClick={disabled ? nop : e => input.onChange(!checked)}
      id={id} 
      alt={id}
      style={{opacity: disabled?'0.2':'1'}}
    />
  )
}

const WwaCheckboxReduxForm = ({id, onChange, disabled=false, input, label}) => {
  const checked = input.value;
  return (
    <>
    <FormLabel className="UserEdit__label">{label}</FormLabel>{' '}
    <img 
      src={checked ? checkBoxFilled : checkBoxEmtpy} 
      onClick={disabled ? nop : e => input.onChange(!checked)}
      id={id} 
      alt={id}
      style={{opacity: disabled?'0.2':'1'}}
    />
    </>
  )
}

WwaCheckbox.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired

}

const WwaRadio = (props) => (
  <span>
    <input id={props.id} type="radio" className="wwa-radio" {...props} />
    <label htmlFor={props.id} />
  </span>
)
WwaRadio.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
}

export { WwaCheckbox, WwaRadio, WwaCheckboxInvertedReduxForm, WwaCheckboxReduxForm  }
