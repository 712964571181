import log from 'loglevel';
import { put, takeEvery, call, select } from 'redux-saga/effects';

import {
  SUPPORTEMAIL_SUBMIT,
  SUPPORTEMAIL_SUCCESS,
  SUPPORTEMAIL_FAILURE
} from './SupportEmailFormDuck';

import { postFormData } from 'utils/fetchApi';

const getblob = async f => {
  const response = await fetch(f);
  const blob = await response.blob();
  return blob;
};

export function* postSupportEmail(action) {
  try {
    const { subject, details, phone, email, attachments} = action.payload;
    const authToken = yield select(state => state.login.authToken);
    let url = '/Contacts/support/email';

    let body = new FormData();
    body.append('subject', subject);
    body.append('details', details);
    if (phone) body.append('phone', phone);
    body.append('responseEmail', email);

    if(attachments)
      for (let file of attachments) {
        if (file){
          const blob = yield call(getblob, file.preview);
          body.append('files', blob, file.name);
        }
      }

    const parsedJson = yield call(postFormData, url, { body, authToken });

    if (!parsedJson.error) {
      yield put({
        type: SUPPORTEMAIL_SUCCESS,
        data: parsedJson
      });
    } else {
      yield put({
        type: SUPPORTEMAIL_FAILURE,
        message: parsedJson.error.text._error
          ? parsedJson.error.text._error
          : parsedJson.error.text,
        payload: parsedJson.error.text
      });
    }
  } catch (error) {
    log.error('postSupportEmail', error);
    yield put({
      type: SUPPORTEMAIL_FAILURE,
      message: 'Network error.'
    });
  }
}

export default function* supportEmailSaga() {
  yield takeEvery(SUPPORTEMAIL_SUBMIT, postSupportEmail);
}
