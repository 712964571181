import log from 'loglevel';
import { put, race, take, takeEvery, call, select } from 'redux-saga/effects'
import fetchApi from 'utils/fetchApi';
import {
  SUBSCRIPTIONDATA_SUBMIT,
  SUBSCRIPTIONDATA_SUCCESS,
  SUBSCRIPTIONDATA_FAILURE,
  APIPACKAGE_ADD_ONTHEFLY,
  SUBSCRIPTION_ACTV_TRIAL_SUBMIT,
} from "./SubscriptionDuck";
import {
  ACTV_TRIAL_SUCCESS,
  ACTV_TRIAL_FAILURE
} from "Admin/ActivateTrialCompany/ActivateTrialCompanyDuck";
import { COMPANYSUBSCRIPTION_SUCCESS, COMPANYSUBSCRIPTION_NOTFOUND } from 'Admin/CompanyEdit/CompanyEditDuck'
import { DATA_FAILURE } from 'common/dataRetrieval/DataDuck';
import { APIPACKAGE_MODAL_STATE, MODAL_OPEN, 
  APIPACKAGEUPSERT_SUCCESS, APIPACKAGEUPSERT_FAILURE } from 'common/ApiPackage/ApiPackageDuck';
import { DATA_SUCCESS } from 'common/dataRetrieval/DataDuck';
import { ROLE_SUPER_ADMIN } from 'common/helpers/roles';
  
function* fetchSubscriptionsCall({companyId: actionCompanyId}){

    try{
        const login = yield select((state) => state.login);
        const authToken = login.authToken;
        const isSuperAdmin = login.roles && login.roles.includes(ROLE_SUPER_ADMIN);
        let companyId = actionCompanyId;
        let url = companyId
          ? `/Companies/${companyId}/Subscriptions`
          : '/Subscriptions'
        // guard: non-super admins must not get all Subscriptions
        if (!companyId && !isSuperAdmin) {
          companyId = login.companyId;
          url = `/Companies/${companyId}/Subscriptions`;
        }
        const parsedJson = yield call(fetchApi, url, {
            authToken,
            method: 'GET'
        })

        if (!parsedJson.error){
          if (companyId){
            yield put({
              type: COMPANYSUBSCRIPTION_SUCCESS,
              subscriptionObj : parsedJson,
              companyId
            });
          } else {
            yield put({
                type: SUBSCRIPTIONDATA_SUCCESS,
                payload : parsedJson
            });
          }
        } else {
          if (!parsedJson.notfound) {
            // no subscription can happen
            yield put({
              type: DATA_FAILURE,
              message: parsedJson.error.text._error ? parsedJson.error.text._error : parsedJson.error.text,
            })
            yield put({
                type: SUBSCRIPTIONDATA_FAILURE
            });
          } else {
            yield put({
                type: COMPANYSUBSCRIPTION_NOTFOUND,
                companyId
            });
          }
        }

    } catch(error){
      log.error('fetchDataSaga error', error)
      yield put({
        type: DATA_FAILURE,
        message: 'Network error.'
      })
    }
}

/* Activate trial company from subscriptions modal. State handling via
 * ActivateTrialCompanyDuck.
  */
function* postTrialActivateCall({companyId}){
    try{
      const login = yield select((state) => state.login);
      const authToken = login.authToken;

      const json = yield call(
        fetchApi,
        `/Subscriptions/activateCompany`,
        {
          authToken,
          payload:{companyId}
        }
      );
      if (!json.error) {
        yield put({
          type: ACTV_TRIAL_SUCCESS,
          data: json,
          activateSuccess: true
        });
        yield put({
          type: SUBSCRIPTIONDATA_SUBMIT,
          companyId
        });
      } else {
        log.error(
          "postActivateTrialCompany api error:",
          JSON.stringify(json.error)
        );
        yield put({
          type: ACTV_TRIAL_FAILURE,
          isCompanyAlreadyActive: (json.error.text || {}).isCompanyAlreadyActive,
          apiError: json.error.text._error
            ? json.error.text._error
            : json.error.text
        });
      }
    } catch(err){
      log.warn("postActivateTrialCompany err", err);
      yield put({ type: ACTV_TRIAL_FAILURE, apiError: "Network error" });
    }
}

export default function* subscriptionSaga () {
  yield takeEvery(SUBSCRIPTIONDATA_SUBMIT, fetchSubscriptionsCall)
  yield takeEvery(SUBSCRIPTION_ACTV_TRIAL_SUBMIT, postTrialActivateCall)
  yield takeEvery(DATA_SUCCESS, fetchSubscriptionsCall)
  yield call(addPackageOnTheFly);
}

function* addPackageOnTheFly() {
  while (true) {
  const action = yield take(APIPACKAGE_ADD_ONTHEFLY)
    yield put({
      type: APIPACKAGE_MODAL_STATE,
      modalState: MODAL_OPEN,
      isOnTheFly: true
    })

    const {newPackage} = yield race({
      newPackage: take(APIPACKAGEUPSERT_SUCCESS),
      failed: take(APIPACKAGEUPSERT_FAILURE),
      modalClosed: take(APIPACKAGE_MODAL_STATE),
    });

    if (newPackage){
      yield put({
        type: 'SUBSCRIPTIONSELECTOR_SETAPIPACKAGE',
        companyId: action.companyId,
        key: 'apiPackageId',
        str: newPackage.data.id
      })
    }
  }
}

