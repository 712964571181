import log from "loglevel";
import { put, takeEvery, spawn, call, select } from "redux-saga/effects";
import { isThirdPartyUser } from "common/helpers/helpers";
import { runSemaphore } from "common/helpers/semaphore";
import { SITESELECT_RESET } from "common/components/SiteSelector/SiteSelectorDuck";
import { BULK_RESET } from "Admin/BulkSiteAssignment/BulkSiteAssignmentDuck";
import { USER_REPORTSUBS_SUCCESS } from "common/dataRetrieval/ReportsDuck";

import {
  USERASSIGN_SUCCESS,
  USERASSIGN_BULK,
  USERASSIGN_BULK_SUCCESS,
  USERASSIGN_BULK_FAILURE
} from "./UserAssignDuck";
import fetchApi from "utils/fetchApi";
import { reportTypes } from "common/helpers/helpers";

const USER_REPORTS_SEMAPHORE_INC = 'USER_REPORTS_SEMAPHORE_INC';
const USER_REPORTS_SEMAPHORE_DEC = 'USER_REPORTS_SEMAPHORE_DEC';
const SEMAPHORE_NAME = 'BULKASSIGN_USER_REPORTSUBSCRIPTIONS';

// NEW WW-1507: offload assignment/reports subscription to backend
// assigns all sites and users (persons and thirdPartyUsers) in action to
// each other and also subscribe reports if supplied
function* putAllSitesAndUsersAssignSaga(action) {
  try {
    const { companyId, siteArr, userArr, siteReportsToSubscribe } = action;

    const url = '/Persons/assignAndSubscribe';
    const authToken = yield select(state => state.login.authToken);

    const sites = siteArr.map(s => s.id);
    const persons = userArr.filter(u => !isThirdPartyUser(u)).map(u => u.id);
    const thirdPartyUsers = userArr.filter(u => isThirdPartyUser(u)).map(u => u.id);
    let userReports = {}; // for both Person and ThirdPartyUser
    for (const site of siteArr) {
      for (const user of userArr) {
        if(!userReports[user.id]) userReports[user.id] = [];
        for(const siteReport of siteReportsToSubscribe) {
          // don't subscribe nonwinter only site to winter type reports and vice versa
          if((site.types.includes('winter') && reportTypes['winter'].includes(siteReport.type)) ||
            (site.types.includes('nonwinter') && reportTypes['nonwinter'].includes(siteReport.type)) ||
            siteReport.type === "snowtify") {
            userReports[user.id].push({
              companyId,
              siteId: site.id,
              type: siteReport.type,
              sendIfNecessary: siteReport.sendIfNecessary
            });
          } else {
            log.debug(
              `Not subscribing to incompatible report '${siteReport.type}' for site ${JSON.stringify(site)}`
            );
          }
        }
      }
    }

    const payload = {
      companyId,
      sites,
      persons,
      thirdPartyUsers,
      userReports
    };

    const parsedJson = yield call(fetchApi, url, {
      method: "PUT",
      payload,
      authToken
    });

    const err = parsedJson.error;
    if (!err) {
      for(const site of sites) {
        // TODO: WW-1507 could have backend endpoint to fetch all site-user combinations for company
        yield put({ type: USERASSIGN_SUCCESS, personObj: { siteId: site, companyId } });
      }
      // update Person/Site/Report subscription mappings in Redux
      for(const user of userArr) {
        yield put({
          type: USER_REPORTSUBS_SUCCESS,
          subsArr : parsedJson.result.reportSubscriptions[user.id],
          userId: user.id,
          userType: isThirdPartyUser(user)? 'thirdPartyUser' : 'person'
        })
      }
      yield put({ type: USERASSIGN_BULK_SUCCESS });
      yield put({ type: BULK_RESET });
      yield put({ type: SITESELECT_RESET });
    } else
      yield put({
        type: USERASSIGN_BULK_FAILURE,
        payload: err
      });
  } catch (error) {
    log.error("putAllSitesAndUsersAssignSaga", error);
    yield put({
      type: USERASSIGN_BULK_FAILURE,
      message: error?.message
    });
  }
}

// listen for actions of type UserAssign_SUBMIT and use them
export default function* userAssignSaga() {
  yield spawn(
    runSemaphore,
    SEMAPHORE_NAME,
    [USER_REPORTS_SEMAPHORE_INC],
    [USER_REPORTS_SEMAPHORE_DEC]
  );
  // yield spawn (countCalls);
  yield takeEvery(USERASSIGN_BULK, putAllSitesAndUsersAssignSaga);
}
