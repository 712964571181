import log from 'loglevel';

import { put, takeEvery, call, select } from 'redux-saga/effects';
import {
  DATA_SUCCESS,
  DATA_SUBMIT,
  DATA_FAILURE,
  SITE_HOURLY_FORECAST_SUBMIT,
  SITE_HOURLY_FORECAST_SUCCESS,
  SITE_HOURLY_FORECAST_FAILURE,
  SITES_SUCCESS,
  SITES_SUBMIT,
  SITES_FAILURE,
} from './DataDuck.js';
import { AUTH_EXPIRED_COUNTDOWN } from "Login/LoginDuck";
import { ACTIONS } from 'Map/MapActions';
import { SUBSCRIPTIONDATA_SUBMIT } from './SubscriptionDuck'
import { UPDATE_WINTER_TOGGLE } from 'common/dataRetrieval/CompanyDuck';
import { THREATLIST_APPLY_FILTER } from 'ThreatList/ThreatListDuck';
import { WATCHLIST_APPLY_FILTER } from 'Watchlist/WatchListDuck';
import fetchApi, { checkStatus, parseJSON, errorConverter } from 'utils/ajax';

const trim = s => (s ? s.trim() : undefined);

const normalizedSites = sites =>
  sites.map(site => ({
    ...site,
    name: trim(site.name),
    address1: trim(site.address1),
    address2: trim(site.address2),
    city: trim(site.city),
    state: trim(site.state),
    zipCode: trim(site.zipCode)
  }));

function* fetchSitesCall(reqCompanyId) {
  try {
    const login = yield select(state => state.login);
    const authToken = login.authToken;

    const companyId = reqCompanyId ? reqCompanyId : (login.companyId ? login.companyId : undefined);

    if (!companyId) {
      log.error('DATA_SUBMIT without companyId!');
      return [];
    }

    const sitesQuery = `?filter[where][companyId]=${companyId}`;

    const sites = yield call(fetchApi, `/Sites${sitesQuery}`, {
      authToken,
      method: 'GET',
    });
    yield call(checkStatus, sites);
    const sitesParsedJson = yield call(parseJSON, sites);

    return {
      sites: normalizedSites(sitesParsedJson)
    };
  } catch (error) {
    log.warn('DATA_FAILURE', error.message);
    const code = error.response && error.response.status;
    let convertedError;
    if (code >= 400 && code < 500) {
      const parsedJson = yield call(parseJSON, error.response);
      convertedError = errorConverter(parsedJson.error);
    } else {
      convertedError = errorConverter({ statusCode: code });
    }

    if(convertedError.authExpired) yield put({ type: AUTH_EXPIRED_COUNTDOWN });
    yield put({
      type: DATA_FAILURE,
      message: convertedError._error ? convertedError._error : error.message
    });
  }
}

function* fetchSitesForCompany({companyId}) {
  try {
    const login = yield select(state => state.login);
    const authToken = login.authToken;

    //log.debug('fetchSitesForCompany', companyId)
    const sitesQuery = `?filter[where][companyId]=${companyId}`;
    const sites = yield call(fetchApi, `/Sites${sitesQuery}`, {
      authToken,
      method: 'GET',
    });
    yield call(checkStatus, sites);
    const sitesParsedJson = yield call(parseJSON, sites);

    yield put({
      type: SITES_SUCCESS,
      sites: sitesParsedJson,
      companyId
    });

  } catch (error) {
    log.warn('SITES_FAILURE', error.message);
    const code = error.response && error.response.status;
    let convertedError;
    if (code >= 400 && code < 500) {
      const parsedJson = yield call(parseJSON, error.response);
      convertedError = errorConverter(parsedJson.error);
    } else {
      convertedError = errorConverter({ statusCode: code });
    }

    log.debug('fetchSitesForCompany error', convertedError);
    if(convertedError.authExpired) {
      yield put({ type: AUTH_EXPIRED_COUNTDOWN });
    }
    yield put({
      type: SITES_FAILURE,
      message: convertedError._error ? convertedError._error : error.message
    });
  }
}

function* fetchRelatedDataCall() {
  try {
    const login = yield select(state => state.login);
    const authToken = login.authToken;

    // fetch related data only if something is selected in company selector (heavy call)
    const companySelector = yield select(state => state.companySelector);
    const companyId = login.companyId
      ? login.companyId
      : companySelector.companyId
        ? companySelector.companyId
        : undefined;

    if (!companyId)
      return {
        forecasts: [],
        threats: [],
        events: [],
        advisories: [],
        watchlists: [],
        notifications: [],
        observations: [],
        siteConvectiveOutlooks: [],
        convectiveOutlooks: [],
      };

    const byCompanyQuery = companyId
      ? {
          where: {
            companyId: companyId
          }
        }
      : {};

    const forecastsQuery = {
      where: {
        timeSpanH: {
          neq: 1
        },
        companyId
      }
    };

    const forecasts = yield call(fetchApi, '/Forecasts', {
      authToken,
      method: 'GET',
      query: {
        filter: JSON.stringify(forecastsQuery)
      }
    });
    yield call(checkStatus, forecasts);
    const forecastsParsedJson = yield call(parseJSON, forecasts);

    const threats = yield call(fetchApi, '/Threats', {
      authToken,
      method: 'GET',
      query: {
        filter: JSON.stringify(byCompanyQuery)
      }
    });
    yield call(checkStatus, threats);
    const threatsParsedJson = yield call(parseJSON, threats);

    // Sorting threats wrt dates
    threatsParsedJson.sort(
      (first, sec) => new Date(first.startDate) - new Date(sec.startDate)
    );

    const events = yield call(fetchApi, '/Events', {
      authToken,
      method: 'GET',
      query: {
        filter: JSON.stringify(byCompanyQuery)
      }
    });
    yield call(checkStatus, events);
    const eventsParsedJson = yield call(parseJSON, events);

    // Sorting events wrt dates
    eventsParsedJson.sort(
      (first, sec) => new Date(first.startDate) - new Date(sec.startDate)
    );

    const advisories = yield call(fetchApi, '/Advisories', {
      authToken,
      method: 'GET',
      query: {
        filter: JSON.stringify(byCompanyQuery)
      }
    });
    yield call(checkStatus, advisories);
    const advisoriesParsedJson = yield call(parseJSON, advisories);

    // const query = companyId ? `?filter[where][companyId]=${companyId}` : ''
    const watchlists = yield call(fetchApi, `/WatchLists`, {
      authToken,
      method: 'GET',
      query: {
        filter: JSON.stringify(byCompanyQuery)
      }
    });
    yield call(checkStatus, watchlists);
    const watchlistsParsedJson = yield call(parseJSON, watchlists);

    const notificationsurl = companyId
      ? `/Companies/${companyId}/notifications`
      : '/Notifications';
    const notifications = yield call(fetchApi, notificationsurl, {
      authToken,
      method: 'GET',
      query: {
        filter: JSON.stringify(byCompanyQuery)
      }
    });
    yield call(checkStatus, notifications);
    const notificationsParsedJson = yield call(parseJSON, notifications);

    const observations = yield call(fetchApi, '/Observations', {
      authToken,
      method: 'GET',
      query: {
        filter: JSON.stringify(byCompanyQuery)
      }
    });
    yield call(checkStatus, observations);
    const observationsParsedJson = yield call(parseJSON, observations);

    const advisoryTypes = yield call(fetchApi, '/Advisories/types', {
      authToken,
      method: 'GET',
      query: {}
    });
    yield call(checkStatus, advisoryTypes);
    const advisoryTypesParsedJson = yield call(parseJSON, advisoryTypes);

    const siteConvectiveOutlooks = yield call(fetchApi, '/SiteConvectiveOutlooks', {
      authToken,
      method: 'GET',
      query: { filter: JSON.stringify(byCompanyQuery) }
    });
    yield call(checkStatus, siteConvectiveOutlooks);
    const siteConvectiveOutlooksParsedJson = yield call(parseJSON, siteConvectiveOutlooks);
    let siteConvectiveOutlookIds = siteConvectiveOutlooksParsedJson.map(sco => sco.convectiveOutlookId);

    // do multiple calls with 200 IDs each because more will fail with 414 Request-URI Too Large
    let outlookIdBatches = [];
    while (siteConvectiveOutlookIds.length)
      outlookIdBatches.push(siteConvectiveOutlookIds.splice(0, 200));
    let convectiveOutlooks = [];
    for (let ids of outlookIdBatches){
      const outlookData = yield call(fetchApi, '/ConvectiveOutlooks', {
        authToken,
        method: 'GET',
        query: { 
          filter: JSON.stringify({
            where: { id: { inq: ids }, },
            fields: {"id": true, "risk":true, "day": true, "minDate":true, "maxDate":true, "category":true}
          }) 
        }
      })
      yield call(checkStatus, outlookData);
      const convectiveOutlooksParsedJson = yield call(parseJSON, outlookData);
      convectiveOutlooks.push(...convectiveOutlooksParsedJson);
    }

    return {
      forecasts: forecastsParsedJson,
      threats: threatsParsedJson,
      events: eventsParsedJson,
      advisories: advisoriesParsedJson,
      watchlists: watchlistsParsedJson,
      notifications: notificationsParsedJson,
      observations: observationsParsedJson,
      advisoryTypes : advisoryTypesParsedJson && advisoryTypesParsedJson.result && advisoryTypesParsedJson.result.types ? advisoryTypesParsedJson.result.types : [],
      siteConvectiveOutlooks: siteConvectiveOutlooksParsedJson,
      convectiveOutlooks
    };
  } catch (error) {
    log.warn('DATA_FAILURE', error.message);
    const code = error.response && error.response.status;
    let convertedError;
    if (code >= 400 && code < 500) {
      try {
      const parsedJson = yield call(parseJSON, error.response);
      convertedError = errorConverter(parsedJson.error);
      } catch (parseErr) {
        log.error('Parsing error.response as JSON failed:', parseErr);
        convertedError = {message: parseErr};
      }
    } else {
      convertedError = errorConverter({ statusCode: code });
    }

    log.warn('fetchDataSaga error', convertedError);
    if(convertedError.authExpired) yield put({ type: AUTH_EXPIRED_COUNTDOWN });
    yield put({
      type: DATA_FAILURE,
      message: convertedError._error ? convertedError._error : error.message
    });
  }
}

export function* fetchSites({companyId}) {
  const sites = yield call(fetchSitesCall, companyId);

  // subscription data must be known before UPDATE_WINTER_TOGGLE
  yield put({
    type: SUBSCRIPTIONDATA_SUBMIT, companyId
  });

  const relatedData = yield call(fetchRelatedDataCall);

  if (relatedData){
    // Instructing middleware to dispatch corresponding action.
    yield put({
      type: DATA_SUCCESS,
      payload: { ...sites, ...relatedData, companyId }
    });

    yield put({
      type: UPDATE_WINTER_TOGGLE, companyId
    });

    // populate threatlist.visibleSites
    yield put({
      type: THREATLIST_APPLY_FILTER
    });

    yield put({
      type: WATCHLIST_APPLY_FILTER
    });

  } else
    yield;
}

function* fetchSitesHourlyForecastsCall({ authToken, siteId }) {
  try {
    const login = yield select(state => state.login);
    if (!authToken) authToken = login.authToken;

    const forecasts = yield call(
      fetchApi,
      `/Forecasts?filter[where][siteId]=${siteId}&filter[where][timeSpanH]=1`,
      {
        authToken,
        method: 'GET'
      }
    );
    yield call(checkStatus, forecasts);
    const forecastsParsedJson = yield call(parseJSON, forecasts);

    return {
      siteId,
      siteHourlyForecasts: forecastsParsedJson
    };
  } catch (error) {
    log.warn('SITE_HOURLY_FORECAST_FAILURE', error);
    const code = error.response.status;
    let convertedError;
    if (code >= 400 && code < 500) {
      const parsedJson = yield call(parseJSON, error.response);
      convertedError = errorConverter(parsedJson.error);
    } else {
      convertedError = errorConverter({ statusCode: code });
    }
    log.debug('fetchSitesHourlyForecastsSaga error', convertedError);
    if(convertedError.authExpired) yield put({ type: AUTH_EXPIRED_COUNTDOWN });
    yield put({
      type: SITE_HOURLY_FORECAST_FAILURE,
      message: convertedError._error ? convertedError._error : error.message
    });
  }
}

export function* fetchSitesHourlyForecasts(action) {
  const response = yield call(fetchSitesHourlyForecastsCall, action.payload);

  // Instructing middleware to dispatch corresponding action.
  yield put({
    type: SITE_HOURLY_FORECAST_SUCCESS,
    payload: response
  });
}

function* fetchSelectedSiteHourlyForecasts(action) {
  if (action.payload.selectionType === "select"){
    yield put({
      type: SITE_HOURLY_FORECAST_SUCCESS,
      payload: yield call(fetchSitesHourlyForecastsCall, action.payload)
    });
  } else yield;
}

export default function* dataSaga() {
  yield takeEvery(SITES_SUBMIT, fetchSitesForCompany);
  yield takeEvery(DATA_SUBMIT, fetchSites);
  yield takeEvery(SITE_HOURLY_FORECAST_SUBMIT, fetchSitesHourlyForecasts);
  yield takeEvery(ACTIONS.SELECT_SITE, fetchSelectedSiteHourlyForecasts);
}
