import React, { Component } from "react";
import ReactCrop from "react-image-crop";
import { Button, Alert } from "react-bootstrap";
import BusySpinner from "common/components/BusySpinner";
import "react-image-crop/dist/ReactCrop.css";

import "./ImageCropComponent.scss";

class ImageCrop extends Component {
  defaultCrop = {
    unit: "%",
    width: 70,
    height: 70
  };

  state = {
    src: null,
    imageSizeExceeded: false,
    crop: {
      ...this.defaultCrop
    }
  };

  constructor(props) {
    super(props);
    this.state.crop.aspect = props.initialAspect || undefined;
  }

  getFileSuffix = f => {
    const name = f.name.toLowerCase();
    const suffix =
      name.lastIndexOf(".") >= 0
        ? name.substring(name.lastIndexOf(".") + 1)
        : "";
    switch (suffix) {
      case "jpg":
      case "jpeg":
        return "jpeg";
      case "png":
        return "png";
      default:
        return "jpeg";
    }
  };

  onResetCrop = () => this.setState({ crop: { ...this.defaultCrop } });

  onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
          console.log('image size', reader.result.length);
        if (reader.result.length <= this.props.maxImageSize) {
          this.setState({ src: reader.result, imageSizeExceeded: false })
        } else {
          this.setState({ src: undefined, imageSizeExceeded: true, croppedImageUrl: undefined, blob: undefined });
        }
      });
      this.setState({ file: e.target.files[0] });
      this.setState({ fileType: this.getFileSuffix(e.target.files[0]) });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = image => {
    this.imageRef = image;
  };

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        this.setState({ blob });
        resolve(this.fileUrl);
      }, `image/${this.state.fileType}`
        , 1 /*qualityArgument*/);
    });
  }

  onSubmit = async () => {
    this.props.onSubmit(
      this.state.blob,
      this.state.file.name,
      this.props.companyId
    );
  };

  render() {
    const { crop, croppedImageUrl, src } = this.state;
    const { isUploading, imageError, imageUploaded, aspectTooTall=1, aspectTooNarrow=6 } = this.props;

    return (
      <div
        className={"ImageCrop__Container"}
      >
        {!!src || (
          <div className={"ImageCrop__FileChooser"}>
            <input 
              type="file" 
              onChange={this.onSelectFile} 
              accept="image/png, image/jpeg"
            />
          </div>
        )}
        {this.state.imageSizeExceeded &&
          <div className={"ImageCrop__Alert"}>
            <Alert variant="warning">
              File size too big, choose a smaller file of not more than {this.props.maxImageSize/1024/1024} MB.
            </Alert>
          </div>
        }
        {!!src && (
          <>
            <div className="ImageCrop__CropContainer">
              <ReactCrop
                src={src}
                crop={crop}
                ruleOfThirds
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
              />
            </div>
            <div className="ImageCrop__Hint">
              To adjust the cutout, click and draw the handles.
            </div>
            <div className={"ImageCrop__FileChooser2"}>
              <input type="file" onChange={this.onSelectFile} />
            </div>
          </>
        )}
        {croppedImageUrl && (
          <>
            <div className="ImageCrop__PreviewHint">Preview</div>
            <div
              className="ImageCrop__CroppedImage"
              style={{
                borderColor:
                  crop.width / crop.height  < aspectTooTall ||
                  crop.width / crop.height > aspectTooNarrow
                    ? "#ff000066"
                    : "#00ff0066",
                borderWidth: "2px"
              }}
            >
              <img
                alt="Crop"
                className='ImageCrop__PreviewImg'
                src={croppedImageUrl}
              />
            </div>
          </>
        )}

        {!!imageError && (
          <div className="ImageCrop__Alert">
            <Alert variant="danger">{imageError}</Alert>
          </div>
        )}
        {!!this.state.blob && imageUploaded && (
          <div className="ImageCrop__Alert">
            <Alert variant="success">Logo uploaded successfully!</Alert>
          </div>
        )}

        {!!src && (
          <div className="ImageCrop__Buttons">
            {/*
            <div className="ImageCrop__ResetButton">
              <Button onClick={this.onResetCrop}>Reset</Button>
            </div>
            */}
            <div className="ImageCrop__ResetButton">
              <Button
                variant="primary"
                onClick={this.onSubmit}
                disabled={!this.state.file || !this.state.blob || isUploading}
              >
                {isUploading ? (
                  <span>
                    <BusySpinner inButton={true} />
                    Uploading Preview
                  </span>
                ) : (
                  "Upload Preview"
                )}
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default ImageCrop;
