// This file implements the "ducks" pattern, i.e. combines action constants,
// action creators and reducers in one file instead of having multiple files per
// function. As long as there are not too many values, this makes it nice and
// compact.

import presetFilters from './presetFilters';
import { TOGGLEBUTTON_SELECT } from "common/components/ToggleButton/ToggleButtonDuck";

export const LOC_STATE = 'StateCityCounty';
export const LOC_ZIP = 'ZipCode';

// Action constants

export const THREATFILTER_RESET = 'THREATFILTER_RESET';
export const THREATFILTER_SETLOCATION = 'THREATFILTER_SETLOCATION';
export const THREATFILTER_SETVALUES = 'THREATFILTER_SETVALUES';

export const THREATFILTER_OPEN_MODAL = 'THREATFILTER_OPEN_MODAL';
export const THREATFILTER_CLOSE_MODAL = 'THREATFILTER_CLOSE_MODAL';

export const THREATFILTER_SAVE_SUBMIT = 'THREATFILTER_SAVE_SUBMIT';
export const THREATFILTER_SAVE_SUCCESS = 'THREATFILTER_SAVE_SUCCESS';
export const THREATFILTER_SAVE_FAILURE = 'THREATFILTER_SAVE_FAILURE';

export const THREATFILTER_LOAD_SUBMIT = 'THREATFILTER_LOAD_SUBMIT';
export const THREATFILTER_LOAD_SUCCESS = 'THREATFILTER_LOAD_SUCCESS';
export const THREATFILTER_LOAD_FAILURE = 'THREATFILTER_LOAD_FAILURE';

export const THREATFILTER_APPLY_PRESET = 'THREATFILTER_APPLY_PRESET';
export const THREATFILTER_DELETE_PRESET_SUBMIT = 'THREATFILTER_DELETE_PRESET';
export const THREATFILTER_DELETE_PRESET_SUCCESS = 'THREATFILTER_DELETE_PRESET_SUCCESS';
export const THREATFILTER_DELETE_PRESET_FAIL = 'THREATFILTER_DELETE_PRESET_FAIL';

// Action creators

export const resetForm = () => ({
  type: THREATFILTER_RESET
})

export const setLocation = (loc) => ({
  type: THREATFILTER_SETLOCATION,
  loc
})


export const setThreatFilter = ({
  popSliderVal,
  snowSliderVal,
  iceSliderVal,
  rainSliderVal,
  windSliderVal,
  timingVal,
  locVal,
  typeVal
}) => ({
  type: THREATFILTER_SETVALUES,
  popSliderVal,
  snowSliderVal,
  iceSliderVal,
  rainSliderVal,
  windSliderVal,
  timingVal,
  locVal,
  typeVal
});

export const openModal = name => ({
  type: THREATFILTER_OPEN_MODAL,
  name
})

export const closeModal = () => ({
  type: THREATFILTER_CLOSE_MODAL
})

export const saveThreatFilter = (name, options, personId) => ({
  type: THREATFILTER_SAVE_SUBMIT,
  name,
  options,
  personId
})

export const loadThreatFilters = (personId) => ({
  type: THREATFILTER_LOAD_SUBMIT,
  personId
})

export const applyPreset = (id) => ({
  type: THREATFILTER_APPLY_PRESET,
  id
})

export const deletePreset = (personId, id) => ({
  type: THREATFILTER_DELETE_PRESET_SUBMIT,
  personId,
  id
})


// Reducer


const initialState = {
  showModal: false,
  error: '',
  savedFilters: presetFilters,
  isFresh: true
};

const safeVal = (newVal, oldVal) => newVal !== undefined ? newVal : oldVal;

const threatFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case THREATFILTER_RESET:
      return {...initialState, savedFilters: [...state.savedFilters]}

    case THREATFILTER_SETLOCATION:
      return {
        ...state,
        locationFilter: action.loc,
        isFresh: false
      }
    case THREATFILTER_SETVALUES:
      return {
        ...state,
        popSliderVal: safeVal(action.popSliderVal, state.popSliderVal),
        snowSliderVal: safeVal(action.snowSliderVal, state.snowSliderVal),
        iceSliderVal: safeVal(action.iceSliderVal, state.iceSliderVal),
        rainSliderVal: safeVal(action.rainSliderVal, state.rainSliderVal),
        windSliderVal: safeVal(action.windSliderVal, state.windSliderVal),
        timingVal: safeVal(action.timingVal, state.timingVal),
        locVal: safeVal(action.locVal, state.locVal),
        typeVal: safeVal(action.typeVal, state.typeVal),
        isFresh: false
      }

    case THREATFILTER_APPLY_PRESET:
      const preset = (state.savedFilters.find(f => f.id === action.id) ||{}).options
      // Presets are defined in ./presetFilters.js 
      // N.B.: since old state is not copied over here through spreading, take care
      // to add all key/value pairs from old state that should be in new state.
      return preset 
        ? {
            ...preset,
            showModal: state.showModal,
            error: state.error,
            savedFilters: [...state.savedFilters],
            isFresh: false,
            presetId: action.id
          }
        : state;

    case THREATFILTER_OPEN_MODAL:
      return {
        ...state,
        showModal: action.name
      }

    case THREATFILTER_CLOSE_MODAL:
      return {
        ...state,
        showModal: false
      }

    case THREATFILTER_LOAD_SUBMIT:
      return {
        ...state,
        error: '',
        isFetching: true
      }

    case THREATFILTER_LOAD_SUCCESS:
      return {
        ...state,
        savedFilters: [...presetFilters, ...action.savedFilters],
        isFetching: false
      }

    case THREATFILTER_LOAD_FAILURE:
      return {
        ...state,
        error: action.message,
        isFetching: false
      }

    case THREATFILTER_SAVE_SUBMIT:
      return {
        ...state,
        error: '',
        isFetching: true
      }

    case THREATFILTER_SAVE_SUCCESS:
      return {
        ...state,
        savedFilters: [...state.savedFilters, action.savedFilter],
        showModal: false,
        isFetching: false
      }

    case THREATFILTER_SAVE_FAILURE:
      return {
        ...state,
        error: action.message,
        isFetching: false
      }

    case THREATFILTER_DELETE_PRESET_SUBMIT:
      return {
        ...state,
        isFetching: true
      }

    case THREATFILTER_DELETE_PRESET_SUCCESS:
      return {
        ...state,
        error: '',
        isFetching: false
      }

    case THREATFILTER_DELETE_PRESET_FAIL:
      return {
        ...state,
        error: action.message,
        isFetching: false
      }

    case TOGGLEBUTTON_SELECT:
      // reset when switching between winter and nonwinter
      if (action.groupId === "winterToggle")
        return {...initialState, savedFilters: [...state.savedFilters]}
      else 
        return state;


    default:
      return state

  }
}

export default threatFilterReducer;

