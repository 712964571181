import immutable from "object-path-immutable";
import { SUBSCRIPTION_ACTV_TRIAL_SUBMIT } from
"common/dataRetrieval/SubscriptionDuck";

export const ACTV_TRIAL_SUBMIT = "ACTV_TRIAL_SUBMIT";
export const ACTV_TRIAL_SUCCESS = "ACTV_TRIAL_SUCCESS";
export const ACTV_TRIAL_FAILURE = "ACTV_TRIAL_FAILURE";
export const ACTV_TRIAL_RESET = "ACTV_TRIAL_RESET";

export const doActivateTrialCompany = (emailToken, companyId) => ({
  type: ACTV_TRIAL_SUBMIT,
  emailToken,
  companyId
});

export const resetActivateTrial = () => ({
  type: ACTV_TRIAL_RESET
});

const initialState = {
  meta: {
    isFetching: false,
    apiError: undefined
  }
};

const activateTrialCompany = (state = initialState, action) => {
  switch (action.type) {
    case ACTV_TRIAL_SUBMIT:
    case SUBSCRIPTION_ACTV_TRIAL_SUBMIT:
      return immutable(state)
        .set("meta.isFetching", true)
        .set("meta.apiError", undefined)
        .set("activateSuccess", undefined)
        .value();

    case ACTV_TRIAL_SUCCESS:
      return immutable(state)
        .set("meta.isFetching", false)
        .set("meta.apiError", undefined)
        .set("activateSuccess", action.activateSuccess)
        .set("companyName", action.data.company.name)
        .value();

    case ACTV_TRIAL_FAILURE:
      return immutable(state)
        .set("meta.isFetching", false)
        .set("meta.apiError", action.apiError)
        .set("meta.isCompanyAlreadyActive", action.isCompanyAlreadyActive)
        .value();

    case ACTV_TRIAL_RESET:
      return initialState;

    default:
      return state;
  }
};

export default activateTrialCompany;
