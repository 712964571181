// This file implements the "ducks" pattern, i.e. combines action constants,
// action creators and reducers in one file instead of having multiple files per
// function. As long as there are not too many values, this makes it nice and
// compact.

export const H24 = '24 hrs';
export const H48 = '48 hrs';
export const H72 = '72 hrs';
export const INPROGRESS = 'In-Progress';
export const NOTIME = 'NOTIME';
export const NOEV = 'Eventless';

// Action constants

export const TIMINGSELECTOR_SET_TIME = 'TIMINGSELECTOR_SET_TIME';
export const TIMINGSELECTOR_RESET = 'TIMINGSELECTOR_RESET';

// Reducer

const initialState = {
};

// Action creators

export const selectTiming = (groupId, timing, label) => ({
  type: TIMINGSELECTOR_SET_TIME,
  groupId, timing, label
})

export const resetTiming = (groupId) => ({
  type: TIMINGSELECTOR_RESET,
  groupId
})

// Reducer

const timing2hours = (timing) => {
  switch(timing) {
    case H24: return 24;
    case H48: return 48;
    case H72: return 72;
    case INPROGRESS: return 0;
    default: return 72;
  }
}

const updateGroup = (slice: {timing: INPROGRESS, label: INPROGRESS}, {timing, label}) => (
  {
    ...slice,
    timing, 
    label,
    value: timing2hours(timing)
  }
)

const timingSelectorReducer = (state = initialState, action) => {
  switch (action.type) {
    case TIMINGSELECTOR_RESET:
      return {
        ...state,
        [action.groupId]: updateGroup(state[action.groupId], {timing: NOTIME, label: INPROGRESS})
      }

    case TIMINGSELECTOR_SET_TIME:
      return {
        ...state,
        [action.groupId]: updateGroup(state[action.groupId], action)
      }
    default:
      return state

  }
}

export default timingSelectorReducer;


