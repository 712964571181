export const SITEUSERS_SELECT_USER = 'SITEUSERS_SELECT_USER'

export const setSelectedSiteUser = (id) => ({
  type: SITEUSERS_SELECT_USER,
  id
})

const initialState = {
  selectedSiteUser: undefined
}

const siteUsersManage = (state = initialState, action) => {
    switch(action.type){
      case SITEUSERS_SELECT_USER:
        return {
          ...state,
          selectedSiteUser: action.id
        }

      default :
        return state;
    }
}

export default siteUsersManage

