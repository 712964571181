import { COMPANYDELETE_SUCCESS } from 'Admin/CompanyEdit/CompanyEditDuck';

export const SET_SELECTED_COMPANY = 'SET_SELECTED_COMPANY'

export const selectCompany = (companyId) => ({
  type: SET_SELECTED_COMPANY,
  companyId
});

const initialState = {};

const companySelector = (state = initialState, action) => {
  switch (action.type) {

    case SET_SELECTED_COMPANY:
      return {
        ...state,
        companyId: action.companyId
      };

      case COMPANYDELETE_SUCCESS:
      return {
        ...state,
        companyId: state.companyId === action.companyId ? "" : state.companyId
      }

    default: return state

  }
};

export default companySelector
