import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'rc-slider/lib/Slider';

import './FilterSlider.css';

import { INCHES10, INCHES2, INCHES6, MPH50 } from './index';

const touchpointMinPx = 24;

const markStyle = {
  opacity: '0.6',
  color: '#354052',
  marginTop: '1.2em',
}

const marksPercent = {
  0: {
    style: markStyle,
    label: '0',
  },
  25: {
    style: markStyle,
    label: '25%',
  },
  50: {
    style: markStyle,
    label: '50%',
  },
  75: {
    style: markStyle,
    label: '75%',
  },
  100: {
    style: markStyle,
    label: '100%',
  },
}

const marksInches6 = {
  0: {
    style: markStyle,
    label: '0',
  },
  25: {
    style: markStyle,
    label: '1.5"',
  },
  50: {
    style: markStyle,
    label: '3.0"',
  },
  75: {
    style: markStyle,
    label: '4.5"',
  },
  100: {
    style: markStyle,
    label: '6.0"',
  },
};
const marksInches2 = {
  0: {
    style: markStyle,
    label: '0',
  },
  25: {
    style: markStyle,
    label: '0.5"',
  },
  50: {
    style: markStyle,
    label: '1.0"',
  },
  75: {
    style: markStyle,
    label: '1.5"',
  },
  100: {
    style: markStyle,
    label: '2.0"',
  },
};
const marksInches10 = {
  0: {
    style: markStyle,
    label: '0',
  },
  20: {
    style: markStyle,
    label: '2"',
  },
  40: {
    style: markStyle,
    label: '4"',
  },
  60: {
    style: markStyle,
    label: '6"',
  },
  80: {
    style: markStyle,
    label: '8"',
  },
  100: {
    style: markStyle,
    label: '10"',
  },
};

const marksMPH50 = {
  0: {
    style: markStyle,
    label: '0',
  },
  20: {
    style: markStyle,
    label: '10',
  },
  49: {
    style: markStyle,
    label: '25',
  },
  75: {
    style: markStyle,
    label: '40',
  },
  100: {
    style: markStyle,
    label: '50+',
  },
};

const handleStyle = {
  height: `${touchpointMinPx}px`,
  width: `${touchpointMinPx}px`,
  marginTop: `-${touchpointMinPx/2}px`,
  border: '1px solid #CED0DA',
  borderRadius: '4px',
  background: 'linear-gradient(0deg, #F2F4F8 0%, #FEFFFF 100%)',
  boxShadow: '0 1px 2px 0 rgba(0,0,0,0.05)',
}

const trackStyle = {
  backgroundColor: '#4758BE',
}

const railStyle = {
}

export const percentToIn = (per, max) => max * per/100

const getMarks = marks => {
  switch (marks) {
    case INCHES10: return marksInches10;
    case INCHES2: return marksInches2;
    case INCHES6: return marksInches6;
    case MPH50: return marksMPH50;
    default: return marksPercent;
  }
}

const getValue = (value, marks) => {
  switch (marks) {
    case INCHES10: 
    case INCHES6: 
    case INCHES2: 
      return <div className='FilterSlider__value'>{percentToIn(value, marks)}"</div>
    case MPH50: 
      return <div className='FilterSlider__value'>{Math.floor(percentToIn(value, marks))}</div>
    default: 
      return <div className='FilterSlider__value'>{value}%</div>
  }
}

const FilterSlider = ({value, setValue, type, marks}) => {
  return (
    <div className='FilterSlider__outer'>
      <div className='FilterSlider_Slider '>
          <Slider marks={getMarks(marks)}
                  value={value}
                  dots={false} dotStyle={{display: 'none'}}
                  handleStyle={handleStyle}
                  trackStyle={trackStyle}
                  railStyle={railStyle}
                  onChange={setValue}
          />
      </div>

      {getValue(value, marks)}

    </div>
  )
}

FilterSlider.propTypes = {
  value: PropTypes.number.isRequired,
  setValue: PropTypes.func.isRequired
}

export default FilterSlider;
