import {reportSettingsSortFun} from './ReportSettings'

export const REPORTSETTINGSDATA_SUBMIT = 'REPORTSETTINGSDATA_SUBMIT';
export const REPORTSETTINGSDATA_SUCCESS = 'REPORTSETTINGSDATA_SUCCESS';
export const REPORTSETTINGSDATA_FAILURE = 'REPORTSETTINGSDATA_FAILURE';
export const REPORTSETTINGS_SAVE_SUBMIT = 'REPORTSETTINGS_SAVE_SUBMIT';
export const REPORTSETTINGS_SAVE_SUCCESS = 'REPORTSETTINGS_SAVE_SUCCESS';
export const REPORTSETTINGS_SAVE_FAILURE = 'REPORTSETTINGS_SAVE_FAILURE';
export const REPORTSETTINGS_RESET = 'REPORTSETTINGS_RESET';
export const REPORTCUSTOM_SAVE_SUBMIT =  'REPORTCUSTOM_SAVE_SUBMIT';
export const REPORTCUSTOM_SAVE_SUCCESS = 'REPORTCUSTOM_SAVE_SUCCESS';
export const REPORTCUSTOM_SAVE_FAILURE = 'REPORTCUSTOM_SAVE_FAILURE';
export const REPORTCUSTOM_GET_SUBMIT =  'REPORTCUSTOM_GET_SUBMIT';
export const REPORTCUSTOM_GET_SUCCESS = 'REPORTCUSTOM_GET_SUCCESS';
export const REPORTCUSTOM_GET_FAILURE = 'REPORTCUSTOM_GET_FAILURE';

export const loadReportSettings = companyId => ({
  type: REPORTSETTINGSDATA_SUBMIT,
  companyId
})

export const saveReportSettings = (companyId, toDel, toAdd, toUpdate) => ({
  type: REPORTSETTINGS_SAVE_SUBMIT,
  companyId,
  toDel, toAdd, toUpdate
})

export const resetReportSettings = () => ({
  type: REPORTSETTINGS_RESET
});

export const saveCustomReportFilterValues = (companyId, values, name, id) => ({
  type: REPORTCUSTOM_SAVE_SUBMIT,
  companyId,
  values,
  name,
  customReportId: id
});

export const getCustomReportFilterValues = (companyId) => ({
  type: REPORTCUSTOM_GET_SUBMIT,
  companyId
});

const initialState = {
  isFetching: false,
  error: '',
  success: false,
  data: {}, // map of companyId -> array of settings objects
  customReports: []
}

const update = (oldReportSettings, companyId, reportSettings) => {
  const newReportSettings = {...oldReportSettings}
  reportSettings.sort(reportSettingsSortFun)
  newReportSettings[companyId] = reportSettings
  return newReportSettings
}

const reportSettings = (state = initialState, action) => {
  switch (action.type) {
    case REPORTSETTINGSDATA_SUBMIT:
      return {
        ...state,
        error: '',
        isFetching: true
      }

    case REPORTSETTINGSDATA_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: update(state.data, action.companyId, action.reportSettings)
      }

    case REPORTSETTINGSDATA_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.message
      }

    case REPORTSETTINGS_SAVE_SUBMIT:
      return {
        ...state,
        isFetching: true,
        error: '',
        success: false
      }

    case REPORTSETTINGS_SAVE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: update(state.data, action.companyId, action.reportSettings),
        success: true
      }

    case REPORTSETTINGS_SAVE_FAILURE:
      return {
        ...state,
        isFetching: false,
        success: false,
        error: action.message
      }

    case REPORTSETTINGS_RESET:
      return {
        ...state,
        success: false,
        error: ''
      };

    case REPORTCUSTOM_SAVE_SUBMIT:
      return {
        ...state,
        isFetching: true
      }

    case REPORTCUSTOM_SAVE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        apiError: undefined,
        customReportSaveSuccess: true
      }

    case REPORTCUSTOM_SAVE_FAILURE:
      return {
        ...state,
        isFetching: false,
        apiError: action.apiError,
        customReportSaveSuccess: false
      }

    case REPORTCUSTOM_GET_SUBMIT:
      return {
        ...state,
        isFetchedCustomReports: false,
        isFetching: true,
      }

    case REPORTCUSTOM_GET_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isFetchedCustomReports: true,
        customReports: action.customReports.filter(r => r.active),
        apiError: undefined
      }

    case REPORTCUSTOM_GET_FAILURE:
      return {
        ...state,
        isFetching: false,
        isFetchedCustomReports: false,
        apiError: action.apiError
      }

    default:
      return state

  }
}

export default reportSettings;
