export const USERUNASSIGN_OPEN = "USERUNASSIGN_OPEN";
export const USERUNASSIGN_CLOSE = "USERUNASSIGN_CLOSE";
export const USERUNASSIGN_SUBMIT = "USERUNASSIGN_SUBMIT";
export const USERUNASSIGN_SUCCESS = "USERUNASSIGN_SUCCESS";
export const USERUNASSIGN_FAILURE = "USERUNASSIGN_FAILURE";

export const USERUNASSIGN_BULK_SUBMIT = "USERUNASSIGN_BULK_SUBMIT";
export const USERUNASSIGN_BULK_SUCCESS = "USERUNASSIGN_BULK_SUCCESS";
export const USERUNASSIGN_BULK_FAILURE = "USERUNASSIGN_BULK_FAILURE";
export const USERUNASSIGN_BULK_RESET_STATUS = "USERUNASSIGN_BULK_RESET_STATUS";

export const closeUnassignModal = () => ({
  type: USERUNASSIGN_CLOSE
});

export const openUnassignModal = () => ({
  type: USERUNASSIGN_OPEN
});

export const unassignUsers = (companyId, siteId, userArr) => ({
  type: USERUNASSIGN_BULK_SUBMIT,
  companyId,
  siteId,
  userArr
});

export const resetBulkStatus = () => ({
  type: USERUNASSIGN_BULK_RESET_STATUS
});

const initialState = {
  showModal: false,
  isFetching: false,
  errorMessage: undefined,
  bulkAssignOpSuccess: undefined
};

const userUnassign = (state = initialState, action) => {
  switch (action.type) {
    case USERUNASSIGN_OPEN:
      return {
        ...state,
        showModal: true
      };
    case USERUNASSIGN_CLOSE:
      return initialState;

    case USERUNASSIGN_SUBMIT:
      return {
        ...state,
        errorMessage: undefined,
        isFetching: true,
      };
    case USERUNASSIGN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        errorMessage: undefined,
      };

    case USERUNASSIGN_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };

    case USERUNASSIGN_BULK_SUBMIT:
      return {
        ...state,
        isFetching: true,
        bulkAssignOpSuccess: undefined
      };
    case USERUNASSIGN_BULK_SUCCESS:
      return {
        ...state,
        isFetching: false,
        bulkAssignOpSuccess: true
      };
    case USERUNASSIGN_BULK_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
        bulkAssignOpSuccess: false
      };

    case USERUNASSIGN_BULK_RESET_STATUS:
      return { 
        ...state, 
        bulkAssignOpSuccess: undefined 
      };

    default:
      return state;
  }
};

export default userUnassign;
