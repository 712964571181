export const ACTIONS = {
    CONTROL_PANEL_CHANGED : 'CONTROL_PANEL_CHANGED',
    SCREEN_CHANGED : 'SCREEN_CHANGED',
    ITEM_SELECTED : 'ITEM_SELECTED',
    CHILD_ITEM_SELECTED : 'CHILD_ITEM_SELECTED',
    LAYER_OPACITY_CHANGED : 'LAYER_OPACITY_CHANGED',
    START_ANIMATION_LOAD :  'START_ANIMATION_LOAD',
    ANIMATION_STARTED : 'ANIMATION_STARTED',
    ANIMATION_PAUSED : 'ANIMATION_PAUSED',
    ANIMATION_INTERVAL_CHANGED : 'ANIMATION_INTERVAL_CHANGED',
    USER_LOCATION_FOUND : 'USER_LOCATION_FOUND',
    FETCH_LOCATION_WEATHER : 'FETCH_LOCATION_WEATHER',
    FETCH_LOCATION_WEATHER_SUCCESS : 'FETCH_LOCATION_WEATHER_SUCCESS',
    RESET_LOCATION_WEATHER : 'RESET_LOCATION_WEATHER',
    MAP_ZOOM_CHANGED : 'MAP_ZOOM_CHANGED',
    SELECT_SITE : 'SELECT_SITE',
    TILE_LOADED : 'TILE_LOADED',
    DOT_LEGENDS_EXPANDED : 'DOT_LEGENDS_EXPANDED',
    DOT_LEGENDS_COLLAPSED : 'DOT_LEGENDS_COLLAPSED',
    ADD_SITE_TO_SELECTED_SITES  : 'ADD_SITE_TO_SELECTED_SITES',
    REMOVE_SITE_FROM_SELECTED_SITES  : 'REMOVE_SITE_FROM_SELECTED_SITES',
    RESET_SELECTED_SITES : 'RESET_SELECTED_SITES',
}

export const ANIMATION_STATE = {
    PAUSED : 'PAUSED',
    LOADING : 'LOADING',
    PLAYING : 'PLAYING'
}

export function controlPanelChanged(expanded){

    return {
        type : ACTIONS.CONTROL_PANEL_CHANGED,
        payload : expanded
    }
}

export function mapScreenChanged(){

    return {
        type : ACTIONS.SCREEN_CHANGED
    }
}

export function itemSelected(id, selected){

    return {
        type : ACTIONS.ITEM_SELECTED,
        payload : {
            id,
            selected
        }
    }
}

export function childItemSelected(parentId, childId, selected){

    return {
        type : ACTIONS.CHILD_ITEM_SELECTED,
        payload : {
            parentId,
            childId,
            selected
        }
    }
}

export function tileLoaded(userId, count){

    return {
        type : ACTIONS.TILE_LOADED,
        payload : {
            userId,
            count
        }
    }
}

export function layerOpacityChaged(id, opacity){

    return {
        type : ACTIONS.LAYER_OPACITY_CHANGED,
        payload : {
            id,
            opacity
        }
    }
}



export function startAnimationLoad(){

    return {
        type : ACTIONS.START_ANIMATION_LOAD,
    }
}

export function animationLoadCompleted(){

    return {
        type : ACTIONS.ANIMATION_STARTED
    }
}

export function pauseAnimation(){

    return {
        type : ACTIONS.ANIMATION_PAUSED
    }
}

export function changeAnimationInterval(interval){

    return {
        type : ACTIONS.ANIMATION_INTERVAL_CHANGED,
        payload : interval
    }
}

export function userLocationFound(userLocation){

    return {
        type : ACTIONS.USER_LOCATION_FOUND,
        payload : {
            userLocation
        }
    }
}

export function dotLegendsExpanded(){
    return {
        type : ACTIONS.DOT_LEGENDS_EXPANDED
    }
}

export function dotLegendsCollapsed(){
    return {
        type : ACTIONS.DOT_LEGENDS_COLLAPSED
    }
}

export function addSiteToSelectedSites(siteId){
    return {
        type : ACTIONS.ADD_SITE_TO_SELECTED_SITES,
        payload : siteId
    }
}

export function removeSiteFromSelectedSites(siteId){
    return {
        type : ACTIONS.REMOVE_SITE_FROM_SELECTED_SITES,
        payload : siteId
    }
}

export function resetSelectedSites(){
    return {
        type : ACTIONS.RESET_SELECTED_SITES
    }
}


// triggers call to aeris to fetch current weather for location
// if showPanel==true: open MapOverComponent on map to display this data
// if false:, don't - this enables the LayoutComponent to ask for current
// weather to show in Header without the map overlay opening
export function fetchLocationWeather(location, showPanel=true){

    return {
        type : ACTIONS.FETCH_LOCATION_WEATHER,
        payload : {
            location,
            showPanel
        }
    }
}

export function resetLocationWeather(){

    return {
        type : ACTIONS.RESET_LOCATION_WEATHER
    }
}

export function changeMapZoom(value){

    return {
        type : ACTIONS.MAP_ZOOM_CHANGED,
        payload : {
            value
        }
    }
}


/*
 *  selectionType : 'select' or 'hover'
 *  selected : true or false
 */
export function selectSite(source, siteId, selectionType, selected){

    return {
        type : ACTIONS.SELECT_SITE,
        payload : {
            source,
            siteId,
            selectionType,
            selected
        }
    }
}
