import { connect } from 'react-redux'
import LocationSelectorComponent from './LocationSelector'
import { 
  setFedState, setCity, setZipCode, selectRadio,
  FEDSTATE, ZIPCODE } from './LocationSelectorDuck'

const mapStateToProps = ({locationselector, sites, statesandcities }, ownProps) => {
  const ls = locationselector[ownProps.groupId]
  return {
    isStateCitySelected: ls && ls.stateOrZipCode === FEDSTATE,
    isZipCodeSelected: ls && ls.stateOrZipCode === ZIPCODE,
    selectedRadio: ls && ls.radio,
    fedStates: statesandcities || {},
    selectedFedState: ls && ls.fedState,
    selectedCity: ls && ls.city,
    selectedZipCode: (ls && ls.zipCode) || ''
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  selectRadio: (name) => {
    dispatch(selectRadio(ownProps.groupId, name))
  },
  setFedState: (fedState) => {
    dispatch(setFedState(ownProps.groupId, fedState))
  },
  setCity: (city) => {
    dispatch(setCity(ownProps.groupId, city))
  },
  setZipCode: (zipCode) => {
    dispatch(setZipCode(ownProps.groupId, zipCode))
  }
})

const LocationSelectorContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationSelectorComponent)

export default LocationSelectorContainer;


