export const LOGVIEWER_ADD_ROOM = "LOGVIEWER_ADD_ROOM";
export const LOGVIEWER_SET_ROOMS = "LOGVIEWER_SET_ROOMS";
export const LOG_RECORD = "LOG_RECORD";
export const LOGSOCKET_DATA = "LOGSOCKET_DATA";
export const LOG_SET_CONNECTIONSTATUS = "LOG_SET_CONNECTIONSTATUS";
export const LOG_GET_BATCH = "LOG_GET_BATCH";
export const LOG_BATCH = "LOG_BATCH";
export const LOG_REQUEST_ROOMSLIST = "LOG_REQUEST_ROOMSLIST";
export const LOGVIEWER_RECONNECT = "LOGVIEWER_RECONNECT";
export const LOGVIEWER_ERROR = "LOGVIEWER_ERROR";
export const LOGVIEWER_RESET = "LOGVIEWER_RESET";

const MAXLOGRECS = 2000; // stop data from growing indefinitely

export const requestBatch = (roomId, startLineNo, endLineNo) => ({
  type: LOG_GET_BATCH,
  roomId,
  startLineNo,
  endLineNo
});

export const reconnect = () => ({
  type: LOGVIEWER_RECONNECT
});

export const reset = () => ({
  type: LOGVIEWER_RESET
});

export const requestRooms = () => ({
  type: LOG_REQUEST_ROOMSLIST
});

const initialState = {
  rooms: {},
  data: {},
  meta: { connStat: {} }
};

const orderedRooms = roommap => {
  const ordered = {};
  Object.keys(roommap)
    .sort((a,b) => roommap[a].roomName.localeCompare(roommap[b].roomName))
    .forEach(key => {
      ordered[key] = roommap[key];
    });
  return ordered;
};

const logViewer = (state = initialState, action) => {
  switch (action.type) {
    case LOGVIEWER_RESET:
      return {
        ...state,
        data: [],
        rooms: {}
      };

    case LOGVIEWER_ADD_ROOM:
      return {
        ...state,
        rooms: orderedRooms({ ...state.rooms, ...action.room })
      };

    case LOG_RECORD:
      const roomId = action.logrec.roomId;
      return {
        ...state,
        data: {
          ...state.data,
          [roomId]: [action.logrec, ...(state.data[roomId] || [])]
            .sort((a, b) => b.lineNo - a.lineNo)
            .splice(0, MAXLOGRECS)
        }
      };

    case LOG_BATCH:
      return {
        ...state,
        data: {
          ...state.data,
          [action.roomId]: [
            ...action.batchArr,
            ...(state.data[action.roomId] || [])
          ]
            .sort((a, b) => b.lineNo - a.lineNo)
            .filter((d, i, arr) => i === 0 || arr[i - 1].lineId !== d.lineId)
            .splice(0, MAXLOGRECS)
        }
      };

    case LOG_SET_CONNECTIONSTATUS:
      return {
        ...state,
        meta: {
          ...state.meta,
          connStat: {
            ...state.meta.connStat,
            [action.url]: action.connectionStatus
          }
        }
      };

    default:
      return state;
  }
};

export default logViewer;
