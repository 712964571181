import log from 'loglevel';
import { put, takeEvery, call } from 'redux-saga/effects'

import {
  VERIFYPERSON_SUBMIT,
  VERIFYPERSON_SUCCESS,
  VERIFYPERSON_FAILURE
} from './VerifyPersonDuck'
import fetchApi from 'utils/fetchApi';

export function* fetchVerifyPersonSaga (action) {
  try {
    // redux-form-submit-saga injects 'payload' into action
    const { uid, token } = action.payload;
    const url = `/Persons/confirm?uid=${uid}&token=${token}`;

    const parsedJson = yield call(fetchApi, url, {method: 'GET'})
    if (!parsedJson.error){
      yield put({ type: VERIFYPERSON_SUCCESS, email: parsedJson.email, token: parsedJson.token })
    } else {
      let convertedError = parsedJson.error.text
      if (parsedJson.error.text._error === 'Authorization required.') {
        convertedError = {_error: 'Authentication token is expired or invalid.'}
      }
      yield put({
        type: VERIFYPERSON_FAILURE,
        payload: convertedError
      })
    }
  } catch (error) {
    log.error('fetchVerifyPersonSaga', error)
    yield put({
      type: VERIFYPERSON_FAILURE,
      message: 'Network error.'
    })
  }
}


// listen for actions of type VERIFYPERSON_SUBMIT and use them
export default function* verifyPersonSaga () {
  yield takeEvery(VERIFYPERSON_SUBMIT, fetchVerifyPersonSaga)
}
