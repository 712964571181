import log from 'loglevel';
import { call, put, select, takeEvery } from 'redux-saga/effects'
import {
  THREATFILTER_APPLY_PRESET,
  THREATFILTER_LOAD_FAILURE,
  THREATFILTER_LOAD_SUBMIT,
  THREATFILTER_LOAD_SUCCESS,
  THREATFILTER_SAVE_FAILURE,
  THREATFILTER_SAVE_SUBMIT,
  THREATFILTER_SAVE_SUCCESS,
  THREATFILTER_DELETE_PRESET_SUBMIT,
  THREATFILTER_DELETE_PRESET_SUCCESS,
  THREATFILTER_DELETE_PRESET_FAIL
} from "./ThreatFilterDuck";
import fetchApi from 'utils/fetchApi'
import { THREATFILTER } from 'ThreatFilter/ThreatFilterContainer'
import { THREATTYPESELECTOR_SET_VALUES } from "common/components/ThreatTypeSelector/ThreatTypeSelectorDuck";
import { FILTERSLIDER_SELECT } from "common/components/FilterSlider/FilterSliderDuck";
import { TIMINGSELECTOR_SET_TIME } from "common/components/TimingSelector/TimingSelectorDuck";
import { LOCATIONSELECTOR_SET_VALUES } from "common/components/LocationSelector/LocationSelectorDuck";

export function* saveThreatFilterSaga (action) {
  try {
    const {name, options, personId} = action

    const authToken = yield select((state) => state.login.authToken);
    const payload = {
      name,
      options,
      personId
    }
    const filterParsedJson = yield call(fetchApi, `/Persons/${personId}/filters`, {
      authToken,
      payload
    })

    if (!filterParsedJson.error)
      yield put({
        type: THREATFILTER_SAVE_SUCCESS,
        savedFilter: filterParsedJson
      })
    else
      yield put ({
        type: THREATFILTER_SAVE_FAILURE,
        message: filterParsedJson.error.text._error
          ? filterParsedJson.error.text._error
          : filterParsedJson.error.text
      })

  } catch (error) {
    log.error('saveThreatFilterSaga error', error)
  }
}

export function* loadThreatFilterSaga ({personId}) {
  try {
    const authToken = yield select((state) => state.login.authToken);
    const filtersParsedJson = yield call(fetchApi, `/Persons/${personId}/filters`, {
      authToken,
      method: 'GET'
    })

    if (!filtersParsedJson.error) {
      const customSavedFilters = filtersParsedJson.map(f => ({
        ...f,
        isCustom: true
      }));
      yield put({
        type: THREATFILTER_LOAD_SUCCESS,
        savedFilters: customSavedFilters
      })
      return customSavedFilters;
    } else
      yield put ({
        type: THREATFILTER_LOAD_FAILURE,
        message: filtersParsedJson.error.text._error
          ? filtersParsedJson.error.text._error
          : filtersParsedJson.error.text
      })

  } catch (error) {
    log.error('loadThreatFilterSaga error', error)
  }
}

export function* updateFilterControls ({id}) {
  try {
    const preset = yield select(
        state =>
            (state.threatfilter.savedFilters.find(f => f.id === id) || {}).options)
    if (!preset) return;
    const threatTypes = preset.typeVal
    const popSliderVal = preset.popSliderVal
    const snowSliderVal = preset.snowSliderVal
    const iceSliderVal = preset.iceSliderVal
    const timingVal = preset.timingVal
    const locVal = preset.locVal

    yield put ({
      type: THREATTYPESELECTOR_SET_VALUES,
      cbGroupId: THREATFILTER,
      threatTypes
    })
    yield put ({
      type: FILTERSLIDER_SELECT,
      sliderId: `${THREATFILTER}_pop`,
      value: popSliderVal
    })
    yield put ({
      type: FILTERSLIDER_SELECT,
      sliderId: `${THREATFILTER}_snow`,
      value: snowSliderVal
    })
    yield put ({
      type: FILTERSLIDER_SELECT,
      sliderId: `${THREATFILTER}_ice`,
      value: iceSliderVal
    })
    yield put ({
      type: TIMINGSELECTOR_SET_TIME,
      groupId: `${THREATFILTER}`,
      timing: timingVal
    })
    yield put ({
      type: LOCATIONSELECTOR_SET_VALUES,
      groupId: THREATFILTER,
      values: locVal
    })
  } catch (error) {
    log.error('updateFilterControls error', error)
  }
}

export function* deleteThreatFilterPresetSaga ({personId, id}) {
  try {
    const authToken = yield select((state) => state.login.authToken);
    const deleteParsedJson = yield call(fetchApi, `/Persons/${personId}/filters/${id}`, {
      authToken,
      method: 'DELETE'
    })

    if (!deleteParsedJson.error) {
      yield put ({
        type: THREATFILTER_DELETE_PRESET_SUCCESS
      })
      yield put ({
        type: THREATFILTER_LOAD_SUBMIT,
        personId
      })
    } else {
      yield put ({
        type: THREATFILTER_DELETE_PRESET_FAIL,
        message: deleteParsedJson.error.text._error
          ? deleteParsedJson.error.text._error
          : deleteParsedJson.error.text
      })
    }
  } catch (error) {
    log.error('deleteThreatFilterPresetSaga error', error)
  }
}

export default function* threatFilterSaga () {
  yield takeEvery(THREATFILTER_SAVE_SUBMIT, saveThreatFilterSaga)
  yield takeEvery(THREATFILTER_LOAD_SUBMIT, loadThreatFilterSaga)
  yield takeEvery(THREATFILTER_APPLY_PRESET, updateFilterControls)
  yield takeEvery(THREATFILTER_DELETE_PRESET_SUBMIT, deleteThreatFilterPresetSaga)
}
