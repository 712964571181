import { connect } from 'react-redux';
import SiteSelectorComponent from './SiteSelectorComponent';
import {
  addSite,
  delSite,
  setSiteSelectedBy,
  setSiteSelectedOrderBy,
  setInputValue,
  reset
} from './SiteSelectorDuck';
import makeGetDataSources, {
  makeGetSites,
} from './SiteSelectorSelectors';

// this component is used in multiple places, therefore make sure each instance gets their own reselectors

const makeMapStateToProps = (state, ownProps) => {
  const useMySitesFilter =
    ownProps.showMySitesToggle !== undefined
      ? ownProps.showMySitesToggle
      : true;

  const getSites = makeGetSites(ownProps.selectSiteFunc);

  const getDataSources = makeGetDataSources(ownProps.selectSiteFunc);

  const mapStateToProps = (state, ownProps) => {
    // allow showing/not showing the company sites/my sites filter

    return {
      companyId: ownProps.companyId,
      sites: getSites(state, useMySitesFilter, ownProps.companyId),
      dataSources: getDataSources(state, useMySitesFilter, ownProps.companyId),
      selectSiteBy: state.siteselector.selectSiteBy,
      selectSiteOrderBy: state.siteselector.selectSiteOrderBy,
      inputValue: state.siteselector.inputValue || "",
      showMySitesToggle: useMySitesFilter,
      hasMySites: !!state.mySites.siteIds.length,
      handleSelect: ownProps.handleSelect
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = dispatch => ({
  addSite: id => dispatch(addSite(id)),
  delSite: id => dispatch(delSite(id)),
  setSiteSelectedBy: selectSiteBy => dispatch(setSiteSelectedBy(selectSiteBy)),
  setSiteSelectedOrderBy: selectSiteBy => dispatch(setSiteSelectedOrderBy(selectSiteBy)),
  setInputValue: value => dispatch(setInputValue(value)),
  reset: () => dispatch(reset())
});

const SiteSelectorContainer = connect(makeMapStateToProps, mapDispatchToProps)(
  SiteSelectorComponent
);

export default SiteSelectorContainer;
