//import { arr2obj } from 'common/helpers/datahelpers';

export const APISTATS_SUBMIT = 'APISTATS_SUBMIT';
export const APISTATS_SUCCESS = 'APISTATS_SUCCESS';
export const APISTATS_FAILURE = 'APISTATS_FAILURE';
export const APISTATS_REQUEST = 'APISTATS_REQUEST';

export const requestApiStats = companyId => ({
  type: APISTATS_REQUEST,
  companyId
});

export const loadApiStats = companyId => ({
  type: APISTATS_SUBMIT,
  companyId
});

const initialState = {
  data: [],
  meta: { isFetching: false }
};

const apiStats = (state = initialState, action) => {
  switch (action.type) {
    case APISTATS_SUBMIT:
      return {
        ...state,
        meta: { ...state.meta, isFetching: true }
      };

    case APISTATS_SUCCESS:
      return {
        ...state,
        data: action.data,
        meta: { 
          ...state.meta,
          isFetching: false,
          fetchError: undefined,
          isDataLoaded: true
        }
      };

    case APISTATS_FAILURE:
      return {
        ...state,
        meta: { 
          ...state.meta, 
          fetchError: action.message,
          isFetching: false
        }
      };

    default:
      return state;
  }
};

export default apiStats;
