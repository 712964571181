import { arr2obj } from 'common/helpers/datahelpers'

export const PERSONDATA_SUBMIT = 'PERSONDATA_SUBMIT'
export const PERSONDATA_SUCCESS = 'PERSONDATA_SUCCESS'
export const PERSONDATA_FAILURE = 'PERSONDATA_FAILURE'
export const PERSONDEL_SUBMIT = 'PERSONDEL_SUBMIT'
export const PERSONDEL_SUCCESS = 'PERSONDEL_SUCCESS'
export const PERSONDEL_FAILURE = 'PERSONDEL_FAILURE'
export const SET_PERSON_SITE = 'SET_PERSON_SITE'
export const SUPERADMINDATA_SUBMIT = 'SUPERADMINDATA_SUBMIT'
export const SUPERADMINDATA_SUCCESS = 'SUPERADMINDATA_SUCCESS'
export const SUPERADMINDATA_FAILURE = 'SUPERADMINDATA_FAILURE'

export const loadPersons = (companyId) => ({
  type: PERSONDATA_SUBMIT,
  companyId
})

export const loadSuperAdmins = () => ({
  type: SUPERADMINDATA_SUBMIT
})

export const deletePerson = (id, userType = 'person') => ({
  type: PERSONDEL_SUBMIT,
  id,
  userType
})


const persons = (state = {}, action) => {
  switch (action.type) {

    case PERSONDATA_SUCCESS:
      return arr2obj(action.payload);

    case SUPERADMINDATA_SUCCESS:
      return {
        ...state,
        ...arr2obj(action.payload)
      }

    case PERSONDEL_SUCCESS:
      let {[action.id]: deleted, ...newState} = state;
      return newState;

    default :
      return state;
  }
}

const initialState = {
  isDeleting: false,
  isFetching: false,
  deleteError: undefined
}

export const personsMeta = (state = initialState, action) => {
  switch (action.type) {

    case PERSONDEL_SUBMIT:
      return {
        ...state,
        isDeleting: true,
        isFetching: true
      }

    case PERSONDEL_SUCCESS:
    case PERSONDATA_SUCCESS:
      return initialState;

    case PERSONDEL_FAILURE:
      return {
        ...state,
        deleteError: action.message,
        isFetching: false
      };

    case PERSONDATA_SUBMIT:
      return {
        ...state,
        isFetching: true
      }

    default :
      return state;
  }
}

export default persons

