const nonWinterAdvisoryTypes = {
  misc: [
    "911 Telephone Outage",
    "Administrative Message",
    "Child Abduction Emergency",
    "Civil Emergency Message",
    "Evacuation - Immediate",
    "Extreme Fire Danger",
    "Local Area Emergency",
    "Short Term Forecast"
  ],
  alert: ["Air Quality Alert"],
  advisory: [
    "Air Stagnation Advisory",
    "Ashfall Advisory",
    "Blowing Dust Advisory",
    "Blowing Snow Advisory",
    "Brisk Wind Advisory",
    "Coastal Flood Advisory",
    "Dense Fog Advisory",
    "Dense Smoke Advisory",
    "Flood Advisory",
    "Freezing Drizzle Advisory",
    "Freezing Fog Advisory",
    "Freezing Spray Advisory",
    "Frost Advisory",
    "Heat Advisory",
    "High Surf Advisory",
    "Hydrologic Advisory",
    "Lake Wind Advisory",
    "Lakeshore Flood Advisory",
    "Low Water Advisory",
    "Small Craft Advisory",
    "Small Craft Advisory For Hazadous Seas",
    "Small Craft Advisory for Rough Bar",
    "Small Craft Advisory for Winds",
    "Snow and Blowing Snow Advisory",
    "Tsunami Advisory",
    "Weather Advisory",
    "Wind Advisory",
    "Wind Chill Advisory",
    "Winter Weather Advisory"
  ],
  warning: [
    "Arctic Outflow Warning",
    "Ashfall Warning",
    "Avalanche Warning",
    "Blizzard Warning",
    "Civil Danger Warning",
    "Coastal Flood Warning",
    "Dust Storm Warning",
    "Earthquake Warning",
    "Excessive Heat Warning",
    "Extreme Cold Warning",
    "Extreme Wind Warning",
    "Fire Warning",
    "Flash Flood Warning",
    "Flash Freeze Warning",
    "Flood Warning",
    "Freeze Warning",
    "Freezing Rain Warning",
    "Gale Warning",
    "Hard Freeze Warning",
    "Hazardous Materials Warning",
    "Hazardous Seas Warning",
    "Heat Warning",
    "Heavy Freezing Spray Warning",
    "High Surf Warning",
    "High Wind Warning",
    "Hurricane Force Wind Warning",
    "Hurricane Warning",
    "Ice Storm Warning",
    "Lakeshore Flood Warning",
    "Law Enforcement Warning",
    "Nuclear Power Plant Warning",
    "Radiological Hazard Warning",
    "Rainfall Warning",
    "Red Flag Warning",
    "Severe Thunderstorm Warning",
    "Shelter In Place Warning",
    "Smog Warning",
    "Snow Squall Warning",
    "Snowfall Warning",
    "Special Marine Warning",
    "Storm Surge Warning",
    "Storm Warning",
    "Tornado Warning",
    "Tropical Storm Warning",
    "Tsunami Warning",
    "Typhoon Warning",
    "Volcano Warning",
    "Weather Warning",
    "Wind Chill Warning",
    "Wind Warning",
    "Winter Storm Warning"
  ],
  watch: [
    "Avalanche Watch",
    "Coastal Flood Watch",
    "Excessive Heat Watch",
    "Extreme Cold Watch",
    "Fire Weather Watch",
    "Flash Flood Watch",
    "Flood Watch",
    "Freeze Watch",
    "Gale Watch",
    "Hard Freeze Watch",
    "Hazardous Seas Watch",
    "Heavy Freezing Spray Watch",
    "High Wind Watch",
    "Hurricane Force Wind Watch",
    "Hurricane Watch",
    "Lakeshore Flood Watch",
    "Severe Thunderstorm Watch",
    "Snow Squall Watch",
    "Storm Surge Watch",
    "Storm Watch",
    "Tornado Watch",
    "Tropical Storm Watch",
    "Tsunami Watch",
    "Typhoon Watch",
    "Wind Chill Watch",
    "Winter Storm Watch"
  ],
  statement: [
    "Beach Hazard Statement",
    "Coastal Flood Statement",
    "Flash Flood Statement",
    "Flood Statement",
    "Hurricane Local Statement",
    "Lakeshore Flood Statement",
    "Marine Weather Statement",
    "Rip Current Statement",
    "Severe Weather Statement",
    "Special Weather Statement",
    "Tropical Cyclone Statement",
    "Tropical Storm Local Statement",
    "Typhoon Local Statement"
  ],
  outlook: ["Hazardous Weather Outlook"]
};

const winterAdvisoryTypes = [
    "Avalanche Warning",
    "Avalanche Watch",
    "Blizzard Warning",
    "Blowing Snow Advisory",
    "Ice Storm Warning",
    "Law Enforcement Warning",
    "Snowfall Warning",
    "Wind Chill Advisory",
    "Wind Chill Warning",
    "Wind Chill Watch",
    "Winter Storm Warning",
    "Winter Storm Watch",
    "Winter Weather Advisory",
    "Severe Thunderstorm Warning",
    "Severe Thunderstorm Watch",
    "Severe Thunderstorm Advisory",
    "Wind Watch",
    "Wind Warning",
    "Wind Advisory"
  ];

export { nonWinterAdvisoryTypes, winterAdvisoryTypes };
