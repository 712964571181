import { arr2obj } from 'common/helpers/datahelpers';
import immutable from 'object-path-immutable';
import { COMPANYSUBSCRIPTION_SUCCESS, COMPANY_SHOW_LOGO_SUCCESS  } from 'Admin/CompanyEdit/CompanyEditDuck';

export const SUBSCRIPTIONDATA_SUBMIT = 'SUBSCRIPTIONDATA_SUBMIT';
export const SUBSCRIPTIONDATA_SUCCESS = 'SUBSCRIPTIONDATA_SUCCESS';
export const SUBSCRIPTIONDATA_FAILURE = 'SUBSCRIPTIONDATA_FAILURE';
export const SUBSCRIPTION_ACTV_TRIAL_SUBMIT = 'SUBSCRIPTION_ACTV_TRIAL_SUBMIT';
export const APIPACKAGE_ADD_ONTHEFLY = 'APIPACKAGE_ADD_ONTHEFLY';

export const loadSubscriptions = companyId => ({
  type: SUBSCRIPTIONDATA_SUBMIT,
  companyId
});

export const addApiPackageOnTheFly = companyId => ({
  type: APIPACKAGE_ADD_ONTHEFLY,
  companyId
});

export const activateTrialCompany = companyId => ({
  type: SUBSCRIPTION_ACTV_TRIAL_SUBMIT,
  companyId
});

const initialState = {};

const subscriptions = (state = initialState, action) => {
  switch (action.type) {
    case SUBSCRIPTIONDATA_SUCCESS:
      return arr2obj(action.payload);

    case COMPANYSUBSCRIPTION_SUCCESS:
      return {
        ...state,
        [action.subscriptionObj.id]: action.subscriptionObj
      };

    case COMPANY_SHOW_LOGO_SUCCESS:
      return immutable.set(state, `${action.subscriptionId}.showLogo`, action.showLogo)

    default:
      return state;
  }
};

export default subscriptions;
