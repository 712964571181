export const SITEPAGE_SELECT_SITE = "SITEPAGE_SELECT_SITE";
export const SITEPAGE_SET_SORT_COLUMN = "SITEPAGE_SET_SORT_COLUMN";
export const SITEPAGE_SET_PAGESIZE = "SITEPAGE_SET_PAGESIZE";
export const SITEPAGE_SET_RUBRIC_FILTER = "SITEPAGE_SET_RUBRIC_FILTER";
export const SITEPAGE_SET_BULK_TOGGLE = "SITEPAGE_SET_BULK_TOGGLE";
export const SITEPAGE_SET_PULSING_RUBRIC = "SITEPAGE_SET_PULSING_RUBRIC";

export const setSelectedSite = id => ({
  type: SITEPAGE_SELECT_SITE,
  id
});

export const setSortColumn = (sortColumn, sortDirection) => ({
  type: SITEPAGE_SET_SORT_COLUMN,
  sortColumn,
  sortDirection
});

export const setPageSize = pageSize => ({
  type: SITEPAGE_SET_PAGESIZE,
  pageSize
});

export const setRubricFilter = rubric => ({
  type: SITEPAGE_SET_RUBRIC_FILTER,
  rubric
});

export const setBulkToggle = bulkToggleState => ({
  type: SITEPAGE_SET_BULK_TOGGLE,
  bulkToggleState
});

const initialState = {
  selectedSite: undefined,
  sortColumn: "name",
  sortDirection: 1,
  rubric: "all",
  bulkToggleState: undefined,
  pulsingRubric: undefined
};

const sitesManage = (state = initialState, action) => {
  switch (action.type) {
    case SITEPAGE_SELECT_SITE:
      return {
        ...state,
        selectedSite: action.id,
        pulsingRubric: undefined
      };

    case SITEPAGE_SET_SORT_COLUMN:
      return {
        ...state,
        sortColumn: action.sortColumn,
        sortDirection: action.sortDirection
      };

    case SITEPAGE_SET_PAGESIZE:
      return {
        ...state,
        selectedSite: undefined,
        pageSize: Number(action.pageSize)
      };

    case SITEPAGE_SET_RUBRIC_FILTER:
      return {
        ...state,
        rubric: action.rubric
      };

    case SITEPAGE_SET_PULSING_RUBRIC:
      return {
        ...state,
        pulsingRubric: action.rubric
      };

    case SITEPAGE_SET_BULK_TOGGLE:
      return {
        ...state,
        bulkToggleState: action.bulkToggleState
      };

    // different company has different optimal page size, so reset here
    case "COMPANYPAGE_SELECT_COMPANY":
      return {
        ...state,
        pageSize: undefined
      };

    default:
      return state;
  }
};

export default sitesManage;
