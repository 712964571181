export const REPORTPICKUP_SUBMIT = 'REPORTPICKUP_SUBMIT';
export const REPORTPICKUP_SUCCESS = 'REPORTPICKUP_SUCCESS';
export const REPORTPICKUP_FAILURE = 'REPORTPICKUP_FAILURE';
export const REPORTPICKUP_SET_SORT_COLUMN = 'REPORTPICKUP_SET_SORT_COLUMN';
export const REPORTCREATEZIP_SUBMIT = 'REPORTCREATEZIP_SUBMIT';
export const REPORTCREATEZIP_SUCCESS = 'REPORTCREATEZIP_SUCCESS';
export const REPORTCREATEZIP_FAILURE = 'REPORTCREATEZIP_FAILURE';
export const REPORTDOWNLOADZIP_SUBMIT = 'REPORTDOWNLOADZIP_SUBMIT';
export const REPORTDOWNLOADZIP_SUCCESS = 'REPORTDOWNLOADZIP_SUCCESS';
export const REPORTDOWNLOADZIP_FAILURE = 'REPORTDOWNLOADZIP_FAILURE';

export const ALL_REPORTS = 'ALL_REPORTS';
export const SELECTED_REPORTS = 'SELECTED_REPORTS';

export const createSiteReportZip = (
  token,
  reportApiUrl,
  reportList,
  zipType
) => ({
  type: REPORTCREATEZIP_SUBMIT,
  token,
  reportApiUrl,
  reportList,
  zipType
});

export const loadSiteReportData = (token, reportApiUrl) => ({
  type: REPORTPICKUP_SUBMIT,
  token,
  reportApiUrl
});

export const setSortColumn = (sortColumn, sortDirection) => ({
  type: REPORTPICKUP_SET_SORT_COLUMN,
  sortColumn,
  sortDirection
});

const initialState = {
  isFetching: false,
  isCreatingZip: false,
  isDownloadingZip: false,
  zipType: '',
  message: '',
  data: {},
  sortColumn: 'name',
  sortDirection: 1,
  zipName: ''
};

const reportPickup = (state = initialState, action) => {
  switch (action.type) {
    case REPORTPICKUP_SUBMIT:
      return {
        ...state,
        isFetching: true
      };

    case REPORTPICKUP_SUCCESS:
      return {
        ...state,
        summary: action.data.summary,
        isFetching: false,
        message: ''
      };

    case REPORTPICKUP_FAILURE:
      return {
        ...state,
        message: action.message,
        isFetching: false
      };

    case REPORTPICKUP_SET_SORT_COLUMN:
      return {
        ...state,
        sortColumn: action.sortColumn,
        sortDirection: action.sortDirection
      };

    case REPORTCREATEZIP_SUBMIT:
      return {
        ...state,
        isCreatingZip: true,
        zipName: '',
        zipType: action.zipType
      };

    case REPORTCREATEZIP_SUCCESS:
      return {
        ...state,
        zipName: action.result.name,
        zipType: '',
        isCreatingZip: false
      };

    case REPORTCREATEZIP_FAILURE:
      return {
        ...state,
        isCreatingZip: false,
        message: action.message
      };

    case REPORTDOWNLOADZIP_SUBMIT:
      return {
        ...state,
        isDownloadingZip: true,
        message: ''
      };
    case REPORTDOWNLOADZIP_SUCCESS:
      return {
        ...state,
        isDownloadingZip: false,
        zipType: '',
        message: ''
      };
    case REPORTDOWNLOADZIP_FAILURE:
      return {
        ...state,
        isDownloadingZip: false,
        message: action.message
      };

    default:
      return state;
  }
};

export default reportPickup;
