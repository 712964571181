// This file implements the "ducks" pattern, i.e. combines action constants,
// action creators and reducers in one file instead of having multiple files per
// function. As long as there are not too many values, this makes it nice and
// compact.

import orderBy from "lodash/orderBy";
import head from "lodash/head";
import format from 'date-fns/format';
import { DATA_SUCCESS } from 'common/dataRetrieval/DataDuck';

// Action constants

export const NOTIFICATIONFILTER_RESET = 'NOTIFICATIONFILTER_RESET';
export const NOTIFICATIONFILTER_SET_TYPE = 'NOTIFICATIONFILTER_SET_TYPE';
export const NOTIFICATIONFILTER_SET_DATE_FROM = 'NOTIFICATIONFILTER_SET_DATE_FROM';
export const NOTIFICATIONFILTER_SET_DATE_TO = 'NOTIFICATIONFILTER_SET_DATE_TO';


// Action creators

export const resetForm = () => ({
  type: NOTIFICATIONFILTER_RESET
})

export const setNotificationType = (notificationType) => ({
  type: NOTIFICATIONFILTER_SET_TYPE,
  notificationType
})

export const setDateFrom = (date) => ({
  type: NOTIFICATIONFILTER_SET_DATE_FROM,
  date
})

export const setDateTo = (date) => ({
  type: NOTIFICATIONFILTER_SET_DATE_TO,
  date
})

// Reducer
const initialState = {
  notificationType: '-select',
  dateFrom: '',
  dateTo: ''
};


const notificationfilter = (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATIONFILTER_RESET:
      return initialState;

    case NOTIFICATIONFILTER_SET_TYPE:
      return {
        ...state,
        notificationType: action.notificationType
      }

    case DATA_SUCCESS:
      // set default filter date from notifications data
      const firstNotification = 
        head(orderBy(action.payload.notifications, 'createdAt', 'desc'));
      const createdAt = 
        format(firstNotification ? firstNotification.createdAt : '', 'YYYY-MM-DD')
      return {
        ...state,
        dateFrom: state.dateFrom || createdAt
      }

    case NOTIFICATIONFILTER_SET_DATE_FROM:
      return {
        ...state,
        dateFrom: action.date
      }

    case NOTIFICATIONFILTER_SET_DATE_TO:
      return {
        ...state,
        dateTo: action.date
      }

    default:
      return state

  }
}

export default notificationfilter;

