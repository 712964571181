import React from 'react';
import { WwaCheckbox } from "../../common/components/WwaInputElements";
import { winterTypeIcons } from "common/helpers/helpers";
import 'common/css/inputElements.css'

const getWinterIcon = (winterType, on) =>
  winterType ? (
    <img
      src={winterTypeIcons[winterType][true]}
      alt={winterType}
      style={{ width: "20px", height: "20px", opacity: "0.8", marginRight: "0.3em" }}
    />
  ) : null;

const BulkSiteReportCheckbox = ({id, checked, sendIfNecessary, disabled, handleReportCheckboxChange, handleReportSubtypeChange, winterType}) => (
  <div>
    {getWinterIcon(winterType, true)}
    <WwaCheckbox id={id}
                 name={'site-report'}
                 onChange={(e) => handleReportCheckboxChange(e)}
                 checked={checked}
                 disabled={disabled}
    />
    { id !== "snowtify"
      ? 
        <select
          id={id}
          disabled={!checked ? true : false}
          onChange={(e) => handleReportSubtypeChange(e)}
          className='wwa__select'
          value={sendIfNecessary}
        >
          <option
            className='SiteSel__option'
            key={1}
            value={false}
          >Daily
          </option>
          <option
            className='SiteSel__option'
            key={2}
            value={true}
          >Events Only
          </option>
        </select>
      : 
        <span> Snowtify </span>
    }
  </div>
)

export default BulkSiteReportCheckbox
