export const WATCHLISTDATA_SUBMIT = 'WATCHLISTDATA_SUBMIT'
export const WATCHLISTDATA_SUCCESS = 'WATCHLISTDATA_SUCCESS'

export const loadWatchlistStats = () => ({
  type: WATCHLISTDATA_SUBMIT
})

const initialState = {
}

const watchListByCompanyId = (data) => (
  data
    .map(wl => wl.companyId)
    .reduce((acc, cid) => {
        acc[cid] = acc[cid] ? acc[cid] + 1 : 1;
        return acc
      },
      {}
    )
);

export const watchlistStats = (state = initialState, action) => {
  switch (action.type) {

    case WATCHLISTDATA_SUCCESS:
      return watchListByCompanyId(action.payload);

    default :
      return state;
  }
}

export default watchlistStats

