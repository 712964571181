import log from 'loglevel';
import fetchApi from 'utils/fetchApi'
import { put, takeEvery, call, select } from 'redux-saga/effects'
import {
  REPORTSETTINGSDATA_SUBMIT,
  REPORTSETTINGSDATA_SUCCESS,
  REPORTSETTINGSDATA_FAILURE,
  REPORTSETTINGS_SAVE_SUBMIT,
  REPORTSETTINGS_SAVE_SUCCESS,
  REPORTSETTINGS_SAVE_FAILURE,
  REPORTCUSTOM_SAVE_SUBMIT,
  REPORTCUSTOM_SAVE_SUCCESS,
  REPORTCUSTOM_SAVE_FAILURE,
  REPORTCUSTOM_GET_SUBMIT,
  REPORTCUSTOM_GET_SUCCESS,
  REPORTCUSTOM_GET_FAILURE
} from "./ReportSettingsDuck";

const getUrl = companyId => `/Companies/${companyId}/companyReportTimes`

function* fetchReportSettings({companyId, reportType, hour}) {
  try {
    const authToken = yield select((state) => state.login.authToken);
    const parsedJson = yield call(fetchApi, getUrl(companyId),
      { authToken, method: 'GET' })

    if (!parsedJson.error){
      yield put ({
        type: REPORTSETTINGSDATA_SUCCESS,
        companyId,
        reportSettings: parsedJson
      })
    } else {
      yield put ({
        type: REPORTSETTINGSDATA_FAILURE,
        message: parsedJson.error.text._error ? parsedJson.error.text._error : parsedJson.error.text,
      })
    }

  } catch (error) {
    log.error('fetchReportSettings error', error)
  }
}

function* saveReportSettings({companyId, toDel, toAdd, toUpdate}) {
  try {
    const authToken = yield select((state) => state.login.authToken);
    const oldReportSettings = yield select((state) => state.reportSettings.data[companyId]);

    // consolidate/diff old and new settings
    let newReportSettings = [...oldReportSettings]
    let backendError

    for (let rs of toDel) {
      if (backendError) break;
      const parsedJson = yield call(fetchApi, `${getUrl(companyId)}/${rs.id}`,
        {method: 'DELETE', authToken});
      newReportSettings = newReportSettings.filter(rs1 => rs1.id !== rs.id)
      if (parsedJson.error) {backendError = parsedJson; break;}
    }

    for (let rs of toAdd){
      if (backendError) break;
      const payload = { hour: rs.hour, reportType: rs.reportType, companyId };
      const parsedJson = yield call(fetchApi, getUrl(companyId),
        { payload, authToken });
      newReportSettings.push(parsedJson)
      if (parsedJson.error) {backendError = parsedJson; break;}
    }

    for (let rs of toUpdate) {
      if (backendError) break;
      const payload = { hour: rs.hour, reportType: rs.reportType, companyId };
      const parsedJson = yield call(fetchApi, `${getUrl(companyId)}/${rs.id}`,
        { method: 'PUT', payload, authToken });
      newReportSettings = newReportSettings.map(newrs => newrs.id === parsedJson.id ? parsedJson : newrs)
      if (parsedJson.error) {backendError = parsedJson; break;}
    }

    if (!backendError){
      yield put ({
        type: REPORTSETTINGS_SAVE_SUCCESS,
        companyId,
        reportSettings: newReportSettings
      })
    } else {
      log.warn('backenderror:', backendError)
      yield put ({
        type: REPORTSETTINGS_SAVE_FAILURE,
        message: backendError.error.text._error ? backendError.error.text._error : backendError.error.text,
      })
    }

  } catch (error) {
    log.error('saveReportSettings error', error)
  }
}

function* saveCustomReportValues({companyId, name, values, customReportId}) {
  try {
    const authToken = yield select((state) => state.login.authToken);

    const threatTypeValueMap = Object.keys(values.threatWeatherPrimaryCodes)
      .filter(function checkedCodesOnly(c) {return values.threatWeatherPrimaryCodes[c]})
      .reduce((acc,c) => {acc[c] = true; return acc}, {});
    values.threatWeatherPrimaryCodes = threatTypeValueMap;

    const parsedJson = yield call(fetchApi, `/Companies/${companyId}/customReports/${customReportId||''}`,
      {
        method: customReportId ? 'PUT' : 'POST', 
        payload: {values, name, active: true},
        authToken
      });

    if (!parsedJson.error){
      yield put ({
        type: REPORTCUSTOM_SAVE_SUCCESS,
        companyId,
        reportSettings: parsedJson
      });
      yield put ({
        type: REPORTCUSTOM_GET_SUBMIT,
        companyId
      });
    } else {
      yield put ({
        type: REPORTCUSTOM_SAVE_FAILURE,
        apiError: parsedJson.error.text._error ? parsedJson.error.text._error : parsedJson.error.text,
      })
    }

  } catch (error) {
    log.error('saveCustomReportValues error', error)
    yield put ({
      type: REPORTCUSTOM_SAVE_FAILURE,
      apiError: 'Network Error.'
    })
  }
}

function* getCustomReportValues({companyId}) {
  try {
    const authToken = yield select((state) => state.login.authToken);
    const parsedJson = yield call(fetchApi, `/Companies/${companyId}/customReports`,
      {
        method: 'GET', 
        authToken
      });

    if (!parsedJson.error){
      yield put ({
        type: REPORTCUSTOM_GET_SUCCESS,
        companyId,
        customReports: parsedJson
      })
    } else {
      yield put ({
        type: REPORTCUSTOM_GET_FAILURE,
        apiError: parsedJson.error.text._error ? parsedJson.error.text._error : parsedJson.error.text,
      })
    }

  } catch (error) {
    log.error('getCustomReportValues error', error)
    yield put ({
      type: REPORTCUSTOM_GET_FAILURE,
      apiError: 'Network Error.'
    })
  }
}

export default function* reportSettingsSaga () {
  yield takeEvery (REPORTSETTINGSDATA_SUBMIT, fetchReportSettings)
  yield takeEvery (REPORTSETTINGS_SAVE_SUBMIT, saveReportSettings)
  yield takeEvery (REPORTCUSTOM_SAVE_SUBMIT, saveCustomReportValues)
  yield takeEvery (REPORTCUSTOM_GET_SUBMIT, getCustomReportValues)
}
