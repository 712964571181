// This file implements the "ducks" pattern, i.e. combines action constants,
// action creators and reducers in one file instead of having multiple files per
// function. As long as there are not too many values, this makes it nice and
// compact.

// The 3 "read" states are: completely new -- hovered over -- clicked&read
export const NOTIFICATION_NEW = 'NOTIFICATION_NEW';
export const NOTIFICATION_NOTICED = 'NOTIFICATION_NOTICED';
export const NOTIFICATION_READ = 'NOTIFICATION_READ';

// Action constants

export const NOTIFICATION_SET_NOTICED = 'NOTIFICATION_SET_NOTICED';
export const NOTIFICATION_SET_READ = 'NOTIFICATION_SET_READ';
export const NOTIFICATION_SET_NEW = 'NOTIFICATION_SET_NEW';

// Action creators

export const setNew = (tileId) => ({
  type: NOTIFICATION_SET_NEW,
  tileId
})

export const setNoticed = (tileId) => ({
  type: NOTIFICATION_SET_NOTICED,
  tileId
})

export const setRead = (tileId) => ({
  type: NOTIFICATION_SET_READ,
  tileId
})

// Reducer

const initialState = {
  notifications: {}
};

const notificationTileReducer = (state = initialState, action) => {

  const changeStatus = (notifications, id, newState) => {
    return {...notifications, [id]:{state: newState}};
  }

  switch (action.type) {
    case NOTIFICATION_SET_NEW:
      return {
        ...state,
        notifications: changeStatus (state.notifications, action.tileId, NOTIFICATION_NEW) 
      }
    case NOTIFICATION_SET_NOTICED:
      return {
        ...state,
        notifications: changeStatus (state.notifications, action.tileId, NOTIFICATION_NOTICED) 
      }
    case NOTIFICATION_SET_READ:
      return {
        ...state,
        notifications: changeStatus (state.notifications, action.tileId, NOTIFICATION_READ) 
      }

    default:
      return state

  }
}

export default notificationTileReducer;

