import log from "loglevel";
import { put, takeEvery, call, select } from "redux-saga/effects";
import fetchApi, { postFormData } from "utils/fetchApi";
import { AVATAR_GET_SUBMIT } from "common/components/Profile/ProfileDuck";
import {
  IMAGE_UPLOAD_FAILURE,
  IMAGE_UPLOAD_SUCCESS,
  IMAGE_UPLOAD_SUBMIT,
  IMAGE_FETCH_FAILURE,
  IMAGE_FETCH_SUCCESS,
  IMAGE_FETCH_SUBMIT,
} from "common/ducks/imageUploadDuck";
import { COMPANY_SHOW_LOGO_SUBMIT } from 'Admin/CompanyEdit/CompanyEditDuck';

export function* postImage({file, fileName, imgType, companyId}) {
  try {
    const login = yield select(state => state.login);
    const authToken = login.authToken;
    const userId = login.userId;

    const isAvatar = imgType === "avatar";

    let url = isAvatar
      ? `/Persons/${userId}/avatar`
      : `/Companies/${companyId}/logo`;
    let body = new FormData();
    body.append("file", file, fileName);

    const parsedJson = yield call(postFormData, url, { body, authToken });

    if (!parsedJson.error) {
      yield put({
        type: IMAGE_UPLOAD_SUCCESS,
        data: parsedJson
      });

      if (isAvatar)
        yield put({
          type: AVATAR_GET_SUBMIT,
          userId: userId
        });
      else
        yield put({
          type: IMAGE_FETCH_SUBMIT,
          imgType,
          id: companyId
        });
        yield put ({
          type: COMPANY_SHOW_LOGO_SUBMIT,
          companyId,
          showLogo: true
        });
    } else {
      yield put({
        type: IMAGE_UPLOAD_FAILURE,
        payload: parsedJson.error.text._error
          ? parsedJson.error.text._error
          : parsedJson.error.text
      });
    }
  } catch (error) {
    log.error("postImage", error);
    yield put({
      type: IMAGE_UPLOAD_FAILURE,
      message: "Network error."
    });
  }
}

export function* fetchImage ({id, imgType}) {
  try {
    const login = yield select((state) => state.login);
    const authToken = login.authToken;
    const isAvatar = imgType === "avatar";

    if (!id){
      yield put({ type: IMAGE_FETCH_FAILURE,
        payload: 'ID undefined'
      })
    } else {
      let url = isAvatar
        ? `/Persons/${id}/avatar`
        : `/Companies/${id}/logo`;
      const result = yield call(fetchApi, url, { authToken, method: 'GET' })

      if (!result.error && !result.notfound){
        if (result.status !== "Not found"){
          const objectURL = URL.createObjectURL(result);
          yield put({
            type: IMAGE_FETCH_SUCCESS,
            id,
            data: objectURL
          })
        } else {
          yield put({ type: IMAGE_FETCH_FAILURE,
            imageFetchError: `No ${imgType} image found.`,
            noImageFound: true
          })
        }
      } else {
        yield put({ type: IMAGE_FETCH_FAILURE,
          payload: result.error.text._error ? result.error.text._error : result.error.text
        })
      }
    }
  } catch (error) {
    log.error('fetchImage', error)
  }
}

export default function* personalDataSaga() {
  yield takeEvery(IMAGE_UPLOAD_SUBMIT, postImage);
  yield takeEvery(IMAGE_FETCH_SUBMIT, fetchImage);
}
