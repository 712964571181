import { COMPANYDELETE_SUCCESS } from 'Admin/CompanyEdit/CompanyEditDuck';

export const COMPANYPAGE_SELECT_COMPANY = 'COMPANYPAGE_SELECT_COMPANY'

export const setSelectedCompany = (id) => ({
  type: COMPANYPAGE_SELECT_COMPANY,
  id
})

const initialState = {
  selectedCompany: undefined
}

const companiesPage = (state = initialState, action) => {
    switch(action.type){
      case COMPANYPAGE_SELECT_COMPANY:
        return {
          ...state,
          selectedCompany: action.id
        }

      case COMPANYDELETE_SUCCESS:
        return initialState;

      default :
        return state;
    }
}

export default companiesPage

