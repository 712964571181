import { connect } from 'react-redux';
import ImageCropComponent from './ImageCropComponent';
import { uploadImage } from "common/ducks/imageUploadDuck";

const mapStateToProps = (state, ownProps) => ({
  imageError: state.imageUpload.imageError,
  isUploading: state.imageUpload.isUploading,
  imageUploaded: state.imageUpload.imageUploaded,
  companyId: ownProps.companyId
});

const mapDispatchToProps = dispatch => ({
  onSubmit: (file, fileName, companyId) => dispatch(uploadImage('companyLogo', file, fileName, companyId)),
});

const ImageCropContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ImageCropComponent)
export default ImageCropContainer;
