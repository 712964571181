// This file implements the "ducks" pattern, i.e. combines action constants,
// action creators and reducers in one file instead of having multiple files per
// function. As long as there are not too many values, this makes it nice and
// compact.


// Action constants

export const HEADER_SUBMIT = 'HEADER_SUBMIT';
export const HEADER_SUCCESS = 'HEADER_SUCCESS';
export const HEADER_FAILURE = 'HEADER_FAILURE';
export const HEADER_RESPONSIVE_MENU = 'HEADER_RESPONSIVE_MENU';
export const HEADER_RESPONSIVE_MENU_SET = 'HEADER_RESPONSIVE_MENU_SET';

export const toggleResponsiveMenu = () => ({
  type: HEADER_RESPONSIVE_MENU
})

export const setResponsiveMenu = (open=false) => ({
  type: HEADER_RESPONSIVE_MENU_SET,
  open
})

export const headerSearch = (searchTerm) => ({
  type: HEADER_SUBMIT,
  searchTerm
})


// Reducer

const initialState = {
  searchTerm: '',
  isFetching: false,
  isResponsiveMenu: false
};

const headerReducer = (state = initialState, action) => {
  switch (action.type) {
    case HEADER_RESPONSIVE_MENU:
      return {
        ...state,
        isResponsiveMenu: !state.isResponsiveMenu 
      }

    case HEADER_RESPONSIVE_MENU_SET:
      return {
        ...state,
        isResponsiveMenu: action.open
      }

    case HEADER_SUBMIT:
      return {
        ...state,
        searchTerm: action.searchTerm,
      }

    default:
      return state

  }
}

export default headerReducer;

