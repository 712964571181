import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import './BusySpinner.css';

const defaultStyle = {
  fontSize: '32px',
  marginTop: '20px',
  marginBottom: '20px',
  color: '#4758BE'
};

const inButtonStyle = { fontSize: '14px', marginRight: '8px' };

const BusySpinner = ({ style, inButton, spinning, tooltip='Refresh data' }) =>
  spinning !== undefined && spinning === false ? (
    <OverlayTrigger
      placement="bottom"
      overlay={<Tooltip id={tooltip}>{tooltip}</Tooltip>}
    >
      <span style={inButton ? inButtonStyle : style || defaultStyle}
            className="fa fa-refresh BusySpinner__base BusySpinner__refresh"
      >
      <i
        aria-hidden="true"
      />
    </span>
    </OverlayTrigger>
  ) : (
    <i
      style={inButton ? inButtonStyle : style || defaultStyle}
      className="fa fa-spinner fa-spin BusySpinner__base"
      aria-hidden="true"
    />
  );

BusySpinner.propTypes = {
  inButton: PropTypes.bool,
  spinning: PropTypes.bool,
  style: PropTypes.object
};

export default BusySpinner;
