// This file implements the "ducks" pattern, i.e. combines action constants,
// action creators and reducers in one file instead of having multiple files per
// function. As long as there are not too many values, this makes it nice and
// compact.

// Action constants

export const THREATTILE_SETHOVERED = 'THREATTILE_SETHOVERED';

// Action creators

// sets the "hovered" state of an unexpanded tile so other components can use
// this state
export const setHovered = (threatId, val) => ({
  type: THREATTILE_SETHOVERED,
  threatId, val
})


// Reducer

const initialState = {
};

const threatTileReducer = (state = initialState, action) => {
  switch (action.type) {
    case THREATTILE_SETHOVERED:
      return {
        ...state,
        hoveredTile: action.val ? action.threatId : undefined
      }

    default:
      return state

  }
}

export default threatTileReducer;

