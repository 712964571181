import log from 'loglevel';
import { put, takeEvery, call, select } from 'redux-saga/effects'
import fetchApi from 'utils/fetchApi'
import { PERSONDATA_SUBMIT, PERSONDATA_SUCCESS, 
  SUPERADMINDATA_SUBMIT, SUPERADMINDATA_SUCCESS, SUPERADMINDATA_FAILURE,
  PERSONDEL_SUBMIT, PERSONDEL_SUCCESS, PERSONDEL_FAILURE } from './PersonDuck'
import { SITE_PERSONDATA_SUBMIT } from './SiteDuck'
import {SET_PERSON_SITE} from 'common/dataRetrieval/PersonDuck'
import { INVITEPERSON_SUCCESS } from 'Admin/InvitePerson/InvitePersonDuck'
import { DATA_FAILURE } from 'common/dataRetrieval/DataDuck';
import { USER_REPORTSUBS_SUBMIT } from 'common/dataRetrieval/ReportsDuck'
import { isThirdPartyUser } from "common/helpers/helpers";

function* fetchPersonsCall({companyId}){

    try{
      const authToken = yield select((state) => state.login.authToken);
      const url = companyId
        ? `/Companies/${companyId}/Persons`
        : '/Persons'
      const personsParsedJson = yield call(fetchApi, url, {
          authToken,
          method: 'GET'
      })

      if (!personsParsedJson.error){
        yield put({
            type: PERSONDATA_SUCCESS,
            payload : personsParsedJson
        });

        for (let p of personsParsedJson) {
          yield put({
            type: USER_REPORTSUBS_SUBMIT,
            userId: p.id,
            userType: isThirdPartyUser(p) ? 'third-party-user' : 'person'
          });
        }
      } else {
        yield put({ type: DATA_FAILURE,
          message: personsParsedJson.error.text._error ? personsParsedJson.error.text._error : personsParsedJson.error.text,
          payload: personsParsedJson.error.text
        })
      }
    } catch(error){
      log.error('fetchPersonsCall', error)
    }
}

function* fetchSuperAdminsCall({companyId}){
  try{
    const authToken = yield select((state) => state.login.authToken);

    const superAdminQuery = {
      where : {
        roles : {
            inq : ["super-admin"]
        },
        companyId
      }
    };
    const url = '/Persons'
    const personsParsedJson = yield call(fetchApi, url, {
        authToken,
        method: 'GET',
        query : {
          filter : JSON.stringify(superAdminQuery)
        }    
      })
    
    if (!personsParsedJson.error){
      yield put({
          type: SUPERADMINDATA_SUCCESS,
          payload : personsParsedJson
      });
    } else {
      yield put({ type: SUPERADMINDATA_FAILURE,
        message: personsParsedJson.error.text._error ? personsParsedJson.error.text._error : personsParsedJson.error.text,
        payload: personsParsedJson.error.text
      })
    }
  } catch(error){
    log.error('fetchPersonsCall', error)
  }
}

function* deletePersonCall(action){

    try{
        const authToken = yield select((state) => state.login.authToken);
        const { id, userType } = action
        const url = userType === 'third-party-user'
          ? `/ThirdPartyUsers/${id}`
          : `/Persons/${id}`
        const result = yield call(fetchApi, url, {
            authToken,
            method: 'DELETE'
        })

        if (!result.error){
          yield put({
              type: PERSONDEL_SUCCESS,
              id
          })
        } else {
          yield put({ type: PERSONDEL_FAILURE,
            message: result.error.text._error ? result.error.text._error : result.error.text,
          })
        }

    } catch(error){
      log.error('deletePersonCall', error)
    }
}

// associate a person (site-admin, company-user) with a site
function* setPersonSite({siteId, personId, companyId}){
  try{
    const authToken = yield select((state) => state.login.authToken);
    const payload = {companyId}
    const result = yield call(fetchApi, `/Persons/${personId}/Sites/rel/${siteId}`, {
      authToken,
      payload,
      method: 'PUT'
    })

    if (result.error){
      yield put({ type: DATA_FAILURE,
        message: result.error.text._error ? result.error.text._error : result.error.text,
      })
      }
  } catch(error){
    log.error('setPersonSite', error)
  }
}

function* fetchSitePersonsAfterInviteCall({personObj}){
  if (personObj.roles.includes('site-admin') && personObj.siteId){
    yield put({
      type: SITE_PERSONDATA_SUBMIT,
      id: personObj.siteId,
      companyId: personObj.companyId
    })
  }

  if (personObj.roles.includes('super-admin'))
    yield put({type: PERSONDATA_SUBMIT})

}


export default function* personSaga () {
  yield takeEvery(PERSONDATA_SUBMIT, fetchPersonsCall)
  yield takeEvery(SUPERADMINDATA_SUBMIT, fetchSuperAdminsCall)
  yield takeEvery(PERSONDEL_SUBMIT, deletePersonCall)
  yield takeEvery(SET_PERSON_SITE, setPersonSite)
  yield takeEvery(INVITEPERSON_SUCCESS, fetchSitePersonsAfterInviteCall)
}
