import { idx } from 'common/helpers/helpers';
import immutable from 'object-path-immutable';
export const FILEUPLOAD_OPEN = 'FILEUPLOAD_OPEN';
export const FILEUPLOAD_CLOSE = 'FILEUPLOAD_CLOSE';
export const FILEUPLOAD_SUBMIT = 'FILEUPLOAD_SUBMIT';
export const FILEUPLOAD_SUCCESS = 'FILEUPLOAD_SUCCESS';
export const FILEUPLOAD_FAILURE = 'FILEUPLOAD_FAILURE';
export const CHANGELIST_SUBMIT = 'CHANGELIST_SUBMIT';
export const CHANGELIST_SUCCESS = 'CHANGELIST_SUCCESS';
export const CHANGELIST_FAILURE = 'CHANGELIST_FAILURE';
export const CHANGELIST_APPLY_SUBMIT = 'CHANGELIST_APPLY_SUBMIT';
export const CHANGELIST_APPLY_SUCCESS = 'CHANGELIST_APPLY_SUCCESS';
export const CHANGELIST_APPLY_FAILURE = 'CHANGELIST_APPLY_FAILURE';

export const closeUploadModal = () => ({
  type: FILEUPLOAD_CLOSE
})

export const openUploadModal = () => ({
  type: FILEUPLOAD_OPEN
})

export const uploadFile = (file, companyId) => ({
  type: FILEUPLOAD_SUBMIT,
  file,
  companyId
})

export const applyChangeList = (listData, companyId, allowMultiSiteAddresses) => ({
  type: CHANGELIST_APPLY_SUBMIT,
  listData,
  companyId,
  allowMultiSiteAddresses
})

export const uploadChangeListFile = (file, companyId, allowMultiSiteAddresses) => ({
  type: CHANGELIST_SUBMIT,
  file,
  companyId,
  allowMultiSiteAddresses
})

const initialState = {
  showModal: false,
  meta:{
    isFetching: false,
    fileName: undefined
  },
  // for CSV sites file upload:
  bulkData: {
    uploadError: undefined,
    uploadSuccess: false,
    recordsAdded: undefined
  },
  // for CSV sites file to check updates/inserts/deletes/untouched:
  changeListData: {
    status: undefined,
    // map of { inserts: {count:<num>,entries:[]}, updates: {..}, deletes: {..}, untouched: {..}}
    // inserts entries can have: "partialMatch":<bool>, "foundAddress":<str>
    list: undefined
  },
  applied: {
    uploadError: undefined,
    status: undefined,
    results: {}
  }
}

const handleUploadErrors = data => {
  switch (data.code) {
    case "FILE_PARSE_ERROR":
      return {
        _error: "File parse error."
      };
    case "NO_FILE":
      return {
        _error: "File is empty or doesn't exist."
      };
    case "EMPTY_FILE":
      return {
        _error: "File is empty."
      };
    case "NO_SITES":
      return {
        _error: "File doesn't contain sites."
      };
    case "NO_COMPANY":
      return {
        _error: "Company doesn't exist."
      };
    case "NO_ACTIVE_SUBS":
      return {
        _error: "Company doesn't have an active subscription."
      };
    case "GEOCODING_ERROR":
      return {
        _error: [{
            index: data.index,
            site: data.site,
            data: idx(['err', 'data'], data),
            details: idx(['err', 'details'], data),
        }],
        index: data.index 
      };
    case "TIMEZONE_ERROR":
      return {
        _error: "Timezone processing failed.",
        index: data.index 
      };
    case "INCORRECT_SITE_DATA":
      return {
        _error: data.invalidSiteDetails
      };
    case "UPDATE_CHANGED_FIELDS_MISSING":
      return {
        _error: "Some rows have missing fields. Please check your uploaded file. " +
        `Row(s): ${data.details.map(el => el.index+1).join(', ')}`
      };
    case "UPDATE_CHANGED_FIELDS_ERROR":
      return {
        _error: "Some rows have empty fields that must not be empty. Please check your uploaded file. " +
        `Row(s): ${data.details.map(el => el.index+1).join(', ')}`
      };
    case "INSERT_LATLNG_MISSING":
      return {
        _error: "Lat/Long missing"
      }

    default:
      return "";
  }
};

const fileUpload = (state = initialState, action) => {
  switch (action.type) {
    case FILEUPLOAD_OPEN:
      return {
        ...state,
        showModal: true,
      }

    case FILEUPLOAD_CLOSE:
      return initialState

    case FILEUPLOAD_SUBMIT:
      return immutable(state)
        .set("meta.isFetching", true)
        .set("meta.fileName", action.file.name)
        .set("bulkData", initialState.bulkData)
        .set("applied", initialState.applied)
        .set("changeListData", initialState.changeListData)
        .value();

    case FILEUPLOAD_SUCCESS:
      return immutable(state)
        .set("meta.isFetching", false)
        .set("bulkData.recordsAdded", action.data.added ? action.data.added.length : undefined)
        .set("bulkData.recordsErrorsList", action.data.errors || undefined)
        .set("bulkData.started", action.data.started)
        .set("bulkData.uploadSuccess", !action.data.code)
        .set("bulkData.uploadError", handleUploadErrors(action.data))
        .value();

    case FILEUPLOAD_FAILURE:
      return immutable(state)
        .set("meta.isFetching", false)
        .set("bulkData.recordsAdded", 0)
        .set("bulkData.uploadSuccess", false)
        .set("bulkData.uploadError", action.uploadError)
        .value();

    case CHANGELIST_SUBMIT:
      return immutable(state)
        .set("meta.isFetching", true)
        .set("meta.fileName", action.file.name)
        .set("bulkData", initialState.bulkData)
        .set("applied", initialState.applied)
        .set("changeListData", initialState.changeListData)
        .value();

    case CHANGELIST_SUCCESS:
      return immutable(state)
        .set("meta.isFetching", false)
        .set("changeListData.status", action.data.status)
        .set("changeListData.list", action.data.list)
        .set("changeListData.uploadError", handleUploadErrors(action.data))
        .value();

    case CHANGELIST_FAILURE:
      return immutable(state)
        .set("meta.isFetching", false)
        .set("changeListData.status", undefined)
        .set("changeListData.list", undefined)
        .set("changeListData.uploadError", action.uploadError)
        .value();

    case CHANGELIST_APPLY_SUBMIT:
      return immutable(state)
        .set("meta.isFetching", true)
        .set("bulkData", initialState.bulkData)
        .set("applied", initialState.applied)
        .value();

    case CHANGELIST_APPLY_SUCCESS:
      return immutable(state)
        .set("meta.isFetching", false)
        .set("applied.status", action.data.status)
        .set("applied.results", action.data.results || {})
        .set("applied.uploadError", handleUploadErrors(action.data))
        .value();

    case CHANGELIST_APPLY_FAILURE:
      console.log('CHANGELIST_APPLY_FAILURE', action)
      const newstate = immutable(state)
        .set("meta.isFetching", false)
        .set("applied.status", ((action.data||{}).status) || 'ERROR')
        .set("applied.results", {})
        .set("applied.uploadError", action.uploadError)
        .value();
      return newstate;

    default:
      return state

  }
}

export default fileUpload;
