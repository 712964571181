export const HEADER_RESPONSIVE_MENU = 'HEADER_RESPONSIVE_MENU';

export const toggleResponsiveMenu = () => ({
  type: HEADER_RESPONSIVE_MENU
})

// Reducer

const initialState = {
  isFetching: false,
  isResponsiveMenu: false
};

const devHeader = (state = initialState, action) => {
  switch (action.type) {
    case HEADER_RESPONSIVE_MENU:
      return {
        ...state,
        isResponsiveMenu: !state.isResponsiveMenu 
      }

    default:
      return state

  }
}

export default devHeader;

