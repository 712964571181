import React from 'react';
import { FormGroup, FormControl, FormLabel, Col, Row, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Field } from 'react-final-form';

// wrapper for a react-bootstrap component
// updated for use with react-final-form instead of redux-form
class FieldGroup extends React.Component {
  render() {

    const {
      input, meta: {touched, error, submitError}, label, fieldname,
      as,
      formClass, inputClass, labelClass,
      horizontalForm,
      optionValues,
      ...rest
    } = this.props;

    return (
      <FormGroup as={horizontalForm ? Row : Col} controlId={fieldname} className={formClass || 'Login_form_group'}>
        {horizontalForm ?
              <>
              <Col 
                as={FormLabel} 
                sm={2} 
                className={(touched && (!!error || !!submitError)) ? `${labelClass} has-error` : labelClass || "Login_label"}
              >
                {label}
              </Col>
              <Col sm={10}>
                <OverlayTrigger show={touched && !!error} placement="top" overlay={(props) => (
                  <Tooltip id="overlay-example" {...props}>
                    {[error, submitError].filter(v=>v).join(", ")}
                  </Tooltip>
                )}>
                    <FormControl
                      {...input}
                      as={as}
                      className={inputClass || ((touched && (!!error || !!submitError)) ? "Login_input_error" : "Login_input")}
                      {...rest}
                      ref="target"
                    >{optionValues&&optionValues.props.children}</FormControl>
                </OverlayTrigger>
              </Col>
              </>

          : <div>
              <FormLabel className={labelClass || "Login_label"}>{label}</FormLabel>
              <OverlayTrigger show={touched && (!!error || !!submitError)} placement="top" overlay={(props) => (
                <Tooltip id="overlay-example" {...props}>
                  {[error, submitError].filter(v=>v).join(", ")}
                </Tooltip>
              )}>
                <FormControl
                  {...input}
                  as={as}
                  className={inputClass || ((touched && (!!error || !!submitError)) ? "Login_input_error" : "Login_input")}
                  {...rest}
                  ref="target"
                >{optionValues&&optionValues.props.children}</FormControl>
              </OverlayTrigger>
          </div>
        }
      </FormGroup>
    );
  }
}

// wrapper for a react-final-form Field component
const WrappedField = (props) => {
  return <Field fieldname={props.name} component={FieldGroup} {...props} >
  </Field>
}

export default WrappedField

