import log from 'loglevel';
import { put, select, takeEvery } from 'redux-saga/effects'
import { WATCHLIST_SET_VISIBLEWATCHES, WATCHLIST_APPLY_FILTER }  from './WatchListDuck'
import { H24, H48, INPROGRESS } from 'common/components/TimingSelector/TimingSelectorDuck'
import { COMPANY_SITES, ACTIVE_WINTER_SITES, ACTIVE_NONWINTER_SITES } from "SiteFilter/SiteFilterDuck";
import { matchSearchTerm } from "common/helpers/helpers";

// let only those watches through that have related sites whose name matches the
// value in search box
const filterBySearchTerm = (watchList, searchTerm, sites) => {
  // if search box is empty, all must match
  if (!searchTerm) return true;

  const matches = matchSearchTerm(searchTerm);

  const res = watchList.siteIds
    .map(sid => sites[sid])
    .filter(s => s) // filter out sites that we don't know about
    .filter(s => !s || matches(s.name) || matches(s.zipCode))
  return res.length > 0;
}

// semantics: show only those watchlists whose timeSpanH is less or equal to the
// filter's value
const matchTiming = (filterVal, wlVal) => {
  const now = new Date();
  const then = new Date();

  switch (filterVal) {
    case INPROGRESS:
      // in-progress watchlists have timeSpanH === 0 (when creating the
      // watchlist, this timeSpanH is set to 0 for in-progress)
      return +wlVal === 0;
    case H24: then.setHours(then.getHours() + Number(wlVal)); break;
    case H48: then.setHours(then.getHours() + Number(wlVal)); break;
    default: return true;
  }

  const isWithinTime = (then - now) <= (1+[H24,H48].indexOf(filterVal))*24*3600*1000
  return isWithinTime;
}

const matchWeatherCodes = (wl, filterVals = {}) => {
  if (wl.type !== 'threat') return true;
  if (!wl.weatherCodes) return false;
  if (Object.keys(filterVals).length === 0) return true;

  const matches = wl.weatherCodes.filter(c => filterVals[c]).length;
  return !!matches
}

const matchAdvisoryCodes = (wl, filterVals = []) => {
  if (wl.type !== 'advisory') return true;
  if (!wl.advisories) return false;
  if (filterVals.length === 0) return true;

  const matches = wl.advisories.filter(a => filterVals.includes(a)).length;
  return matches;
}

const filterByFilterParams = (
  wl,
  {
    advTypeVal,
    thrtTypeVal,
    snowSliderVal,
    iceSliderVal,
    rainSliderVal,
    windSliderVal,
    timingVal,
    popSliderVal
  }
) => {
  if (wl.type === "advisory") {
    const advisoriesMatches = matchAdvisoryCodes(wl, advTypeVal);
    return advisoriesMatches;
  } else {
    const snowAccumMatches = snowSliderVal
      ? (snowSliderVal * 10.0) / 100 <= wl.totalSnowIN
      : true;
    const iceAccumMatches = iceSliderVal
      ? (iceSliderVal * 2.0) / 100 <= wl.totalIceaccum
      : true;
    const rainAccumMatches = rainSliderVal
      ? (rainSliderVal * 6.0) / 100 <= wl.totalPrecipIN
      : true;
    const windSpeedMatches = windSliderVal
      ? (windSliderVal * 50.0) / 100 <= wl.windSpeedMPH
      : true;
    const popMatches = popSliderVal ? popSliderVal <= wl.pop : true;
    const timingMatches = matchTiming(timingVal, wl.timeSpanH);
    const weatherMatches = matchWeatherCodes(wl, thrtTypeVal);

    return (
      popMatches &&
      snowAccumMatches &&
      iceAccumMatches &&
      rainAccumMatches &&
      windSpeedMatches &&
      timingMatches &&
      weatherMatches
    );
  }
};

const filterBySiteFilter = (wl, selectedFilter, mySites) => {
  return selectedFilter === COMPANY_SITES ||
    wl.siteIds.find(sid => mySites.siteIds.includes(sid))
}

const getWinterType = (winterFilter) =>
  (winterFilter === ACTIVE_WINTER_SITES && 'winter') ||
  (winterFilter === ACTIVE_NONWINTER_SITES && 'nonwinter') ||
  undefined;

const filterByWinterTypeFilter = (wl, winterFilter) => {
  const winterType = getWinterType(winterFilter); 
  if(winterType) {
    if(wl.winterType) {
      // WinterToggle matches watchlist's winterType:
      return winterType === wl.winterType;  
    } else {
      // Legacy watchlists that don't have winterType, so show only on winter dashboard:
      return winterType === 'winter'; 
    }
  }
  // toggle is undefined, fall through
  return true;
}

// apply the "search box" filter, based on the "watchlist" redux state
export function* filterSearchSaga (action) {
  try {
    const sites = yield select((state) => state.sites);
    const watchlists = yield select((state) => state.watchlists);
    const watchlistfilter = yield select ((state) => state.watchlistfilter);
    const searchbox = yield select ((state) => state.searchbox['watchlist'])
    const siteFilterButton = yield select ((state) => state.togglebutton['sitefilter']);
    const siteFilter = siteFilterButton ? siteFilterButton.label : COMPANY_SITES
    const mySites = yield select ((state) => state.mySites);
    const winterToggle = yield select ((state) => state.togglebutton['winterToggle']);
    const winterFilter = winterToggle ? winterToggle.label : undefined;

    const visibleWatches = Object.values(watchlists)
          .filter(wl => filterBySearchTerm(wl, searchbox && searchbox.value, sites))
          .filter(wl => filterByFilterParams(wl, watchlistfilter))
          .filter(wl => filterBySiteFilter(wl, siteFilter, mySites))
          .filter(wl => filterByWinterTypeFilter(wl, winterFilter))
          .map(wl => wl.id);

    yield put({
      type: WATCHLIST_SET_VISIBLEWATCHES, 
      visibleWatches
    })

  } catch (error) {
    log.error('Watchlist filterSearch saga error', error)
  }
}

// create an array of watchlist items to show in watchlist panel based on:
// 0. forced filter apply (e.g. when initially loading the data)
// 1. watchlist search box change and
// 2. watchlist filter parameters change
export default function* siteFilterSaga () {
  yield takeEvery(WATCHLIST_APPLY_FILTER, filterSearchSaga)
}

