import log from 'loglevel';
import { put, takeEvery, call, select } from 'redux-saga/effects';
import fetchApi from 'utils/fetchApi.js';
import compareDesc from 'date-fns/compare_desc';
import {
  APISTATUS_SUBMIT,
  APISTATUS_SUCCESS,
  APISTATUS_FAILURE
} from './ApiStatusDuck';

function* fetchApiStatus({ companyId }) {
  try {
    const url = '/ApiStatus';
    const authToken = yield select(state => state.login.authToken);
    const parsedJson = yield call(fetchApi, url, { method: 'GET', authToken });

    if (!parsedJson.error) {
      parsedJson.sort((a, b) => compareDesc(a.date, b.date));
      yield put({ type: APISTATUS_SUCCESS, data: parsedJson });
    } else {
      yield put({
        type: APISTATUS_FAILURE,
        message: parsedJson.error.text._error
          ? parsedJson.error.text._error
          : parsedJson.error.text
      });
    }
  } catch (error) {
    log.error('fetchApiStatusSaga', error);
    yield put({
      type: APISTATUS_FAILURE,
      message: 'Network error.'
    })
  }
}

export default function* apiStatusSaga() {
  yield takeEvery(APISTATUS_SUBMIT, fetchApiStatus);
}
