import './common/helpers/polyfills.js'
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux'

import './index.css';
import Site from './Site';
import configureStore from './createstore/createstore'

import log from 'loglevel'
import logServer from 'utils/loglevel-serverSend';

// serviceworker has to wait until production build is behind https
//import registerServiceWorker from './registerServiceWorker';

log.setDefaultLevel('warn')
if (process.env.NODE_ENV === 'production')
  log.setLevel('warn')
else 
  log.setLevel('trace')

if(process.env.REACT_APP_SERVERSEND === 'true'){
  const port = process.env.REACT_APP_SERVERSEND_PORT ? `:${process.env.REACT_APP_SERVERSEND_PORT}` : '' ;
  const serverUrl = `${window.location.protocol}//${window.location.hostname}${port}/wwa/log`;
  console.log('Sending logs to', serverUrl);
  logServer(log, {
    url: serverUrl, 
    callOriginal: true, 
    prefix: function(logSev,message) {
              return '[' + new Date().toISOString() + '] ' + logSev + ': ' + message + '\n'   
            }
  })
}

const store = configureStore();

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  /* <React.StrictMode> */
  <Provider store={store}>  
    <Site />
  </Provider>
  /* </React.StrictMode> */
);

//registerServiceWorker();
