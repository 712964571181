// This file implements the "ducks" pattern, i.e. combines action constants,
// action creators and reducers in one file instead of having multiple files per
// function. As long as there are not too many values, this makes it nice and
// compact.

// Action constants

export const WATCHLIST_TOGGLE = 'WATCHLIST_TOGGLE';
export const WATCHLIST_TOGGLE_DETAILS = 'WATCHLIST_TOGGLE_DETAILS';
export const WATCHLIST_SET_VISIBLEWATCHES = 'WATCHLIST_SET_VISIBLEWATCHES ';
export const WATCHLIST_APPLY_FILTER = 'WATCHLIST_APPLY_FILTER';
export const WATCHLIST_RESET = 'WATCHLIST_RESET';
const WATCHLIST_SET_HEADER_BOUNDS = 'WATCHLIST_SET_HEADER_BOUNDS';

// Action creators

export const resetWatchlist = () => ({
  type: WATCHLIST_RESET
});

export const toggleFilterView = () => ({
  type: WATCHLIST_TOGGLE
})

export const toggleDetailsView = (id) => ({
  type: WATCHLIST_TOGGLE_DETAILS,
  id
})

export const setVisibleWatches = (visibleWatches) => ({
  type: WATCHLIST_SET_VISIBLEWATCHES,
  visibleWatches
})

export const applyFilters = () => ({
  type: WATCHLIST_APPLY_FILTER
})

export const setBounds = bounds => ({
  type: WATCHLIST_SET_HEADER_BOUNDS,
  bounds
});


// Reducer

const initialState = {
  isFilterViewOpen: false,
  isDetailsViewOpen: false,
  watchId: undefined,
  visibleWatches: [],
  bounds: {}
};

const watchListReducer = (state = initialState, action) => {
  switch (action.type) {
    case WATCHLIST_RESET:
      return initialState;

    case WATCHLIST_TOGGLE:
      return {
        ...state,
        isFilterViewOpen: !state.isFilterViewOpen
      }
    case WATCHLIST_TOGGLE_DETAILS:
      return {
        ...state,
        isDetailsViewOpen: !state.isDetailsViewOpen,
        watchId: action.id
      }
    case WATCHLIST_SET_VISIBLEWATCHES:
      return {
        ...state,
        visibleWatches: action.visibleWatches
      }

    case WATCHLIST_SET_HEADER_BOUNDS:
      return {
        ...state,
        bounds: action.bounds
      }

    default:
      return state

  }
}

export default watchListReducer;

