import log from "loglevel";
import { LOGOUT_SUBMIT, LOGIN_SUCCESS } from "Login/LoginDuck";
import {
  call,
  take,
  takeEvery,
  put,
  select,
  spawn,
  fork,
  cancel,
  race
} from "redux-saga/effects";
import { ROLE_SUPER_ADMIN } from "common/helpers/roles";
import {
  LOG_SET_CONNECTIONSTATUS,
  LOGVIEWER_RECONNECT,
  LOG_GET_BATCH,
  LOG_REQUEST_ROOMSLIST,
  LOGVIEWER_ERROR
} from "Admin/LogViewer/LogViewerDuck";
import {
  createSocketConnection,
  createSocketChannel
} from "common/helpers/socketevents";

const listenReconnectSaga = function*(socket) {
  while (true) {
    const connectionStatus = yield call(
      () =>
        new Promise(resolve => {
          socket.on("reconnect", () => resolve("reconnected"));
          socket.on("reconnect_attempt", () => resolve("reconnecting"));
          socket.on("connect", () => resolve("connected"));
        })
    );

    //log.debug('Setting connectionStatus to', connectionStatus);
    yield put({
      type: LOG_SET_CONNECTIONSTATUS,
      url: socket.url,
      connectionStatus
    });

    //log.debug('reconnectlistener', connectionStatus);
  }
};

const hostname = window && window.location && window.location.hostname;
const domain = "fmsweather.com";
let socketServers = [];
if (hostname === domain) {
  socketServers.push(`https://${domain}`);
  socketServers.push(`https://api.${domain}`);
} else if (hostname === `dev.${domain}`) {
  //socketServers.push(`https://dev.${domain}`);
  socketServers.push(`https://devapi.${domain}`);
} else if (hostname === `staging.${domain}`) {
  socketServers.push(`https://staging.${domain}`);
  socketServers.push(`https://stagingapi.${domain}`);
} else if (hostname === `demo.${domain}`) {
  socketServers.push(`https://demo.${domain}`);
  socketServers.push(`https://demoapi.${domain}`);
} else {
  // must contact backend directly because CRA doesn't proxy "ws://" URLs
  //socketServers.push('localhost:3001');
  socketServers.push("http://localhost:3030");
}

const listenDisconnectedSaga = function*(socket) {
  while (true) {
    const connectionStatus = yield call(
      () =>
        new Promise(resolve => {
          socket.on("disconnect", () => resolve("disconnected"));
        })
    );
    yield put({
      type: LOG_SET_CONNECTIONSTATUS,
      url: socket.url,
      connectionStatus
    });
    //log.debug('disconnectlistener', connectionStatus);
  }
};

const listenErrorSaga = function*(socket) {
  while (true) {
    const error = yield call(
      () =>
        new Promise(resolve => {
          socket.on("error", error => resolve(error));
        })
    );
    yield put({ type: LOGVIEWER_ERROR, error });
    log.debug("errorListener", error);
  }
};

function requestRoomsSaga(socket, action) {
  socket.emit("list_rooms", action.roomId);
}

function requestBatchSaga(socket, { roomId, startLineNo, endLineNo }) {
  socket.emit("getbatch", { roomId, startLineNo, endLineNo });
}

function* createLogViewerSocket(url) {
  try {
    const login = yield select(state => state.login);
    if (login.roles.includes(ROLE_SUPER_ADMIN)) {
      //log.debug('Logged in as super admin, creating socket.io');

      const socket = yield call(createSocketConnection, url);
      try {
        const socketDataChannel = yield call(
          createSocketChannel,
          socket,
          login.authToken
        );
        yield put({
          type: LOG_SET_CONNECTIONSTATUS,
          url: socket.url,
          connectionStatus: "reconnecting"
        });
        //yield fork(listenConnectSaga, socket, login.authToken);
        yield fork(listenReconnectSaga, socket);
        yield fork(listenDisconnectedSaga, socket);
        yield fork(listenErrorSaga, socket);
        yield takeEvery(LOG_GET_BATCH, requestBatchSaga, socket);
        yield takeEvery(LOG_REQUEST_ROOMSLIST, requestRoomsSaga, socket);

        while (true) {
          const payload = yield take(socketDataChannel);
          yield put(payload);
        }
      } finally {
        log.debug("Disconnecting socket");
        socket.disconnect();
      }
    }
  } catch (error) {
    log.error("Error in createLogViewerSocket:", error);
  }
}

// Cancel polling if user logs out
export default function* socketWatch() {
  while (true) {
    // wait until Login action arrives or user forces reconnect
    yield race([take(LOGIN_SUCCESS), take(LOGVIEWER_RECONNECT)]);

    const logviewertasks = [];
    for (let url of socketServers) {
      logviewertasks.push(yield spawn(createLogViewerSocket, url));
    }

    // wait until Logout action arrives and cancel tasks
    yield take(LOGOUT_SUBMIT);
    log.debug("canceling logviewertasks");
    for (let task of logviewertasks) yield cancel(task);
  }
}
