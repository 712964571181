import immutable from "object-path-immutable";
import parse from "date-fns/parse";
import compareDesc from "date-fns/compare_desc";
export const FREEZE_FETCH_SUBMIT = "FREEZE_FETCH_SUBMIT";
export const FREEZE_FETCH_SUCCESS = "FREEZE_FETCH_SUCCESS";
export const FREEZE_FETCH_FAILURE = "FREEZE_FETCH_FAILURE";
export const FREEZE_SET_SUBMIT = "FREEZE_SET_SUBMIT";
export const FREEZE_SET_SUCCESS = "FREEZE_SET_SUCCESS";
export const FREEZE_SET_FAILURE = "FREEZE_SET_FAILURE";
export const FREEZE_CANCEL_SUBMIT = "FREEZE_CANCEL_SUBMIT";
export const FREEZE_CANCEL_SUCCESS = "FREEZE_CANCEL_SUCCESS";
export const FREEZE_CANCEL_FAILURE = "FREEZE_CANCEL_FAILURE";
export const FREEZE_RESET = "FREEZE_RESET";
export const FREEZE_START_WATCH = "FREEZE_START_WATCH";
export const FREEZE_STOP_WATCH = "FREEZE_STOP_WATCH";

export const resetMeta = () => ({
  type: FREEZE_RESET
});

export const fetchFreezeLogs = () => ({
  type: FREEZE_FETCH_SUBMIT
});

export const cancelFreeze = () => ({
  type: FREEZE_CANCEL_SUBMIT
});

export const upsertFreezeLog = freezeAt => ({
  type: FREEZE_SET_SUBMIT,
  freezeAt
});

export const startWatch = () => ({
  type: FREEZE_START_WATCH
});

export const stopWatch = () => ({
  type: FREEZE_STOP_WATCH
});

const initialState = {
  data: [],
  meta: {
    isFetching: false,
    dataFetched: false,
    apiError: undefined,
    apiErrorOnSetting: undefined
  }
};

// sorts freeze logs according to their priority:
// ACTIVE status always comes first, then
// freezeAt date from more recent to older
const compareLog = (a,b) => (
  a.status === 'ACTIVE' ? -1 : compareDesc(a.freezeAt, b.freezeAt)
);

const freezeScheduler = (state = initialState, action) => {
  switch (action.type) {
    case FREEZE_FETCH_SUBMIT:
      return immutable(state)
        .set("meta.isFetching", true)
        .set("meta.apiError", undefined)
        .value();

    case FREEZE_FETCH_SUCCESS:
      return immutable(state)
        .set("meta.isFetching", false)
        .set("meta.dataFetched", true)
        .set("meta.apiError", undefined)
        .set(
          "data",
          action.data.log
            .map(fl => immutable.set(fl, "freezeAt", parse(fl.freezeAt)))
            .sort(compareLog)
        )
        .value();

    case FREEZE_FETCH_FAILURE:
      return immutable(state)
        .set("meta.isFetching", false)
        .set("meta.apiError", action.apiError)
        .value();

    case FREEZE_SET_SUBMIT:
      return immutable(state)
        .set("meta.isFetching", true)
        .set("meta.apiErrorOnSetting", undefined)
        .set("meta.setWasSuccessful", undefined)
        .value();

    case FREEZE_SET_SUCCESS:
      return immutable(state)
        .set("meta.isFetching", false)
        .set("meta.setWasSuccessful", true)
        .value();

    case FREEZE_SET_FAILURE:
      return immutable(state)
        .set("meta.isFetching", false)
        .set("meta.apiErrorOnSetting", action.apiErrorOnSetting)
        .set("meta.setWasSuccessful", false)
        .value();

    case FREEZE_CANCEL_SUBMIT:
      return immutable(state)
        .set("meta.isFetching", true)
        .set("meta.apiErrorOnSetting", undefined)
        .set("meta.setWasSuccessful", undefined)
        .value();

    case FREEZE_CANCEL_SUCCESS:
      return immutable(state)
        .set("meta.isFetching", false)
        .value();

    case FREEZE_CANCEL_FAILURE:
      return immutable(state)
        .set("meta.isFetching", false)
        .set("meta.apiErrorOnSetting", action.apiErrorOnSetting)
        .value();

    case FREEZE_RESET:
      return immutable(state)
        .set("meta.isFetching", false)
        .set("meta.apiError", undefined)
        .set("meta.apiErrorOnSetting", undefined)
        .set("meta.setWasSuccessful", undefined)
        .value();

    default:
      return state;
  }
};

export default freezeScheduler;
