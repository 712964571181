import React from "react";
import PropTypes from "prop-types";
import memoizeOne from 'memoize-one';
import isDeepEqual from 'lodash/isEqual';
import paginationStart from "images/dashboard/pagination-start.svg";
import paginationPrevious from "images/dashboard/pagination-previous.svg";
import paginationNext from "images/dashboard/pagination-next.svg";
import paginationLast from "images/dashboard/pagination-last.svg";

import "../css/Paging.css";

// Higher Order Component to wrap paging controls. The wrapped component will receive only the
// current page's worth of the original data
// The name of the 'data' prop is configurable through the 'dataName' prop:
// define outside of render(): const PagedSitesTable = withPaging(SitesTable)
// in render():  <PagedSitesTable data={props.sites} dataName={'sites'} {...props} />

// props:
// data : an array of things that should be paged
// pageSize : the number of items to show per page (optional)
// dataName ; what the 'things' are known to by the wrapped component, this will become the paged slice of
//    the original input 'data' array and passed down as prop under the name given by 'dataName'

const defaultPageSize = 10;

const flattenData = memoizeOne(datas =>{
  return datas.reduce((r, d) => {
    return r.concat(d);
  });
})

const computeBookmarks = ({datas, flattenedData, pageSize}) => {
  let pageBookmarks = [];
  let datasIndex = [];
  let i = 0;
  for (let f of flattenedData) {
    for (let d of datas) {
      if (d.length && f.id === d[0].id) {
        pageBookmarks.push(Math.floor(i / pageSize) + 1); // the page where current list starts
        datasIndex.push(i); // start index in "data" of current list
      }
    }
    i++;
  }
  while (pageBookmarks.length < datas.length) {
    pageBookmarks.push(pageBookmarks[pageBookmarks.length - 1]);
    datasIndex.push(datasIndex[datasIndex.length - 1]);
  }
  return { pageBookmarks, datasIndex };
};

const withPaging = WrappedComponent => {
  class WithPaging extends React.Component {
    static propTypes = {
      pageSize: PropTypes.number,
      datas: PropTypes.arrayOf(PropTypes.array).isRequired,
      dataName: PropTypes.string.isRequired
    };

    constructor(props) {
      super(props);
      const pageSize = props.pageSize || defaultPageSize;

      // flatten
      const data = props.data || flattenData(props.datas);

      this.state = {
        page: 1,
        pageSize,
        rawData: data,
        lastPage: Math.ceil(data.length / pageSize),
        pagedData: data.slice(0, pageSize)
      };
    }

    /**
     * If data or current page or page size changes, return new paged data,
     * otherwise return memoized data.
    */
     calculatePagedData = memoizeOne(({page, pageSize, datas}) => {
      const data = datas ? flattenData(datas) : [];

      if (page >= 1) {
        const newData = data.slice(
          (page - 1) * pageSize,
          (page - 1) * pageSize + pageSize
        );
        return {
          pagedData: newData,
          bookmarks: computeBookmarks({datas, flattenedData: data}),
        };
      } else {
        return {
          pagedData: [],
          bookmarks: {}
        }
      }
    }, isDeepEqual);

    /**
     * When raw data changes, recompute
     * - (current) page
     * - lastPage
     * - pagedData
     * - bookmarks
    */
     updateData = memoizeOne(({newPage, datas, newPageSize}) => {
      const pageSize = newPageSize || defaultPageSize;
      const data = datas ? flattenData(datas) : [];

      const lastPage = data?.length ? Math.ceil(data.length / pageSize) : 1;

      if (newPage >= 1) {
        return {
          page: Math.min(newPage, lastPage),
          lastPage,
          pageSize
        };
      }
    }, isDeepEqual);

    gotoPage = newPage => 
      this.setState(
        this.updateData({
          newPage: +newPage,
          datas: this.props.datas,
          newPageSize: +this.state.pageSize
        })
      )

    handlePageChange = newPage => {
      this.setState(
        this.updateData({
          newPage: +newPage,
          datas: this.props.datas,
          newPageSize: +this.state.pageSize
        })
      )
    }

    onPageSizeChange = v => 
      this.setState(
        this.updateData({
          newPage: +this.state.page,
          datas: this.props.datas,
          newPageSize: +v
        })
      )

    render() {
      const { datas, dataName, showTopPagingControls, ...passthroughProps } = this.props;
      const { pagedData, bookmarks } = this.calculatePagedData({
        page: this.state.page,
        pageSize: this.state.pageSize,
        datas: this.props.datas,
      });
      const datasSizes = [];
      for (let d of datas) datasSizes.push(d.length);
      const dynProp = {
        [dataName]: pagedData,
        bookmarks: bookmarks,
        datasSizes,
        gotoPage: this.gotoPage,
        currentPage: this.state.page
      };
      return (
        <div>
          { !!showTopPagingControls &&
            <Paging
              currentPage={this.state.page}
              lastPage={this.state.lastPage}
              onPageChange={this.handlePageChange}
              pageSize={this.state.pageSize}
              onPageSizeChange={this.onPageSizeChange}
            />
          }
          <WrappedComponent {...passthroughProps} {...dynProp} />
          <Paging
            currentPage={this.state.page}
            lastPage={this.state.lastPage}
            onPageChange={this.handlePageChange}
            pageSize={this.state.pageSize}
            onPageSizeChange={this.onPageSizeChange}
          />
        </div>
      );
    }
  }

  WithPaging.displayName = `WithPaging(${getDisplayName(WrappedComponent)})`;
  return WithPaging;
};

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || "Component";
}

const PagingControl = ({ onPageChange, label, children, disabled }) => (
  <div
    className={"PagingControl_PagingControl" + (disabled ? "_Disabled" : "")}
    onClick={onPageChange}
  >
    {" "}
    {children}
  </div>
);

const createPagingSizeOptions = (values, cutoff) =>
  values.map((v, i, arr) => (
    <option key={v} name="{v}" value={v} disabled={i > 0 && arr[i-1] >= cutoff}>
      {v}
    </option>
  ));

const PagingSizeControl = ({ onChange, cutoff, value }) => (
  <select className="wwa__select" onChange={onChange} value={value}>
    {createPagingSizeOptions([10, 25, 50, 100, 250], cutoff)}
  </select>
);

class PageInput extends React.Component {
  state = {
    value: this.props.initialValue || 0
  };

  handleChange = e => this.setState({ value: e.target.value });

  handleKeyDown = e =>
    e.keyCode === 13 && this.props.onChange(this.state.value);

  render() {
    return (
      <input
        type="number"
        placeholder="input number and Enter"
        value={this.state.value}
        onChange={this.handleChange}
        onKeyDown={this.handleKeyDown}
      />
    );
  }
}

// paging controls, page indexes are 1-based
// clicking on "Showing ..." opens page edit input field
export class Paging extends React.Component {
  state = {
    isEditPage: false
  };

  handlePageChange = v => {
    this.setState({ isEditPage: false });
    this.props.onPageChange(
      Math.max(1, Math.min(this.props.lastPage, Number(v)))
    );
  };

  handlePageSizeChange = e => this.props.onPageSizeChange(e.target.value);

  render() {
    const { currentPage, lastPage, pageSize } = this.props;
    return (
      <div className="PagingControl_Paging">
        <PagingControl
          onPageChange={() => this.handlePageChange(1)}
          icon={"First"}
          disabled={currentPage === 1}
        >
          <img src={paginationStart} alt="first page" />
        </PagingControl>

        <PagingControl
          onPageChange={() => this.handlePageChange(Number(currentPage) - 1)}
          label={"Prev"}
          disabled={currentPage === 1}
        >
          <img src={paginationPrevious} alt="previous page" />
        </PagingControl>

        <div
          className="PagingControl__PageNumbers Paging_Medium "
          onClick={() => this.setState({ isEditPage: true })}
        >
          Showing{" "}
          {this.state.isEditPage ? (
            <PageInput
              initialValue={currentPage}
              onChange={this.handlePageChange}
            />
          ) : (
            currentPage
          )}{" "}
          of {lastPage}
        </div>

        <PagingControl
          onPageChange={() => this.handlePageChange(Number(currentPage) + 1)}
          label={"Next"}
          disabled={currentPage === lastPage}
        >
          <img src={paginationNext} alt="next page" />
        </PagingControl>

        <PagingControl
          onPageChange={() => this.handlePageChange(lastPage)}
          label={"Last"}
          disabled={currentPage === lastPage}
        >
          <img src={paginationLast} alt="last page" />
        </PagingControl>

        <div className="Paging_Medium ">
          <PagingSizeControl
            onChange={this.handlePageSizeChange}
            cutoff={lastPage * pageSize}
            value={pageSize}
          />
        </div>
      </div>
    );
  }
}

export default withPaging;
