import {all, put, take, race, call} from 'redux-saga/effects';
import {FORM_SUBMIT} from './common';

export default () => function* formSubmitSaga () {
  while (true) { // eslint-disable-line no-constant-condition
    const result = yield take(FORM_SUBMIT);
    const {
      meta: {
        successActionType,
        failureActionType,
        resolve,
        // reject
      },
      payload
    } = result;

    let response;

    if (all) {
      response = yield all([
        race({
          success: take(successActionType),
          failure: take(failureActionType)
        }),
        put(payload),
      ]);
    } else {
      response = yield [
        race({
          success: take(successActionType),
          failure: take(failureActionType)
        }),
        put(payload),
      ];
    }
    const [{success, failure}] = response;

    if (success) {
      yield call(resolve, success.payload);
    } else {
      // rejecting causes an uncaught promise error in console:
      // yield call(reject, failure.payload);
      yield call(resolve, failure.payload);
    }
  }
};
