import { connect } from "react-redux";
import CustomReportForm from "./CustomReportForm";
import { saveCustomReportFilterValues } from "./ReportSettingsDuck";
import { createSelector } from 'reselect';

const EMPTY_INITIAL_VALUES = {
  totalSnowIN: 0.0,
  totalIceaccum: 0.0,
  threatWeatherPrimaryCodes: {},
  maxPop: 20,
  name: "",
  id: undefined,
  iceSnowPredicate: "and"
};

const selectReportSettings = state => state.reportSettings;

const getCustomReportSettings = createSelector(
  selectReportSettings,
  reportSettings => {
    const customReport =
      reportSettings.customReports && reportSettings.customReports.length
        ? reportSettings.customReports[0]
        : {};
    const {name, id, values} = customReport;
    let initialValues;
    if(!id) {
      initialValues = { ...EMPTY_INITIAL_VALUES }
    } else {
      initialValues = {
        name, 
        id, 
        totalSnowIN: values.totalSnowIN || 0.0,
        totalIceaccum: values.totalIceaccum || 0.0,
        maxPop: values.maxPop || 20,
        threatWeatherPrimaryCodes: values.threatWeatherPrimaryCodes || {},
        iceSnowPredicate: values.iceSnowPredicate || 'and'
      }
    }
    return initialValues;
  }
);

const mapStateToProps = (state, ownProps) => ({
  thrtTypeVal:
    state.threattypeselector["customReportThreatTypes"] &&
  state.threattypeselector["customReportThreatTypes"].threatTypes,
  customReportInitialValues: getCustomReportSettings(state),
  emptyInitialValues: {...EMPTY_INITIAL_VALUES},
  isFetching: state.reportSettings.isFetching,
  apiError: state.reportSettings.apiError,
  isSavedCustomReportsSuccess: state.reportSettings.customReportSaveSuccess,
  companyId: ownProps.companyId,
});

const mapDispatchToProps = dispatch => ({
  saveCustomReportFilterValues: (companyId, values, name, id) => dispatch(saveCustomReportFilterValues(companyId, values, name, id))
});

const CustomReportFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomReportForm);
export default CustomReportFormContainer;
