import React from "react";
import tagDelete from "images/dashboard/tag-delete.svg";
import ResultPool from "common/components/ResultWithDelete/ResultPool";
import { getSiteIcons, getUserIcons } from "common/helpers/helpers";

const BulkSiteUnassignmentResultPool = ({
  sitePersonsToUnassignObjects,
  undoUnassign,
  companySites
}) =>
  sitePersonsToUnassignObjects && (
    <div className="BulkUnassgn__Pool">
      <h4 className="BulkAssgn__Label">Sites and users to be unassigned</h4>
      {Object.keys(sitePersonsToUnassignObjects).map(sid => (
        <div
          key={sid}
          className="BulkUnassgn__Pool"
          style={{ cursor: "pointer" }}
          onClick={() => undoUnassign(sid)}
        >
          <b>
            <h5 className="BulkAssgn__Label">
              {companySites[sid]
                ? `${getSiteIcons(companySites[sid])} ${companySites[sid].name || companySites[sid].city}, ${
                    companySites[sid].state
                  }, ${companySites[sid].zipCode}`
                : "unknown site"}{" "}
              <img
                className="ResultWithDelete__img"
                src={tagDelete}
                alt="Delete"
              />
            </h5>
          </b>

          <ResultPool
            resultList={sitePersonsToUnassignObjects[sid]}
            handleDelete={id => undoUnassign(sid, id)}
            fieldNames={["username", "name", "email"]}
            maxWidth={20}
            iconFunc={getUserIcons}
          />
        </div>
      ))}
    </div>
  );

export default BulkSiteUnassignmentResultPool;
