import React from "react";
import PropTypes from "prop-types";
import {
  Accordion,
  Button,
  Tab,
  Table,
  Tabs,
  Alert,
  Card,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import ImageCrop from "common/components/ImageCrop";
import format from "date-fns/format";
import {
  specialJoin,
  reportTypesByCompanyType,
  friendlyReportType,
  reportTypeToolTip,
} from "common/helpers/helpers";
import ReportSettingsCustomiser, {
  MAX_ENTRIES,
  TMPIDPREF
} from "./ReportSettingsCustomiser";
import HelpModal from "./HelpModal";
import BusySpinner from "common/components/BusySpinner";
import CustomReportForm from "./CustomReportFormContainer";
import ReportsExecute from "Admin/ReportsExecute";
import "./ReportSettings.css";

export const displayFormat = hour =>
  format(new Date(1970, 1, 1, hour, 0, 0), "hA") + " EST";

export const reportSettingsSortFun = (a, b) => a.hour - b.hour;

const MAX_IMAGE_SIZE = 1024 * 1024;

class ReportTypeRow extends React.Component {
  state = {
    isCustomiseMode: false
  };

  // change read/only view of hours to editable view
  handleCustomise = () => {
    this.props.resetReportSettings();
    this.props.toggleCustomise(this.props.reportType);
    this.setState({ isCustomiseMode: true });
  };

  saveReportSettings = reportSettings => {
    this.setState({ isCustomiseMode: false });
    this.props.handleSave(this.props.reportType, reportSettings);
  };

  handleCancel = () => {
    this.setState({ isCustomiseMode: false }, () => {
      this.props.toggleCustomise(this.props.reportType);
      this.props.resetReportSettings();
    });
  };

  render() {
    const {
      companyId,
      reportType,
      reportSettings,
      showButton,
      isFetching
    } = this.props;

    const sortedReportSettings = [...reportSettings];
    sortedReportSettings.sort(reportSettingsSortFun);

    const hours = specialJoin(
      sortedReportSettings.map(rs => displayFormat(rs.hour))
    );

    if (this.state.isCustomiseMode)
      return (
        <tr>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{reportTypeToolTip(reportType)}</Tooltip>}
          >
            <td>
              {friendlyReportType(reportType)}{" "}
              <i className="fa fa-info-circle" aria-hidden="true" />
            </td>
          </OverlayTrigger>
          <ReportSettingsCustomiser
            companyId={companyId}
            reportType={reportType}
            reportSettings={reportSettings}
            onSave={this.saveReportSettings}
            onCancel={this.handleCancel}
          />
        </tr>
      );
    else
      return (
        <tr>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{reportTypeToolTip(reportType)}</Tooltip>}
          >
            <td>
              {friendlyReportType(reportType)}{" "}
              <i className="fa fa-info-circle" aria-hidden="true" />
            </td>
          </OverlayTrigger>

          <td className="ReportSettings__hoursFlex">{hours}</td>
          {showButton && (
            <td>
              <Button onClick={this.handleCustomise} disabled={isFetching} variant="outline-dark">
                {isFetching ? (
                  <span>
                    <BusySpinner inButton={true} />
                    Please wait
                  </span>
                ) : (
                  "Customize"
                )}
              </Button>
            </td>
          )}
        </tr>
      );
  }
}

class ReportSettings extends React.Component {
  state = {
    currentlyCustomising: undefined,
    helpOpen: false,
    openedAccordion: null,
  };

  constructor(props) {
    super(props);
    props.loadReportSettings(props.companyId);
    props.fetchImage(props.companyId);
    props.fetchServerFeatures();
  }

  componentWillUnmount() {
    this.props.resetReportSettings();
  }

  handleHelpOpen = (e) => this.setState({ helpOpen: e.target.id });
  handleHelpClose = () => this.setState({ helpOpen: false });

  toggleCustomise = reportType =>
    this.setState(state => ({
      currentlyCustomising:
        state.currentlyCustomising !== undefined ? undefined : reportType
    }));

  // break out changes to diff/merge of hours to delete, add or update
  handleSave = (reportType, reportSettings) => {
    this.toggleCustomise(reportType);

    const toDel = this.props.companyReportSettings
      .filter(rs => reportType === rs.reportType)
      .filter(rs => !reportSettings.some(rs1 => rs1.id === rs.id));

    const toAdd = reportSettings
      .filter(rs => rs.id.startsWith(TMPIDPREF))
      .map(rs => {
        const { id, ...rest } = rs; // remove temporary ID
        return rest;
      });

    const toUpdate = reportSettings.filter(rs =>
      this.props.companyReportSettings.some(
        rs1 => rs1.id === rs.id && rs1.hour !== rs.hour
      )
    );

    this.props.saveReportSettings(this.props.companyId, toDel, toAdd, toUpdate);
  };

  handlePanelToggled = (e) => {
    const id = e.target?.id;
    if (id === "customReport" && this.state.openedAccordion !== "customReport"){
      this.props.getCustomReportFilterValues(this.props.companyId);
    }
    this.setState(state => ({ openedAccordion: state.openedAccordion === id ? null : id }));
  };

  handleCompanyReportSortColumnChanged = e => {
    const value = e.currentTarget.value;
    this.props.submitCompanyRollupReportSortColumn(this.props.companyId, value);
  }

  render() {
    const {
      companyId,
      companyReportSettings,
      error,
      success,
      isFetching,
      resetReportSettings,
      companyTypes,
      isSetLogoFetching,
      setLogoError,
      companyHasLogo,
      useCompanyLogoFeature,
      companySubscriptionError,
    } = this.props;

    return (
      <div className="ReportSettings__outer">
        <div className="ReportSettings__tab">
          <Tabs defaultActiveKey={1} id="report-timings" >
            <Tab
              eventKey={1}
              title="Report Timings"
              className="ProfileTab__Outer"
              tabClassName="ReportSettings__tabtitle"
            >
              {success && (
                <Alert variant="success">
                  Report Settings saved successfully!
                </Alert>
              )}
              {!!error && <Alert variant="warning">{JSON.stringify(error)}</Alert>}

              <div className="ReportSettings__table">
                <Table hover>
                  <thead>
                    <tr className="WWA__Table--Header">
                      <th className="ReportSettings__table--header">
                        Report type
                      </th>
                      <th className="ReportSettings__table--header" colSpan={2}>
                        Daily report sent at
                      </th>
                    </tr>
                  </thead>

                  <tbody className="ReportSettings__tableBody">
                    { reportTypesByCompanyType(companyTypes).map(rt => (
                            <ReportTypeRow
                              key={rt}
                              companyId={companyId}
                              reportType={rt}
                              reportSettings={
                                companyReportSettings &&
                                companyReportSettings.filter(
                                  rs => rs.reportType === rt && rs.hour !== null
                                )
                              }
                              toggleCustomise={this.toggleCustomise}
                              handleSave={this.handleSave}
                              showButton={
                                this.state.currentlyCustomising === undefined
                              }
                              isFetching={isFetching}
                              resetReportSettings={resetReportSettings}
                            />
                          )
                        )
                    }
                  </tbody>
                </Table>
              </div>

              <div className="ReportSettings__footer">
                You can add a maximum of {MAX_ENTRIES} intervals for each report
                type. <br />
                (W) = Winter Only, (NW) = Non-Winter Only
              </div>
            </Tab>

            <Tab
              eventKey={2}
              title="Note: All report sending times are in EST/EDT"
              disabled
              tabClassName="ReportSettings__tabtitle"
            >
              content
            </Tab>

            {useCompanyLogoFeature && (
              <Tab
                eventKey={3}
                title="Customize Reports"
                tabClassName="ReportSettings__tabtitle"
              >
                <div style={{ height: "2em" }} />

                <Accordion>
                  <Card>
                    <Card.Header>
                      <Card.Title>Private Label Logo</Card.Title>
                      <Accordion.Toggle 
                        as={Button} 
                        variant="link" 
                        eventKey="privateLabel"
                        id="privateLabel"
                        onClick={this.handlePanelToggled}
                      >
                        {this.state.openedAccordion === "privateLabel" ? "Hide" : "Show"}
                      </Accordion.Toggle>
                    </Card.Header>
                    <Card>
                      <Accordion.Collapse eventKey="privateLabel">
                        <Card.Body>
                          <div className="ReportSettings__HelpModal">
                            <div
                              className="ReportSettings__Help"
                              id="privateLabelHelp"
                              onClick={this.handleHelpOpen}
                            >
                              {" "}
                              ?{" "}
                            </div>
                            <HelpModal
                              openState={this.state.helpOpen === "privateLabelHelp"}
                              onClose={this.handleHelpClose}
                              source="customizeReports"
                            />
                          </div>
                          {setLogoError && (
                            <Alert variant="warning">{setLogoError}</Alert>
                          )}
                          {companyHasLogo && (
                            <div className="ReportSettings__ShowLogoCheckbox">
                              {isSetLogoFetching ? (
                                <BusySpinner inButton={true} />
                              ) : null}
                              Current:
                              <span className="ReportSettings__LogoThumbnail">
                                <img
                                  src={this.props.companyLogo}
                                  alt="logo"
                                  style={{
                                    height: "inherit"
                                  }}
                                />
                              </span>
                            </div>
                          )}

                          <ImageCrop
                            companyId={companyId}
                            maxImageSize={MAX_IMAGE_SIZE}
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>

                    <Card>
                      <Card.Header>
                        <Card.Title>Company Event Rollup Report Sort Column</Card.Title>
                        {!!companySubscriptionError && <Alert variant="warning">{companySubscriptionError}</Alert>}
                        <Accordion.Toggle 
                          as={Button} 
                          variant="link" 
                          eventKey="companyReportSortColumn"
                          id="companyReportSortColumn"
                          onClick={this.handlePanelToggled}
                        >
                          {this.state.openedAccordion === "companyReportSortColumn" ? "Hide" : "Show"}
                        </Accordion.Toggle>
                      </Card.Header>
                      <Card>
                        <Accordion.Collapse eventKey="companyReportSortColumn">
                          <Card.Body>
                            <div className="ReportSettings__HelpModal">
                              <div
                                className="ReportSettings__Help"
                                id="companyReportSortColumnHelpOpen"
                                onClick={this.handleHelpOpen}
                              >
                                {" "}
                                ?{" "}
                              </div>
                              <HelpModal
                                openState={this.state.helpOpen === "companyReportSortColumnHelpOpen"}
                                onClose={this.handleHelpClose}
                                source="companyRollupReportSortColumn"
                              />
                            </div>
                            <select
                              id={"id"}
                              onChange={this.handleCompanyReportSortColumnChanged}
                              className='wwa__select'
                              value={this.props.companyRollupReportSortColumn}
                            >
                              <option
                                className='SiteSel__option'
                                key={1}
                                value={"byEvent"}
                              >Event Lead Time
                              </option>
                              <option
                                className='SiteSel__option'
                                key={2}
                                value={"bySiteName"}
                              >Site Name
                              </option>
                              <option
                                className='SiteSel__option'
                                key={3}
                                value={"bySnowfall"}
                              >Snowfall
                              </option>
                            </select>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Card>
                  </Card>

                  <Card>
                    <Card.Header>
                      <Card.Title>Custom Event Rollup (Excel)</Card.Title>
                      <Accordion.Toggle 
                        as={Button} 
                        variant="link" 
                        eventKey="customReport"
                        id="customReport"
                        onClick={this.handlePanelToggled}
                      >
                        {this.state.openedAccordion === "customReport" ? "Hide" : "Show"}
                      </Accordion.Toggle>
                    </Card.Header>
                    <Card>
                      <Accordion.Collapse eventKey="customReport">
                        <Card.Body>
                          {this.state.openedAccordion === "customReport" &&
                            this.props.isFetchedCustomReports && (
                              <CustomReportForm companyId={companyId} />
                            )}
                          {this.state.openedAccordion === "customReport" &&
                            this.props.isFetching && <BusySpinner />}
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Card>
                </Accordion>
              </Tab>
            )}

            <Tab
              eventKey={4}
              title="Execute Reports"
              tabClassName="ReportSettings__tabtitle"
            >
              <ReportsExecute companyId={companyId}/>
            </Tab>
          </Tabs>
        </div>
      </div>
    );
  }
}

ReportSettings.propTypes = {
  companyReportSettings: PropTypes.array
};

export default ReportSettings;
