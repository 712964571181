export const APISTATUS_SUBMIT = 'APISTATUS_SUBMIT';
export const APISTATUS_SUCCESS = 'APISTATUS_SUCCESS';
export const APISTATUS_FAILURE = 'APISTATUS_FAILURE';

export const loadApiStatus = () => ({
  type: APISTATUS_SUBMIT
});

const initialState = {
  data: [],
  latest: undefined,
  meta: { isFetching: false }
};

const apiStatus = (state = initialState, action) => {
  switch (action.type) {
    case APISTATUS_SUBMIT:
      return {
        ...state,
        meta: { ...state.meta, isFetching: true }
      };

    case APISTATUS_SUCCESS:
      return {
        ...state,
        data: action.data,
        latest: action.data.length !== 0 && action.data[0],
        meta: {
          ...state.meta,
          isFetching: false,
          fetchError: undefined,
          isDataLoaded: true
        }
      };

    case APISTATUS_FAILURE:
      return {
        ...state,
        meta: {
          ...state.meta,
          fetchError: action.message,
          isFetching: false
        }
      };

    default:
      return state;
  }
};

export default apiStatus;
