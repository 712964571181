import log from 'loglevel';
import { put, takeEvery, call, select } from 'redux-saga/effects'
import { canonicalPhone } from "common/helpers/helpers";

import {
  COMPANYADD_SUBMIT,
  COMPANYADD_SUCCESS,
  COMPANYADD_FAILURE,
  COMPANYADD_CLOSE
} from './CompanyAddDuck'
import fetchApi from 'utils/fetchApi'

export function* fetchCompanyAddSaga (action) {
  try {
    // redux-form-submit-saga injects 'payload' into action
    const { name, address1, state, city, zipCode, primaryPhone, extension } = action.payload;
    const url = '/Companies';
    const payload = { name, address1, state, city, zipCode,
      primaryPhone: canonicalPhone(primaryPhone),
      extension };
    const authToken = yield select((state) => state.login.authToken);

    // call API
    const parsedJson = yield call(fetchApi, url, { payload, authToken })

    if (!parsedJson.error){
      // ... and update Redux store
      yield put({ type: COMPANYADD_SUCCESS, companyObj: parsedJson, name })
      yield put({ type: COMPANYADD_CLOSE })
      yield put({ type: '@@redux-form/RESET', meta: {'form': 'CompanyAddForm'}  })
    } else {
      yield put({ type: COMPANYADD_FAILURE,
        message: parsedJson.error.text,
        payload: parsedJson.error.text
      })
    }
  } catch (error) {
    log.error('fetchCompanyAddSaga', error)
  }
}


// listen for actions of type COMPANYADD_SUBMIT and use them
export default function* companyaddSaga () {
  yield takeEvery(COMPANYADD_SUBMIT, fetchCompanyAddSaga)
}

