import { PERSONDEL_SUCCESS } from "common/dataRetrieval/PersonDuck";
import { USERUNASSIGN_SUCCESS } from "Admin/SiteUsersManage/UserUnassign/UserUnassignDuck";

export const SITE_PERSONDATA_SUCCESS = "SITE_PERSONDATA_SUCCESS";
export const SITE_PERSONDATA_SUBMIT = "SITE_PERSONDATA_SUBMIT";
export const COMPANY_SITEPERSONS_SUBMIT = "COMPANY_SITEPERSONS_SUBMIT";
export const COMPANY_SITEPERSONS_SUCCESS = "COMPANY_SITEPERSONS_SUCCESS";
export const MY_SITES_SUBMIT = "MY_SITES_SUBMIT";
export const MY_SITES_FAILURE = "MY_SITES_FAILURE";
export const MY_SITES_SUCCESS = "MY_SITES_SUCCESS";

export const loadSitePersons = (siteId, companyId) => ({
  type: SITE_PERSONDATA_SUBMIT,
  id: siteId,
  companyId
});

const initialState = {};

const deletePersonFromSite = (state, personId) => {
  let newState = {};
  Object.keys(state).forEach(
    c => (newState[c] = state[c].filter(p => p.id !== personId))
  );
  return newState;
};

export const sitePersons = (state = initialState, action) => {
  switch (action.type) {
    case SITE_PERSONDATA_SUCCESS:
      return {
        ...state,
        [action.siteId]: action.personArr
      };

    case USERUNASSIGN_SUCCESS: // optimistically delete association while fresh data is loaded from backend
      const oldPersons = state[action.siteId];
      let { [action.siteId]: deleted, ...newState } = state;
      newState[action.siteId] = oldPersons.filter(p => p.id !== action.userId);
      return newState;

    case PERSONDEL_SUCCESS:
      return deletePersonFromSite(state, action.id);

    case COMPANY_SITEPERSONS_SUCCESS:
      return {
        ...state,
        ...action.siteUsers
      };

    default:
      return state;
  }
};

export const mySites = (state = { isFetching: false, siteIds: [] }, action) => {
  switch (action.type) {
    case MY_SITES_SUCCESS:
      return {
        ...state,
        siteIds: action.mySites.map(s => s.id),
        isFetching: false
      };

    case MY_SITES_SUBMIT:
      return {
        ...state,
        isFetching: true
      };

    case MY_SITES_FAILURE:
      return {
        ...state,
        isFetching: false
      };

    default:
      return state;
  }
};
