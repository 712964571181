import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga'
import throttle from 'lodash/throttle'
import rootSaga from './rootSaga';
import rootReducer from './allReducers';
import { loadState, saveState } from './localStorage'

/*
 * Create Redux store out of all reducers that are defined in components
 * and add enhancers like Redux devtools and Saga middleware.
 */

// add Redux dev tools to browser window
const composeEnhancers = (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__([{"maxAge":20}])) || compose;

// create Redux store with dev tools, app reducer and redux-saga
export default function configureStore() {

  const sagaMiddleware = createSagaMiddleware()

  const persistedState = loadState();
  const store = createStore(
    rootReducer,
    persistedState,
    composeEnhancers(
      applyMiddleware(sagaMiddleware)
    )
  );
  
  // once in a while, write "login" slice and other slices of Redux store to localStorage:
  // (don't set it much higher than 1000 because otherwise after logout it will be kept in browser too long)
  store.subscribe( throttle(() => {
      saveState({
        login: store.getState().login,
        notificationlist: store.getState().notificationlist,
        notificationtile: store.getState().notificationtile,
        companySelector: store.getState().companySelector
      })
    }, 1000)
  );

  rootSaga.map(saga => sagaMiddleware.run(saga));
  
  return store
}

