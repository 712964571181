import { THREATFILTER_SHOW_INFO_POPUP } from "common/ducks/OnboardingDuck";
import log from "loglevel";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { loadThreatFilterSaga } from "ThreatFilter/ThreatFilterSagas";

/**
 * The first step of the threat filter onboarding popups:
 * need to load all custom saved threat filters to decide whether to
 * show a popup.
 */
function* handleThreatFilterInfoPopupStep({ step, type, key, ...rest }) {
  try {
    // only interested in step 1 - loading filters
    if (step !== 1) return;

    const localCustomFilters = yield select(state =>
      state.threatfilter.savedFilters.some(f => f.isCustom)
    );

    // short circuit if we know there are custom filters in Redux
    if (localCustomFilters) {
      yield put({ type, key, step: 2, hasCustomFilters: true, ...rest });
      return;
    }

    const customFilters = yield call(loadThreatFilterSaga, {
      personId: rest.userId
    });
    if (customFilters && customFilters.length) {
      yield put({ type, key, step: 2, hasCustomFilters: true, ...rest });
    } else {
      yield put({ type, key, step: 2, hasCustomFilters: false, ...rest });
    }
  } catch (error) {
    log.error("handleThreatFilterInfoPopup error", error);
  }
}

export default function* threatFilterSaga() {
  yield takeEvery(
    THREATFILTER_SHOW_INFO_POPUP,
    handleThreatFilterInfoPopupStep
  );
}
