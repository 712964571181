
const initialState = {

}

const threatDetails = (state = initialState, action) => {
  switch (action.type) {
    case 'DUMMY':
      return {
        ...state
      }

    default: return state
  }
}

export default threatDetails