import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import FilterSlider, { INCHES10, INCHES2, INCHES6, MPH50 } from 'common/components/FilterSlider';
import ThreatTypeSelector from 'common/components/ThreatTypeSelector';
import LocationSelector from 'common/components/LocationSelector';
import './ThreatFilter.css';
import chevronUp from 'images/dashboard/w_sort_collapse.svg';
import SaveThreatFilter from './SaveThreatFilter';

const EventProbabilityElem = () => (
  <div>
    <div className='ThreatFilter_Subhead'>
      Event Probability (in percent)
    </div>

    <FilterSlider sliderId='threatfilter_pop' />
  </div>
)

const SnowAmountElem = () => (
  <div>
    <div className='ThreatFilter_Subhead'>
      Event Snow Amount (in inches)
    </div>

    <FilterSlider sliderId='threatfilter_snow' marks={INCHES10} />
  </div>
)

const IceAmountElem = () => (
  <div>
    <div className='ThreatFilter_Subhead'>
      Event Ice Amount (in inches)
    </div>

    <FilterSlider sliderId='threatfilter_ice' marks={INCHES2} />
  </div>
)

const WindSpeedElem = () => (
  <div>
    <div className='ThreatFilter_Subhead'>
      Event Wind Speed (in MPH)
    </div>

    <FilterSlider sliderId='threatfilter_wind' marks={MPH50} />
  </div>
)

const RainAmountElem = () => (
  <div>
    <div className='ThreatFilter_Subhead'>
      Event Rain Amount (in inches)
    </div>

    <FilterSlider sliderId='threatfilter_rain' marks={INCHES6} />
  </div>
)

const Location = ({checkedRadio, onClick}) => (
  <div>
    <div className='ThreatFilter_Subhead'>
      Location
    </div>
    <LocationSelector groupId={'threatfilter'} />
  </div>
)

class ThreatFilter extends React.Component {

  constructor(props) {
    super(props);
    props.isFresh && props.resetForm();
  }

  handleChangeRadio = (e) => this.props.setLocation(e.target.value)

  handleSaveButton = (e) => this.props.openModal('save')

  handleCancelButton = (e) => this.props.resetForm();

  handleFilterApply = (e) => {
    this.props.setThreatFilter(
      this.props.popSliderVal,
      this.props.snowSliderVal,
      this.props.iceSliderVal,
      this.props.rainSliderVal,
      this.props.windSliderVal,
      this.props.timingVal, 
      this.props.locVal, 
      this.props.typeVal)
  }

  handleSave = (name) => {
    this.props.saveThreatFilter(
      name,
      this.getFilterOptions(),
      this.props.userId)
  }

  getFilterOptions = () => ({
    popSliderVal: this.props.popSliderVal,
    snowSliderVal: this.props.snowSliderVal,
    iceSliderVal: this.props.iceSliderVal,
    rainSliderVal: this.props.rainSliderVal,
    windSliderVal: this.props.windSliderVal,
    timingVal: this.props.timingVal,
    locVal: this.props.locVal,
    typeVal: this.props.typeVal
  })

  render() {

    const { 
      locationFilter,
      toggleFilterView,
      error,
      showModal,
      closeModal,
      winterToggle
    } = this.props;

    return (
      <div className='ThreatFilter'>

        {<SaveThreatFilter
            showModal={!!showModal}
            handleSave={this.handleSave}
            closeModal={closeModal}
            filterOptions={this.getFilterOptions()}
            error={error}
          />
        }

        <div className='ThreatFilter_Top' onClick={toggleFilterView}>
          <div className='ThreatFilter_Head'>
            Apply filters
          </div>
          <div className='ThreatFilter_Back'>
            <img src={chevronUp} alt='Collapse' />
          </div>
        </div>

        <EventProbabilityElem />

        {winterToggle === 'winter' &&
          <>
          <SnowAmountElem />
          <IceAmountElem />
          </>
        }

        {winterToggle === 'nonwinter' &&
          <>
          <WindSpeedElem />
          <RainAmountElem />
          </>
        }

        <Location onClick={this.handleChangeRadio} checkedRadio={locationFilter}/>

        <div className='ThreatFilter_VFill' />
        <ThreatTypeSelector cbGroupId='threatfilter' 
          weatherType={winterToggle}
        />

        <div className='ThreatFilter_Buttons'>
        <Button bsPrefix='ThreatFilter_Button ThreatFilter_Button_Apply'
                onClick={this.handleFilterApply}>
          Apply
        </Button>
        <Button bsPrefix='btn btn-default ThreatFilter_Button ThreatFilter_Button_Save'
                onClick={this.handleSaveButton}>
          Save as a preset
        </Button>
        <Button bsPrefix='ThreatFilter_Button ThreatFilter_Button_Cancel'
          onClick={this.handleCancelButton}>
          Reset
        </Button>
        </div>

        <div className='ThreatFilter_VFill' />

      </div>
    )
  }
}

ThreatFilter.propTypes = {
  toggleFilterView: PropTypes.func.isRequired,
  setLocation: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  setThreatFilter: PropTypes.func.isRequired,
  locationFilter: PropTypes.string,
  timingVal: PropTypes.string,
  snowSliderVal: PropTypes.number,
  iceSliderVal: PropTypes.number,
  popSliderVal: PropTypes.number,
  locVal: PropTypes.object,

}

export default ThreatFilter;
