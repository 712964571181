// This file implements the "ducks" pattern, i.e. combines action constants,
// action creators and reducers in one file instead of having multiple files per
// function. As long as there are not too many values, this makes it nice and
// compact.


// Action constants

export const FORGOTPASS_SUBMIT = 'FORGOTPASS_SUBMIT';
export const FORGOTPASS_SUCCESS = 'FORGOTPASS_SUCCESS';
export const FORGOTPASS_FAILURE = 'FORGOTPASS_FAILURE';
export const CLEAR_FORGOTPASS_DATA = 'CLEAR_FORGOTPASS_DATA'


// Action creators

export const resetForm = () => {
  return { type: CLEAR_FORGOTPASS_DATA }
}

// redux-form calls this function with its 'values' object, whose keys
// correspond to form fields
export const forgotPassword = ({email}) => {
  return {
    type: FORGOTPASS_SUBMIT,
    email
  }
}


// Reducer

const initialState = {
  isFetching: false,
  isReset: false,
  error: ''
};

const forgotPassReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_FORGOTPASS_DATA:
      return initialState;
    case FORGOTPASS_SUBMIT:
      return {
        ...state,
        isFetching: true,
        isReset: false,
        error: ''
      }
    case FORGOTPASS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isReset: true
      }
    case FORGOTPASS_FAILURE:
      return {
        ...state,
        isFetching: false,
        isReset: false,
        error: action.message
      }
    default:
      return state

  }
}

export default forgotPassReducer;
