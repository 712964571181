import React from "react";
import memoizeOne from 'memoize-one';
import { Alert, Button } from "react-bootstrap";
import BusySpinner from "common/components/BusySpinner";
import SiteSelector from "common/components/SiteSelector";
import MultiSearchBoxSelector from "common/components/MultiSearchBoxSelector/MultiSearchBoxSelector";
import BulkSiteUnassignmentResultPool from "./BulkSiteUnassignmentResultPool";

/*
 * Drill down first by Site then by User to select assignments to unassign.
 */
class BulkSiteUnassignByUserTab extends React.Component {
  constructor(props) {
    super(props);
    const selectedUser = this.findUserById(props.preselectedUser) || {}
    this.state = {
      userSearchboxVal: selectedUser && selectedUser.email 
        ? selectedUser.email 
        : "",
      selectedUser
    };
  }

  // find user/3rdPartyUser by ID
  findUserById = id =>
    this.props.allAssignedPersonsDS.reduce(
      (acc, ds) => (acc.id ? acc : ds.data.find(d => d.id === id)),
      {}
    );

  handleSelectUser = id => {
    this.setState(state => ({ selectedUser: this.findUserById(id) }));
  };

  renderPersonSelector = iconFunc => (
    <React.Fragment>
      <h4 className="BulkAssgn__Label">Select Persons</h4>
      <MultiSearchBoxSelector
        dataSources={this.props.allAssignedPersonsDS}
        fields={["username", "email"]}
        minSearchLen={0}
        multipleSelect={false}
        closeOnSelect={false}
        handleSelect={this.handleSelectUser}
        onChange={v => this.setState({ userSearchboxVal: v })}
        value={this.state.userSearchboxVal || ""}
        iconFunc={iconFunc}
      />
    </React.Fragment>
  );

  mapBy = (ar, field) => {
    const m = {};
    ar.forEach(o => { m[o[field]] = o });
    return m;
  }

  sitesForUser = memoizeOne(userId => {
    const apas = this.props.assignedPersonsAndSites[this.state.selectedUser.id];
    // map of siteId to site
    const res = apas ? this.mapBy(apas, 'id') : {};
    return res;
  });

  renderSiteSelector = userId => (
    <React.Fragment key={userId}>
      <h4 className="BulkAssgn__Label">Select Sites</h4>
      <SiteSelector
        companyId={this.props.companyId}
        selectSiteFunc={() => this.sitesForUser(this.state.selectedUser.id)}
        showMySitesToggle={false}
        handleSelect={siteId =>
          this.props.storeSitePersonToUnassign(userId, siteId)
        }
        handleMultiSelect={siteIds => 
          this.props.storeMultipleSitePersonToUnassign(userId, siteIds)
        }
      />
    </React.Fragment>
  );

  render() {
    const {
      iconFunc,
      sitePersonsToUnassignObjects,

      personsDS,
      companySites,
      unassignError,
      isFetching,
      handleSubmitUnassign,
      handleCancel,
      undoUnassign
    } = this.props;

    return (
      <div className="BulkAssgn__grid">

        <div className="BulkAssgn__SelectSites">
          {personsDS &&
            !!personsDS[0].data.length &&
            this.renderPersonSelector(iconFunc)}

          {this.state.selectedUser.id &&
            this.renderSiteSelector(this.state.selectedUser.id)}
        </div>
        <div className="BulkAssgn__CentralBar" />

        <BulkSiteUnassignmentResultPool
          sitePersonsToUnassignObjects={sitePersonsToUnassignObjects}
          undoUnassign={undoUnassign}
          companySites={companySites}
        />

        <div className="BulkAssgn__Buttons">
          {!!unassignError && <Alert variant="danger">{unassignError}</Alert>}
          <Button className="BulkAssgn__CancelButton" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleSubmitUnassign}
            disabled={
              isFetching ||
              !sitePersonsToUnassignObjects ||
              !Object.keys(sitePersonsToUnassignObjects).length
            }
          >
            {isFetching ? (
              <span>
                <BusySpinner inButton={true} />
                Unassigning Users from Sites
              </span>
            ) : (
              "Unassign Users from Sites"
            )}
          </Button>
        </div>
      </div>
    );
  }
}

export default BulkSiteUnassignByUserTab;
