
// Action constants

export const VERIFYPERSON_SUBMIT = 'VERIFYPERSON_SUBMIT';
export const VERIFYPERSON_SUCCESS = 'VERIFYPERSON_SUCCESS';
export const VERIFYPERSON_FAILURE = 'VERIFYPERSON_FAILURE';

// Reducer

const initialState = {
  isFetching: false,
  isVerified: false,
  message: ''
};

const verifyPersonReducer = (state = initialState, action) => {
  switch (action.type) {

    case VERIFYPERSON_SUBMIT:
      return {
        ...state,
        isFetching: true,
        isVerified: false,
        message: ''
      }
    case VERIFYPERSON_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isVerified: true,
        email : action.email,
        token : action.token
      }
    case VERIFYPERSON_FAILURE:
      return {
        ...state,
        isFetching: false,
        isVerified: false,
        message: action.message
      }
    default:
      return state

  }
}

export default verifyPersonReducer;
