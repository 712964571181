import React from 'react';
import log from 'loglevel';
import BusySpinner from 'common/components/BusySpinner';

const defaultStyle = {fontSize:'32px', marginTop:'20px', marginBottom:'20px', color: '#4758BE',
  display:'flex',justifyContent:'center'}

const AsyncLoadingSpinner = (props) => { 
  if (props.error) 
    log.warn('AsyncLoadingSpinner received error', JSON.stringify(props.error));

  return (
    <BusySpinner style={defaultStyle}/>
  );
}

export default AsyncLoadingSpinner
