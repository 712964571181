import log from "loglevel";
import {
  spawn,
  cancel,
  race,
  take,
  put,
  takeEvery,
  call,
  select
} from "redux-saga/effects";
import fetchApi from "utils/fetchApi";
import {
  FREEZE_FETCH_SUBMIT,
  FREEZE_FETCH_SUCCESS,
  FREEZE_FETCH_FAILURE,
  FREEZE_SET_SUBMIT,
  FREEZE_SET_SUCCESS,
  FREEZE_SET_FAILURE,
  FREEZE_CANCEL_SUBMIT,
  FREEZE_CANCEL_SUCCESS,
  FREEZE_CANCEL_FAILURE,
  FREEZE_START_WATCH,
  FREEZE_STOP_WATCH
} from "./FreezeSchedulerDuck";

const POLL_INTERVAL_SECONDS = 5;

const delay = millis =>
  new Promise(resolve => {
    setTimeout(() => resolve(true), millis);
  });

export function* fetchFreezeLog(action) {
  try {
    const login = yield select(state => state.login);
    const authToken = login.authToken;
    const json = yield call(fetchApi, `/ServerStates/freezeLog`, {
      authToken,
      method: "GET"
    });
    if (!json.error) {
      yield put({
        type: FREEZE_FETCH_SUCCESS,
        data: json
      });
    } else {
      log.error("fetchFreezeLog api error:", JSON.stringify(json.error));
      yield put({
        type: FREEZE_FETCH_FAILURE,
        apiError: json.error.text._error
          ? json.error.text._error
          : json.error.text
      });
    }
  } catch (err) {
    log.warn("fetchFreezeLog err", err);
    yield put({ type: FREEZE_FETCH_FAILURE, apiError: "Network error" });
  }
}

export function* setFreezeLog({ freezeAt }) {
  try {
    const login = yield select(state => state.login);
    const authToken = login.authToken;
    const json = yield call(fetchApi, `/ServerStates/requestFreeze`, {
      authToken,
      payload: { freezeAt }
    });
    if (!json.error) {
      yield put({ type: FREEZE_SET_SUCCESS });
      yield put({ type: FREEZE_FETCH_SUBMIT });
    } else {
      log.error("setFreezeLog api error:", JSON.stringify(json.error));
      yield put({
        type: FREEZE_SET_FAILURE,
        apiErrorOnSetting: json.error.text._error
          ? json.error.text._error
          : json.error.text
      });
    }
  } catch (err) {
    log.warn("setFreezeLog err", err);
    yield put({ type: FREEZE_SET_FAILURE, apiErrorOnSetting: "Network error" });
  }
}

export function* cancelFreeze() {
  try {
    const login = yield select(state => state.login);
    const authToken = login.authToken;
    const json = yield call(fetchApi, `/ServerStates/cancelFreeze`, {
      authToken
    });
    if (!json.error) {
      yield put({ type: FREEZE_CANCEL_SUCCESS });
      yield put({ type: FREEZE_FETCH_SUBMIT });
    } else {
      log.error("cancelFreezeLog api error:", JSON.stringify(json.error));
      yield put({
        type: FREEZE_CANCEL_FAILURE,
        apiErrorOnSetting: json.error.text._error
          ? json.error.text._error
          : json.error.text
      });
    }
  } catch (err) {
    log.warn("cancelFreezeLog err", err);
    yield put({
      type: FREEZE_CANCEL_FAILURE,
      apiErrorOnSetting: "Network error"
    });
  }
}

function* watchFreezeState() {
  while (true) {
    yield race([call(fetchFreezeLog), take(FREEZE_STOP_WATCH)]);
    yield call(delay, POLL_INTERVAL_SECONDS * 1000);
  }
}

export default function* loginSaga() {
  yield takeEvery(FREEZE_FETCH_SUBMIT, fetchFreezeLog);
  yield takeEvery(FREEZE_SET_SUBMIT, setFreezeLog);
  yield takeEvery(FREEZE_CANCEL_SUBMIT, cancelFreeze);

  while (true) {
    // wait until watch start action arrives and start task
    yield take(FREEZE_START_WATCH);
    log.debug("Starting new freeze status polling tasks");
    const freezeStateTask = yield spawn(watchFreezeState);

    // wait until Logout action arrives and cancel task
    yield take(FREEZE_STOP_WATCH);
    log.debug('canceling freezeStateTask')
    yield cancel(freezeStateTask);
  }
}
