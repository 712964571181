import React from "react";
import PropTypes from "prop-types";
import tagDelete from "images/dashboard/tag-delete.svg";
import { elliptic } from "common/helpers/helpers";
import { fullSiteName } from "common/helpers/datahelpers";

import "./ResultWithDelete.css";

class ResultWithDelete extends React.Component {
  handleDeleteClick = e => {
    e.stopPropagation();
    this.props.onDeleteClick(this.props.obj.id, this.props.obj);
  }

  createContent = (obj, fieldNames, maxWidth) =>
    fieldNames.reduce((r, f) => {
      // make sure that if it's a site, and site's name is not set, then use city,state
      const val =
        f === "name" && !obj[f] && obj.city && obj.state
          ? fullSiteName(obj)
          : obj[f];

      return val ? `${r} ${elliptic(val, maxWidth)}` : r;
    }, "");

  render() {
    const { obj, fieldNames, maxWidth, iconFunc } = this.props;

    return (
      <div
        className="ResultWithDelete__Tag"
        id={obj.id}
        onClick={this.handleDeleteClick}
      >
        <div className="ResultWithDelete__Tag__Sitename">
          {iconFunc ? iconFunc(obj) : null}
          {this.createContent(obj, fieldNames, maxWidth)}
        </div>

        <img className="ResultWithDelete__img" src={tagDelete} alt="Delete" />
      </div>
    );
  }
}

ResultWithDelete.defaultProps = {
  fieldNames: ["name"],
  maxWidth: 20
};

ResultWithDelete.propTypes = {
  obj: PropTypes.object.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  fieldNames: PropTypes.arrayOf(PropTypes.string), // the field names in 'obj' to display
  maxWidth: PropTypes.number // max length of string to show before it is cut off (with '...')
};

export default ResultWithDelete;
