import React from 'react'
import PropTypes from 'prop-types';

import { TOGGLE_LEFT, TOGGLE_RIGHT } from './ToggleButtonDuck';
import './ToggleButton.css';

class ToggleButton extends React.Component {

  constructor(props) {
    super();

    // select the left button by default unless "nodefault" is given as prop
    if (!props.nodefault){
      props.selectedButton || props.selectButton(TOGGLE_LEFT, props.leftLabel)
    }
  }

  handleOnClickLeft = () => !this.props.disabled && this.props.selectButton(TOGGLE_LEFT, this.props.leftLabel);
  handleOnClickRight = () => !this.props.disabled && this.props.selectButton(TOGGLE_RIGHT, this.props.rightLabel);

  render () {
    const {selectedButton, leftLabel, rightLabel, disabled, preLeftLabel='', preRightLabel=''} = this.props;
    const roundClass = (this.props.rounded?'_Rounded':'_Unrounded')
    const classname = 'ToggleButton_Button ToggleButton_Button'+roundClass+' ToggleButton_';

    return (
      <div className='ToggleButton_Outer'>
      <div className={'ToggleButton ToggleButton'+roundClass}>
        <div className={classname+'Left'+roundClass+' ToggleButton_' + (selectedButton === TOGGLE_LEFT ? '' : 'un') + 'selected'}
          onClick={this.handleOnClickLeft}>
            {`${preLeftLabel} ${leftLabel}`}
        </div>
        <div className={classname+'Right'+roundClass+' ToggleButton_' + (selectedButton === TOGGLE_RIGHT ? '' : 'un') + 'selected'
        +` ${disabled?'ToggleButton_disabled':''}`}
          onClick={this.handleOnClickRight}>
            {`${preRightLabel} ${rightLabel}`}
        </div>
      </div>
      </div>
    )
  }
}

ToggleButton.propTypes = {
  selectedButton: PropTypes.string,
  selectButton: PropTypes.func.isRequired,
  leftLabel: PropTypes.string.isRequired,
  rightLabel: PropTypes.string.isRequired,
  rounded: PropTypes.bool,
  nodefault: PropTypes.bool,
  disabled: PropTypes.bool
}

export default ToggleButton


