import log from 'loglevel';

import { put, takeEvery, call, select } from 'redux-saga/effects'
import { WATCHDEL_SUCCESS, WATCHDEL_FAILURE, WATCHMANAGE_PURGE } from './WatchManageDuck.js';
import { WATCHLIST_APPLY_FILTER } from 'Watchlist/WatchListDuck'
import fetchApi from 'utils/fetchApi';

function* deleteWatchCall(authToken, id){
    try{

      const parsedJson = yield call(fetchApi, `/WatchLists/${id}`, {
          authToken,
          method: 'DELETE'
      })

      if (parsedJson.error) {
        yield put({
          type: WATCHDEL_FAILURE,
          message: parsedJson.error.text._error ? parsedJson.error.text._error : parsedJson.error.text,
        })
      }

      return {
        ...parsedJson
      }
    } catch(error){
      log.error('deleteWatchCall error', error)
      yield put({
        type: WATCHDEL_FAILURE,
        message: 'Network error.'
      })
    }
}

export function* postWatchlistPurge(action) {

  const login = yield select((state) => state.login);
  const deletedWatches = yield select((state) => state.watchlistmanage.deletedWatches);

  for (let id of deletedWatches) {
    log.debug('purge watch',id)
    const result = yield call(deleteWatchCall, login.authToken, id);

    if (result && !result.error){
      yield put({
        type: WATCHDEL_SUCCESS,
        id: id
      });
    }
  }

  yield put({
    type: WATCHLIST_APPLY_FILTER
  });
}

export default function* watchAddSaga () {
  yield takeEvery(WATCHMANAGE_PURGE, postWatchlistPurge)
}


