// This file implements the "ducks" pattern, i.e. combines action constants,
// action creators and reducers in one file instead of having multiple files per
// function. As long as there are not too many values, this makes it nice and
// compact.

// Action constants
export const WATCHLISTFILTER_RESET = 'WATCHLISTFILTER_RESET';
export const WATCHLISTFILTER_SETTHREATVALUES = 'WATCHLISTFILTER_SETTHREATVALUES';
export const WATCHLISTFILTER_SETADVVALUES = 'WATCHLISTFILTER_SETADVVALUES';

// Action creators

export const resetForm = () => ({
  type: WATCHLISTFILTER_RESET
})

export const setThreatTypeFilter = (thrtTypeVal, snowSliderVal, iceSliderVal, rainSliderVal, windSliderVal, timingVal, popSliderVal) => ({
  type: WATCHLISTFILTER_SETTHREATVALUES,
  thrtTypeVal,
  snowSliderVal,
  iceSliderVal,
  rainSliderVal,
  windSliderVal,
  timingVal,
  popSliderVal
})

export const setAdvTypeFilter = (advTypeVal) => ({
  type: WATCHLISTFILTER_SETADVVALUES,
  advTypeVal
})


// Reducer

const initialState = {
};


const watchlistFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case WATCHLISTFILTER_RESET:
      return initialState;

    case WATCHLISTFILTER_SETTHREATVALUES:
      return {
        ...state,
        thrtTypeVal: action.thrtTypeVal,
        snowSliderVal: action.snowSliderVal,
        iceSliderVal: action.iceSliderVal,
        rainSliderVal: action.rainSliderVal,
        windSliderVal: action.windSliderVal,
        timingVal: action.timingVal,
        popSliderVal: action.popSliderVal
      }
    case WATCHLISTFILTER_SETADVVALUES:
      return {
        ...state,
        advTypeVal: action.advTypeVal
      }
    default:
      return state

  }
}

export default watchlistFilterReducer;

