import log from 'loglevel'
import { put, takeEvery, call, select } from 'redux-saga/effects'
import { SITEADD_SUCCESS } from "Admin/SiteAdd/SiteAddDuck";
import { SITES_SUBMIT } from "common/dataRetrieval/DataDuck";

import {
  FILEUPLOAD_SUBMIT,
  FILEUPLOAD_SUCCESS,
  FILEUPLOAD_FAILURE,
  CHANGELIST_SUBMIT,
  CHANGELIST_SUCCESS,
  CHANGELIST_FAILURE,
  CHANGELIST_APPLY_SUBMIT,
  CHANGELIST_APPLY_SUCCESS,
  CHANGELIST_APPLY_FAILURE
} from './FileUploadDuck'
import fetchApi, { postFormData } from 'utils/fetchApi'

export function* fetchFileUploadSaga ({ file, companyId }) {
  try {
    const url = `/Companies/${companyId}/sites/bulk`;
    const authToken = yield select((state) => state.login.authToken);

    let body = new FormData()
    body.append('file', file)

    const parsedJson = yield call(postFormData, url, { body, authToken })

    if (!parsedJson.error){

      yield put({ type: FILEUPLOAD_SUCCESS, data: parsedJson })

      if(!parsedJson.code && (!parsedJson.errors || (parsedJson.errors.length === 0)) )
        yield put({ type: SITEADD_SUCCESS, sites: parsedJson.added })

    } else {
      yield put({ type: FILEUPLOAD_FAILURE,
        uploadError: parsedJson.error.text
      })
    }
  } catch (error) {
    log.error('fetchFileUploadSaga', error)
    yield put({ type: FILEUPLOAD_FAILURE,
      uploadError: 'Network error.'
    })
  }
}

export function* postChangeListFile ({companyId, file, allowMultiSiteAddresses}) {
  try {
    
    const url = `/Companies/${companyId}/sites/bulk/changeList`;
    const authToken = yield select((state) => state.login.authToken);

    let body = new FormData()
    body.append('allowMultiSiteAddresses', allowMultiSiteAddresses);
    body.append('file', file)

    const parsedJson = yield call(postFormData, url, { body, authToken })

    if (!parsedJson.error){
      yield put({ type: CHANGELIST_SUCCESS, data: parsedJson })
    } else {
      yield put({ type: CHANGELIST_FAILURE,
        uploadError: parsedJson.error.text
      })
    }
  } catch (err) {
    log.error('postChangeListFile err', err);
    yield put({ type: CHANGELIST_FAILURE,
      uploadError: 'Network error.'
    })
  }
}

export function* applyChangeListFile ({companyId, listData, allowMultiSiteAddresses}) {
  try {
    
    const url = `/Companies/${companyId}/sites/bulk/changeList/apply`;
    const authToken = yield select((state) => state.login.authToken);
    const payload = {
      deletes: listData.deletes,
      inserts: listData.inserts,
      updates: listData.updates,
      allowMultiSiteAddresses
    };

    const parsedJson = yield call(fetchApi, url, { payload, authToken })

    if (!parsedJson.error){
      yield put({ type: CHANGELIST_APPLY_SUCCESS, data: parsedJson })
      if(parsedJson.results && (parsedJson.results.deleted || parsedJson.results.inserted || parsedJson.results.updated))
        yield put({ type: SITES_SUBMIT, companyId })
    } else {
      yield put({ type: CHANGELIST_APPLY_FAILURE,
        uploadError: parsedJson.error.text._error ? parsedJson.error.text._error : parsedJson.error.text
      })
    }
  } catch (err) {
    log.error('applyChangeListFile err', err);
    yield put({ type: CHANGELIST_APPLY_FAILURE,
      uploadError: 'Network error.'
    })
  }
}

// listen for actions of type FILEUPLOAD_SUBMIT and use them
export default function* fileUploadSaga () {
  yield takeEvery(FILEUPLOAD_SUBMIT, fetchFileUploadSaga);
  yield takeEvery(CHANGELIST_SUBMIT, postChangeListFile);
  yield takeEvery(CHANGELIST_APPLY_SUBMIT, applyChangeListFile);
}

