export const COMPANYADD_OPEN = 'COMPANYADD_OPEN';
export const COMPANYADD_CLOSE = 'COMPANYADD_CLOSE';
export const COMPANYADD_SUBMIT = 'COMPANYADD_SUBMIT';
export const COMPANYADD_SUCCESS = 'COMPANYADD_SUCCESS';
export const COMPANYADD_FAILURE = 'COMPANYADD_FAILURE';

export const closeAddModal = () => ({
  type: COMPANYADD_CLOSE
})

export const openAddModal = () => ({
  type: COMPANYADD_OPEN
})

const initialState = {
  showModal: false,
  page: 1,
  isFetching: false
}

const companyAddReducer = (state = initialState, action) => {
  switch (action.type) {
    case COMPANYADD_OPEN:
      return {
        ...state,
        showModal: true
      }
    case COMPANYADD_CLOSE:
      return {
        ...state,
        showModal: false
      }
    case COMPANYADD_SUBMIT:
      return {
        ...state,
        isFetching: true
      }
    case COMPANYADD_SUCCESS:
      return {
        ...state,
        isFetching: false
      }
    case COMPANYADD_FAILURE:
      return {
        ...state,
        isFetching: false
      }
    default:
      return state

  }
}

export default companyAddReducer;
