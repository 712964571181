import immutable from 'object-path-immutable';

export const CONTACT_SUBMIT = 'CONTACT_SUBMIT';
export const CONTACT_SUCCESS = 'CONTACT_SUCCESS';
export const CONTACT_FAILURE = 'CONTACT_FAILURE';
export const CLEAR_CONTACT_DATA = 'CLEAR_CONTACT_DATA'

// Action creators

export const resetForm = (id) => {
  return {
    type: CLEAR_CONTACT_DATA,
    id
  }
}

export const sendEmail = (id, name, email, companyName, contactNumber) => {
  return {
    type: CONTACT_SUBMIT,
    id,
    name,
    email,
    companyName,
    contactNumber
  }
}

// Reducer

const initialState = {
  form: {
    name: "",
    email: "",
    isSubmitting: false,
    error: "",
    isSent: false
  },
  demo: {
    name: "",
    email: "",
    companyName: "",
    contactNumber: "",
    isSubmitting: false,
    error: "",
    isSent: false
  }
};

const contactReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_CONTACT_DATA:
      return initialState;

    case CONTACT_SUBMIT:
      return immutable(state)
        .set(`${action.id}.isSubmitting`, true)
        .set(`${action.id}.error`, '')
        .value();

    case CONTACT_SUCCESS:
      return immutable(state)
        .set(`${action.id}.isSubmitting`, false)
        .set(`${action.id}.isSent`, true)
        .value();

    case CONTACT_FAILURE:
      return immutable(state)
        .set(`${action.id}.isSubmitting`, false)
        .set(`${action.id}.isSent`, false)
        .set(`${action.id}.error`, action.error)
        .value();

    default:
      return state
  }
}

export default contactReducer;
