import React from 'react'
import PropTypes from 'prop-types';
import { WwaRadio } from 'common/components/WwaInputElements';

import './LocationSelector.css';
import 'common/css/inputElements.css';

const StateSelectBox = (props) => (
  <div className='LocationSelector_Shiftright'>
    <select 
        className='wwa__select'
        id='stateselect' onChange={props.onChange}
        value={props.selectedFedState}
    >
      <option disabled value='-select'>Select state</option>
      {props.fedStates && Object.keys(props.fedStates).map(fs =>
          <option 
            key={fs} 
            value={fs}
          >{fs}</option>
      )}
    </select>
  </div>
)

const CitySelectBox = (props) => (
  <div className='LocationSelector_Shiftright'>
    <select 
        className='wwa__select'
        id='cityselect' onChange={props.onChange}
        value={props.selectedCity}
    >
      <option 
        value='-select'>Select city</option>
      {props.selectedFedState && props.fedStates[props.selectedFedState].map(c =>
        <option key={c} value={c}>{c}</option>
      )}
    </select>
  </div>
)

const StateAndCitySelector = (props) => (
  <div>
    <div className='Location_Outer'>
      <WwaRadio id="statecity" name="locradio-set"  
        value={props.isStateCitySelected} onChange={props.handleOnClick}
        checked={props.selectedRadio === 'statecity'}/>
      <span className='wwa_radio_label'>Filter by State, City, County</span>
    </div>

    {props.selectedRadio === 'statecity' &&
      <StateSelectBox {...props} onChange={props.onSelectState} />
    }
    {props.selectedFedState &&
      <CitySelectBox {...props} onChange={props.onSelectCity} />
    }
  </div>
)

const ZipCodeSelector = (props) => (
  <div>
    <div>
      <WwaRadio id="zipcode" name="locradio-set"  
        value={props.isZipCodeSelected} onChange={props.handleOnClick}
        checked={props.selectedRadio === 'zipcode'}/>
      <span className='wwa_radio_label'>Filter by Zip code</span>
    </div>

    {props.selectedRadio === 'zipcode' &&
      <div className='LocationSelector_Shiftright'>
        <input 
          className='LocationSelector_Inputbox'
          placeholder='Enter zip code'
          value={props.selectedZipCode}
          type="text" onChange={props.onZipCodeChanged} />
      </div>
    }
  </div>
)

class LocationSelector extends React.Component {

  handleOnClick = (e) => {
    this.props.selectRadio(e.target.id)
  }

  handleStateSelected = (e) => {
    this.props.setFedState(e.target.value)
  }

  handleCitySelected = (e) => {
    this.props.setCity(e.target.value)
  }

  handleZipCodeChange = (e) => {
    this.props.setZipCode(e.target.value);
  }

  render () {
    const {
      selectedRadio      
    } = this.props;

    return (
      <div>
        {selectedRadio !== 'zipcode' &&
          <StateAndCitySelector {...this.props} 
            handleOnClick={this.handleOnClick} 
            onSelectState={this.handleStateSelected}
            onSelectCity={this.handleCitySelected}
          />
        }

        {selectedRadio !== 'statecity' &&
          <ZipCodeSelector {...this.props}
            handleOnClick={this.handleOnClick} 
            onZipCodeChanged={this.handleZipCodeChange}
          />
        }
      </div>
    )
  }
}

LocationSelector.propTypes = {
  isStateCitySelected: PropTypes.bool,
  isZipCodeSelected: PropTypes.bool,
  selectedRadio: PropTypes.string,
  fedStates: PropTypes.object.isRequired,
  selectedFedState: PropTypes.string,
  selectedCity: PropTypes.string,
  selectedZipCode: PropTypes.string,
  selectRadio: PropTypes.func.isRequired,
  setFedState: PropTypes.func.isRequired,
  setCity: PropTypes.func.isRequired,
  setZipCode: PropTypes.func.isRequired,
}

export default LocationSelector


