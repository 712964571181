// This file implements the "ducks" pattern, i.e. combines action constants,
// action creators and reducers in one file instead of having multiple files per
// function. As long as there are not too many values, this makes it nice and
// compact.

// Action constants

// Action creators


// Reducer

const initialState = {
};

const watchTileReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state

  }
}

export default watchTileReducer;

