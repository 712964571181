import {
  CITY_STATE,
  ZIP_CODE,
  ALL_FIELDS,
  STATE,
  NAME
} from "./SiteSelectorDuck";
import { MY_SITES } from "SiteFilter/SiteFilterDuck";
import { createSelector } from "reselect";

// state.companySites in admin area, state.sites in dashboard
const selectSites = (state, useMySitesFilter, companyId) =>
  state.companySites.data && state.companySites.data[companyId]
    ? state.companySites.data[companyId]
    : state.sites;
const selectSiteselector = state => state.siteselector;
const selectTogglebutton = state => state.togglebutton;
const selectMySites = state => state.mySites;

// whether a company sites/my sites toggle should affect the sites selector function
const selectUseMySitesFilter = (state, useMySitesFilter) => useMySitesFilter;
const selectCompanyId = (state, useMySitesFilter, companyId) => companyId;

const getTogglebutton = createSelector(
  selectTogglebutton,
  togglebutton => (togglebutton && togglebutton["watchListAddSiteFilter"]) || {}
);

const orderedSites = (sitemap, orderField = "name") => {
  const ordered = {};
  Object.keys(sitemap)
    .sort((a, b) =>
      sitemap[a][orderField] ? sitemap[a][orderField].localeCompare(sitemap[b][orderField]) : -1
    )
    .forEach(key => {
      ordered[key] = sitemap[key];
    });
  return ordered;
};

// call this reselect selector with 3 arguments: (state, useMySitesFilter, companyId)
// selectSiteFunc: (state:Object, useMySitesFilter:bool, companyId:String) => (id:String -> site:Object)
export const makeGetSites = (selectSiteFunc) => {
  return createSelector(
    [
      selectSiteFunc || selectSites,
      getTogglebutton,
      selectMySites,
      selectUseMySitesFilter,
      selectCompanyId,
      selectSiteselector
    ],
    (
      sites,
      togglebutton,
      mySites,
      useMySitesFilter,
      companyId,
      siteselector
    ) => {
      const newSites = {};
      if (useMySitesFilter && togglebutton.label === MY_SITES) {
        mySites.siteIds.forEach(id => {
          if (sites[id]?.companyId === companyId) newSites[id] = sites[id];
        });
      } else {
        Object.keys(sites).forEach(id => {
          if (sites[id].companyId === companyId) newSites[id] = sites[id];
        });
      }
      return orderedSites(newSites, siteselector.selectSiteOrderBy);
    }
  );
}

const nameDS = sites => ({
  name: "name",
  keys: ["name"],
  data: Object.values(sites)
});
const stateDS = sites => ({
  name: "state",
  keys: ["state"],
  data: Object.values(sites)
});
const cityStateDS = sites => ({
  name: "cityState",
  keys: ["city", "state"],
  data: Object.values(sites)
});
const zipCodeDS = sites => ({
  name: "zipCode",
  keys: ["zipCode"],
  data: Object.values(sites)
});

export const makeGetDataSources = (selectSiteFunc) => {
  return createSelector(
    [selectSiteselector, makeGetSites(selectSiteFunc), getTogglebutton],
    (siteselector, sites, sitefilter) => {
      switch (siteselector.selectSiteBy) {
        case ALL_FIELDS:
          return [
            nameDS(sites),
            stateDS(sites),
            cityStateDS(sites),
            zipCodeDS(sites)
          ];
        case STATE:
          return [stateDS(sites)];
        case NAME:
          return [nameDS(sites)];
        case CITY_STATE:
          return [cityStateDS(sites)];
        case ZIP_CODE:
          return [zipCodeDS(sites)];
        default:
          return [];
      }
    }
  );
};

export default makeGetDataSources;
