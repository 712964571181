import log from "loglevel";
import { put, select, takeEvery } from "redux-saga/effects";
import isWithinRange from "date-fns/is_within_range";
import addMonths from "date-fns/add_months";
import addDays from "date-fns/add_days";
import {
  NOTIFICATIONLIST_SET_VISIBLENOTIFICATIONS,
  NOTIFICATIONLIST_SET_INDICATOR,
  NOTIFICATIONLIST_APPLY_FILTER,
  NOTIFICATIONLIST_SET_ALL_READ
} from "./NotificationListDuck";
import { NOTIFICATION_SET_READ } from 'NotificationTile/NotificationTileDuck';
import { COMPANY_SITES } from "SiteFilter/SiteFilterDuck";
import { matchSearchTerm } from "common/helpers/helpers";
import { DATA_SUCCESS } from 'common/dataRetrieval/DataDuck.js';

// let only those notifications through whose subject or body matches the
// value in search box
const filterBySearchTerm = (notification, searchTerm, sites) => {
  // if search box is empty, all must match
  if (!searchTerm) return true;

  const matches = matchSearchTerm(searchTerm);

  const res = notification.affectedSiteIds
    .map(sid => sites[sid])
    .filter(s => s) // filter out sites that we don't know about
    .filter(
      s =>
        !s ||
        matches(s.name) ||
        matches(s.city) ||
        matches(s.state) ||
        matches(s.zipCode)
    );

  return res.length > 0;
};

const filterByFilterParams = (n, { notificationType, dateFrom, dateTo }) => {
  const safeDateFrom = dateFrom || addDays(new Date(), -1);
  const safeDateTo =
    (dateTo && addDays(dateTo, 1)) || addMonths(safeDateFrom, 12);
  return (
    (!notificationType ||
      notificationType === "-select" ||
      n.type === notificationType) &&
    isWithinRange(n.createdAt, safeDateFrom, safeDateTo)
  );
};

const filterBySiteFilter = (n, selectedFilter, mySites) => {
  return (
    selectedFilter === COMPANY_SITES ||
    n.affectedSiteIds.find(sid => mySites.siteIds.includes(sid))
  );
};

// apply the "search box" filter, based on the "notifications" redux state
export function* filterSearchSaga(action) {
  try {
    const sites = yield select(state => state.sites);
    const notifications = yield select(state => state.notifications);
    const searchbox = yield select(state => state.searchbox["notifications"]);
    const notificationfilter = yield select(state => state.notificationfilter);
    const siteFilterButton = yield select(
      state => state.togglebutton["sitefilter"]
    );
    const siteFilter = siteFilterButton
      ? siteFilterButton.label
      : COMPANY_SITES;
    const mySites = yield select(state => state.mySites);

    const visibleNotifications = Object.values(notifications)
      .filter(n => filterBySearchTerm(n, searchbox && searchbox.value, sites))
      .filter(n => filterByFilterParams(n, notificationfilter))
      .filter(n => filterBySiteFilter(n, siteFilter, mySites))
      .map(n => n.id);

    yield put({
      type: NOTIFICATIONLIST_SET_VISIBLENOTIFICATIONS,
      visibleNotifications
    });
  } catch (error) {
    log.error("Notifications filterSearch saga error", error);
  }
}

export function* resetNotificationIndicator({ payload }) {
  try {
    yield put({
      type: NOTIFICATIONLIST_SET_INDICATOR,
      count: 0
    });
  } catch (err) {
    log.warn("resetNotificationIndicator err", err);
  }
}

export function* setAllNotificationsRead ({ids}) {
  try {
    for (let id of ids)
      yield put({
        type: NOTIFICATION_SET_READ,
        tileId: id
      });
  } catch (err) {
    log.warn('setAllNotificationsRead err', err);
  }
}

export function* setNotificationIndicator({ payload }) {
  try {
    yield put({
      type: NOTIFICATIONLIST_SET_INDICATOR,
      count: payload.notifications ? payload.notifications.length : 0,
      ids: payload.notifications.map(n => n.id)
    });

    yield put({
      type: NOTIFICATIONLIST_APPLY_FILTER
    });
  } catch (err) {
    log.warn("setNotificationIndicator err", err);
  }
}

// create an array of notification items to show in notifications panel based on:
// 0. forced filter apply (e.g. when initially loading the data)
// 1. notifications search box change and
// 2. notifications filter parameters change
export default function* notificationFilterSaga() {
  yield takeEvery(NOTIFICATIONLIST_APPLY_FILTER, filterSearchSaga);
  yield takeEvery(DATA_SUCCESS, setNotificationIndicator);
  yield takeEvery(NOTIFICATIONLIST_SET_ALL_READ, setAllNotificationsRead);
}
