import log from 'loglevel';
import { call, put, takeEvery } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import FileSaver from 'file-saver';
import fetchApi, { getBlob } from 'utils/fetchApi';
import {
  REPORTCREATEZIP_FAILURE,
  REPORTCREATEZIP_SUBMIT,
  REPORTCREATEZIP_SUCCESS,
  REPORTPICKUP_FAILURE,
  REPORTPICKUP_SUBMIT,
  REPORTPICKUP_SUCCESS,
  REPORTDOWNLOADZIP_SUBMIT,
  REPORTDOWNLOADZIP_SUCCESS,
  REPORTDOWNLOADZIP_FAILURE
} from 'SiteReportPickup/SiteReportPickupDuck';
import { SERVER_FEATURES_SUBMIT } from 'common/ducks/pollNewDataDuck';
import { IMAGE_FETCH_SUBMIT } from 'common/ducks/imageUploadDuck';

function* fetchSiteReportData(action) {
  try {
    const { token, reportApiUrl } = action;
    const url = `/summary?token=${token}`;
    const parsedJson = yield call(fetchApi, url, {
      prefix: reportApiUrl,
      method: 'GET'
    });

    if (!parsedJson.error) {
      yield put({
        type: REPORTPICKUP_SUCCESS,
        data: parsedJson
      });
      yield put({
        type: SERVER_FEATURES_SUBMIT
      });
      if((parsedJson.summary || {}).useLogo){
        yield put ({
          type: IMAGE_FETCH_SUBMIT,
          imgType: 'companyLogo',
          id: (parsedJson.summary || {}).companyId
        });
      }
    } else {
      yield put({
        type: REPORTPICKUP_FAILURE,
        message: parsedJson.error.text._error || parsedJson.error.text.error
      });
    }
  } catch (error) {
    log.error('fetchSiteReportData error', error); // something happened during the network call
    yield put({
      type: REPORTPICKUP_FAILURE,
      message: 'Network error.'
    });
  }
}

function* postSiteReportZip(action) {
  try {
    const { token, reportApiUrl, reportList } = action;
    const url = `/zip?token=${token}`;
    const payload = {
      token,
      reportList
    };
    const parsedJson = yield call(fetchApi, url, {
      prefix: reportApiUrl,
      payload
    });

    yield delay(3000);

    if (!parsedJson.error) {
      yield put({
        type: REPORTCREATEZIP_SUCCESS,
        result: parsedJson,
        token
      });
      yield put({
        type: REPORTDOWNLOADZIP_SUBMIT,
        name: parsedJson.name,
        token,
        reportApiUrl
      });
    } else {
      yield put({
        type: REPORTCREATEZIP_FAILURE,
        message: parsedJson.error.text
      });
    }
  } catch (error) {
    log.error('postSiteReportZip error', error); // something happened during the network call
    yield put({
      type: REPORTPICKUP_FAILURE,
      message: 'Network error.'
    });
  }
}

function* downloadAndSaveSiteReportZip(action) {
  try {
    const { name, token, reportApiUrl } = action;
    const url = `/zip/${name}?token=${token}`;
    const parsedResponse = yield call(getBlob, url, {
      prefix: reportApiUrl,
      method: 'GET'
    });

    if (!parsedResponse.error) {
      FileSaver.saveAs(parsedResponse);
      yield put({
        type: REPORTDOWNLOADZIP_SUCCESS,
        result: parsedResponse.result
      });
    } else {
      log.error(
        'downloadAndSaveSiteReportZip error:',
        parsedResponse.error.text
      );
      yield put({
        type: REPORTDOWNLOADZIP_FAILURE,
        message: parsedResponse.error.text
      });
    }
  } catch (error) {
    log.error('downloadAndSaveSiteReportZip error', error); // something happened during the network call
    yield put({
      type: REPORTDOWNLOADZIP_FAILURE,
      message: 'Network error.'
    });
  }
}

export default function* siteSaga() {
  yield takeEvery(REPORTPICKUP_SUBMIT, fetchSiteReportData);
  yield takeEvery(REPORTCREATEZIP_SUBMIT, postSiteReportZip);
  yield takeEvery(REPORTDOWNLOADZIP_SUBMIT, downloadAndSaveSiteReportZip);
}
