// This file implements the "ducks" pattern, i.e. combines action constants,
// action creators and reducers in one file instead of having multiple files per
// function. As long as there are not too many values, this makes it nice and
// compact.


// Action constants

export const RESETPASS_SUBMIT = 'RESETPASS_SUBMIT';
export const RESETPASS_SUCCESS = 'RESETPASS_SUCCESS';
export const RESETPASS_FAILURE = 'RESETPASS_FAILURE';
export const CLEAR_RESETPASS_DATA = 'CLEAR_RESETPASS_DATA'


// Action creators

export const resetForm = () => {
  return { type: CLEAR_RESETPASS_DATA }
}

// redux-form calls this function with its 'values' object, whose keys
// correspond to form fields
export const resetPassword = ({email}) => {
  return {
    type: RESETPASS_SUBMIT,
    email
  }
}


// Reducer

const initialState = {
  isFetching: false,
  isNewPasswordSet: false,
  errorMessage: undefined
};

const resetPassReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_RESETPASS_DATA:
      return initialState;
    case RESETPASS_SUBMIT:
      return {
        ...state,
        isFetching: true,
        isNewPasswordSet: false,
        errorMessage: undefined,
      }
    case RESETPASS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isNewPasswordSet: true,
        errorMessage: undefined,
      }
    case RESETPASS_FAILURE:
      return {
        ...state,
        isFetching: false,
        isNewPasswordSet: false,
        errorMessage: action.message,
      }
    default:
      return state

  }
}

export default resetPassReducer;
