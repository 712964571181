import log from 'loglevel';
import queryString from 'query-string';

const SERVER = ''

/*
 * API query helpers
 */

/*
 * Caller to backend API; returns a promise
 *
 * The named parameters can be used like this:
 *  fetchApi(url, { authToken })
 *  fetchApi(url, { query:{access_token:authToken} })
 *  fetchApi(url, { payload: {username:"jon", password:"doe"},
 *                  query:{access_token:authToken} })
 *
 * @param {string} url The full URL to call. Obligatory.
 * @param {object} Named parameters that are deconstructed:
 * @param {string} payload The POST payload as a JSON object; optional.
 * @param {string} authToken The access token.
 * @param {object} query Object of keys and values that gets encoded for URI query.
 * @param {object} method POST, GET etc; defaults to POST
 * @promise
 */
export default (url, { payload, authToken='', query, method='POST' } = {}) => {
  // construct query parameters:
  const queryStr = query ? `?${serializeQuery(query)}` : ''
  const fullquery = `${SERVER}/api${url}${queryStr}`;

  // see if there's any body payload
  const body = payload ? JSON.stringify(payload) : undefined

  // add headers
  const headers = new Headers()
  headers.append('Content-Type', 'application/json')
  authToken && headers.append('X-Access-Token', authToken)

  const obj = {
    headers,
    accept: 'application/json',
    method,
    body
  }

  return fetch(fullquery, obj)
}

// for file upload, the request must be different: no json content-type
export const postFormData = (url, { body, authToken='', query, method='POST' } = {}) => {
  // construct query parameters:
  const queryStr = query ? `?${serializeQuery(query)}` : ''
  const fullquery = `/api${url}${queryStr}`;

  // add headers
  const headers = new Headers()
  authToken && headers.append('X-Access-Token', authToken)

  const obj = {
    headers,
    accept: 'application/json',
    method,
    body
  }

  return fetch(fullquery, obj)
}

/* convert query into URL query ?key=falue&key=value&... (encoded)
 * e.g. query={access_token: "kasdkfd"} -> ?access_token=kasdkfd
 */
function serializeQuery( obj ) {
  return queryString.stringify(obj)
  /*
  return Object.keys(obj)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&')
    */
}

/*
 * Checks the return code of fetch call (in range 200-299)
 * @param {promise} response Fetch's respone.
 * @promise
 */
export const checkStatus = (response) => {
  if (response.ok || response.status === 404) {
    return response;
  }
  const error = new Error(`HTTP Error ${response.statusText}`);
  error.status = response.statusText;
  error.response = response;
  //log.error('checkStatus()', error); // eslint-disable-line no-console
  throw error;
}

/*
 * Turn backend API errors from loopback into human readable messages.
 * This is where localization could be applied as well.
 */
export const errorConverter = (err) => {
  switch (err.statusCode) {
    case 500:
      if (err.message === "User limit exceeded"){
        return {_error: err.message }
      } else if (err.message === 'Incorrect Site Data') {
        return {_error: err.data }
      } else if (err.message === 'no Subscription') {
        return {_error: err.message }
      } else if (err.message === 'File cannot be parsed') {
        return {_error: err.message }
      } else if (err.message === 'No file') {
        return {_error: 'The file does not exist' }
      } else if (err.message === 'Timezone Request Error') {
        if (err.details && err.details.trim() === 'ZERO_RESULTS')
          return {_error: 'Timezone matching found no results' }
        else return {_error: 'Timezone matching error' }
      } else if (err.message === 'Geocoding Request Error') {
        if (err.details && err.details.trim() === 'ZERO_RESULTS')
          return {_error: 'Geocoding found no results' }
        else return {_error: 'Geocoding error' }
      } else if (err.message === 'File cannot be parsed') {
        return {_error: err.message }
      }
      return {
        _error: 'An error occurred internally.'
      }

    case 400:
      if (err.code) {
        switch (err.code) {
          case 'EMAIL_REQUIRED':
            return {
              email: 'Email is required.'
            }
          case 'INVALID_TOKEN':
            return {
              error: 'Invalid token.',
              _error: 'Invalid token.'
            }
          case 'USERNAME_EMAIL_REQUIRED':
            return {
              username: 'Username or email is required.',
              _error: 'Login failed!'
            }
          default: return 'An error occurred.'
        }
      }
      break;

    case 401:
      if (err.code) {
        switch (err.code) {
          case 'LOGIN_FAILED':
            return {
              username: 'This combination of email and password is invalid.',
              _error: 'Login failed!'
            }
          case 'INVALID_TOKEN':
            return {
              _error: 'Invalid access token.',
              authExpired: true
            }
          case 'AUTHORIZATION_REQUIRED':
            return {
              _error: 'Authorization required.',
              authExpired: true
            }
          default: return 'An error occurred.'
        }
      }
      break;

    case 404:
      if (err.code) {
        switch (err.code) {
          case 'EMAIL_NOT_FOUND':
            return {
              email: 'Email not found.'
            }
          case 'USER_NOT_FOUND':
            return { _error: 'User not found.' }
          default: return 'An error occurred.'
        }
      }
      return 'Not found.';

    case 422:
      if (err.code) {
        switch (err.code) {
          case 'INVALID_PASSWORD':
            return {
              password: 'Password must not be empty.',
              _error: 'Login failed!'
            }

          case 'PASSWORD_TOO_LONG':
            return {
              password: 'Password must not be longer than 72 characters.',
              _error: 'Login failed!'
            }
          default: return 'An error occurred.'
        }
      }
      if (err.details && err.details.codes) {
        if (err.details.codes.email) {
          switch (err.details.codes.email[0]) {
            case 'presence':
              return {
                username: 'Email must not be empty.',
                _error: 'Login failed!'
              }
            case 'uniqueness':
              return {
                username: 'Email is already taken.',
                _error: 'Email is already taken.'
              }
            case 'custom.email':
              return {
                username: 'Email is invalid.',
                _error: 'Login failed!'
              }
            default: return 'Validation failed for email.'
          }
        }
        if (err.details.codes.name) {
          switch (err.details.codes.name[0]) {
            case 'presence':
              return {
                name: 'Field must not be empty.',
              }
            default: return 'Validation failed for name.'
          }
        }
      }
      return 'An error occurred (422).'

    default:
      return 'An error occurred.'
  }

  return 'An error occurred...'
}

/*
 * Unwraps return promise from fetch into JSON object
 * @param {promise} response Fetch's respone.
 * @return {object} JSON response data.
 */
export const parseJSON = (response) => {
  var contentType = response.headers.get("content-type");
  if(contentType && contentType.indexOf("application/json") !== -1) {
    return response.json()
      .then(function(json) {
        // log.debug(`got JSON: ${json}`);
        return json;
      })
      .catch (e => {
        log.warn('expected JSON')
        const error = new Error('Server did not return correct data')
        throw error
      })
  } else {
    log.warn("parseJSON(): We haven't got JSON!");
    const error = new Error('Server did not return correct data')
    throw error
  }
}
