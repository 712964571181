import log from 'loglevel';
import {
  take,
  call,
  put,
  select,
  takeEvery,
  takeLatest
} from 'redux-saga/effects';
import fetchApi from 'utils/fetchApi';
import {
  APIAPPS_SUBMIT,
  APIAPPS_SUCCESS,
  APIAPPS_FAILURE,
  APIAPPSUPSERT_SUBMIT,
  APIAPPSUPSERT_SUCCESS,
  APIAPPSUPSERT_FAILURE,
  APIAPPDEL_SUBMIT,
  APIAPPDEL_SUCCESS,
  APIAPPDEL_FAILURE,
} from './ApiAppsDuck';
import { APISTATS_SUBMIT, APISTATS_REQUEST } from './ApiStatsDuck';

function* fetchApiAppsSaga(action) {
  try {
    const authToken = yield select(state => state.login.authToken);
    const url = `/Companies/${action.companyId}/ApiApps`;
    const parsedJson = yield call(fetchApi, url, { method: 'GET', authToken });

    if (!parsedJson.error) {
      yield put({ type: APIAPPS_SUCCESS, data: parsedJson });
    } else {
      yield put({
        type: APIAPPS_FAILURE,
        message: parsedJson.error.text._error
          ? parsedJson.error.text._error
          : parsedJson.error.text
      });
    }
  } catch (error) {
    log.error('fetchApiAppsSaga', error);
    yield put({
      type: APIAPPS_FAILURE,
      message: 'Network error.'
    })
  }
}

function* submitApiApp({ payload }) {
  try {
    const authToken = yield select(state => state.login.authToken);
    const id = payload.id;
    const updateMode = id;
    const url = updateMode ? `/ApiApps/${id}` : '/ApiApps';
    const data = {
      ...payload,
      ipList: payload.ipList.replace(/\s/g, '').split(',')
    };
    const parsedJson = yield call(fetchApi, url, {
      authToken,
      method: updateMode ? 'PUT' : 'POST',
      payload: data
    });

    if (!parsedJson.error) {
      yield put({ type: APIAPPSUPSERT_SUCCESS, data: parsedJson });
    } else {
      yield put({
        type: APIAPPSUPSERT_FAILURE,
        message: parsedJson.error.text._error
          ? parsedJson.error.text._error
          : parsedJson.error.text
      });
    }
  } catch (error) {
    log.error('fetchApiAppssSaga', error);
  }
}

function* deleteApiApp(action) {
  try {
    const authToken = yield select(state => state.login.authToken);
    const id = action.id;
    const url = `/ApiApps/${id}/safeDelete`;
    const parsedJson = yield call(fetchApi, url, {
      authToken,
      method: 'DELETE'
    });

    if (!parsedJson.error) {
      yield put({
        type: APIAPPDEL_SUCCESS,
        id
      });
    } else {
      yield put({
        type: APIAPPDEL_FAILURE,
        message: parsedJson.error.text._error
          ? parsedJson.error.text._error
          : parsedJson.error.text,
        payload: parsedJson.error.text
      });
    }
  } catch (error) {
    log.error('deleteApiApp', error);
  }
}

export default function* apiAppsSagas() {
  yield takeLatest(APIAPPS_SUBMIT, fetchApiAppsSaga);
  yield takeEvery(APIAPPSUPSERT_SUBMIT, submitApiApp);
  yield takeEvery(APIAPPDEL_SUBMIT, deleteApiApp);
  yield call(apiAppsAndStats);
}

// make sure that app data is loaded before stats data
function* apiAppsAndStats() {
  while (true) {
    const { companyId } = yield take(APISTATS_REQUEST);
    yield take(APIAPPS_SUCCESS);
    yield put({ type: APISTATS_SUBMIT, companyId });
  }
}
