const SET_EVENT_TOTAL = "SET_EVENT_TOTAL";
const SET_EVENT_TOTAL_BOUNDS = "SET_EVENT_TOTAL_BOUNDS";

export const setSelected = selected => ({
  type: SET_EVENT_TOTAL,
  selected
});

export const setBounds = bounds => ({
  type: SET_EVENT_TOTAL_BOUNDS,
  bounds
});

const initialState = {
  selected: undefined,
  bounds: {}
};

const eventTotals = (state = initialState, action) => {
  switch (action.type) {
    case SET_EVENT_TOTAL:
      return {
        ...state,
        selected: action.selected
      };

    case SET_EVENT_TOTAL_BOUNDS:
      return {
        ...state,
        bounds: action.bounds
      }

    default:
      return state;
  }
};

export default eventTotals;
