export const SITEADD_OPEN = 'SITEADD_OPEN';
export const SITEADD_CLOSE = 'SITEADD_CLOSE';
export const SITEADD_SUBMIT = 'SITEADD_SUBMIT';
export const SITEADD_SUCCESS = 'SITEADD_SUCCESS';
export const SITEADD_FAILURE = 'SITEADD_FAILURE';

export const closeAddModal = () => ({
  type: SITEADD_CLOSE
})

export const openAddModal = () => ({
  type: SITEADD_OPEN
})

const initialState = {
  showModal: false,
  page: 1,
  isFetching: false
}

const siteAddReducer = (state = initialState, action) => {
  switch (action.type) {
    case SITEADD_OPEN:
      return {
        ...state,
        showModal: true
      }
    case SITEADD_CLOSE:
      return {
        ...state,
        showModal: false
      }
    case SITEADD_SUBMIT:
      return {
        ...state,
        error: null,
        isFetching: true
      }
    case SITEADD_SUCCESS:
      return initialState;

    case SITEADD_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.message,
      }
    default:
      return state

  }
}

export default siteAddReducer;
