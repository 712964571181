import log from 'loglevel';
import {
  call,
  take,
  race,
  put,
  select,
  spawn,
  cancel
} from 'redux-saga/effects';
import { LOGOUT_SUBMIT, LOGIN_SUCCESS } from 'Login/LoginDuck';
import { NEW_DATA_AVAILABLE, SERVER_FEATURES_SUBMIT } from 'common/ducks/pollNewDataDuck';
import { DATA_SUBMIT, DATA_SUCCESS } from 'common/dataRetrieval/DataDuck';
import fetchApi from 'utils/fetchApi';
import moment from 'moment';
import semver from 'semver';

const POLL_INTERVAL_MINUTES = 5;

const delay = millis =>
  new Promise(resolve => {
    setTimeout(() => resolve(true), millis);
  });

// regularly start data fetch
function* pollData() {
  try {
    const login = yield select(state => state.login);
    const { autoRefresh, authToken, companyId } = login;

    const oldData = yield select(state => state.pollNewData);
    const companySelector = yield select(state => state.companySelector);
    const superAdminCompanySelected = (companySelector || {}).companyId;

    const operationDateJson = yield call(fetchApi, `/Sites/operationDate`, {
      authToken,
      method: 'GET'
    });

    if (!operationDateJson.error) {
      if (operationDateJson.result.exists) {
        if (!oldData['sites'].date) {
          // setting initial date
          yield put({
            type: NEW_DATA_AVAILABLE,
            category: 'sites',
            date: operationDateJson.result.date,
            isReadyToRefresh: false
          });
        } else {
            // updating following dates
            const newDate = moment(operationDateJson.result.date).tz('UTC');
            const oldDate = moment(oldData['sites'].date).tz('UTC');
            if (newDate.isAfter(oldDate)) {
              yield put({
                type: NEW_DATA_AVAILABLE,
                category: 'sites',
                date: operationDateJson.result.date,
                isReadyToRefresh: !autoRefresh
              });

              if(autoRefresh){
                yield put({
                  type: DATA_SUBMIT,
                  companyId: companyId || superAdminCompanySelected
                });
              }
            }

        }

      } else {
        log.info('pollData error: no date existant');
      }
    } else {
      log.error('pollData api error:', JSON.stringify(operationDateJson.error));
    }
  } catch (error) {
    log.error('pollData error', error);
    return;
  }
}

const fetchVersion = () => {
  return fetch('/version.txt')
    .then(response => response.text())
    .then(text => text.trim());
};

function* pollCodeVersion() {
  try {
    const oldData = yield select(state => state.pollNewData);

    const newVersion = yield call(fetchVersion);
    log.debug(
      'Server code version',
      newVersion,
      'vs local',
      process.env.REACT_APP_VERSION
    );
    // distinguish between old version in redux (i.e. not the initial check)
    // and otherwise, so we know whether to show the alert (user might have chosen
    // not to want to see it)
    if (
      semver.gt(newVersion, process.env.REACT_APP_VERSION) &&
      newVersion !== oldData['code'].version
    )
      yield put({
        type: NEW_DATA_AVAILABLE,
        category: 'code',
        version: newVersion,
        isReadyToRefresh: true
      });
  } catch (error) {
    log.error('pollCodeVersion error', error);
    return;
  }
}

function* watchPollData() {
  while (true) {
    // fetch live/static data mode status
    yield put ({type: SERVER_FEATURES_SUBMIT});

    yield race([call(pollData), take(LOGOUT_SUBMIT)]);
    yield call(delay, POLL_INTERVAL_MINUTES * 60 * 1000);
  }
}
function* watchPollCode() {
  while (true) {
    yield race([call(pollCodeVersion), take(LOGOUT_SUBMIT)]);
    yield call(delay, POLL_INTERVAL_MINUTES * 60 * 1000);
  }
}

// Wait for successful response, then fire another request
// Cancel polling if user logs out
export default function* watchPoll() {
  while (true) {
    // wait until Login action arrives and start tasks
    yield race([take(LOGIN_SUCCESS), take(DATA_SUCCESS)]);
    log.debug('Starting new data polling tasks')
    const datatask = yield spawn(watchPollData);
    const codetask = yield spawn(watchPollCode);

    // wait until Logout action arrives and cancel tasks
    yield take(LOGOUT_SUBMIT);
    yield cancel(codetask);
    yield cancel(datatask);
  }
}
