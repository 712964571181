import React from "react";
import PropTypes from "prop-types";
import "common/css/inputElements.css";

const SiteSelectorDropdown = props => (
  <div>
    <div className="WA__Subhead">Search sites by</div>
    <div className="SiteSel__select__by--outer">
      <select
        className="wwa__select"
        style={{ margin: "0px auto 12px 0px" }}
        onChange={props.handleSelectChange}
        value={props.selectSiteBy}
      >
        {props.optionArray.map(opt => (
          <option
            id="sselopt"
            className="SiteSel__option"
            key={opt}
            value={opt}
          >
            {opt}
          </option>
        ))}
      </select>
    </div>
  </div>
);

export const SiteSelectorOrderDropdown = props => (
  <div>
    <div className="WA__Subhead">Order sites by</div>
    <div className="SiteSel__select__by--outer">
      <select
        className="wwa__select"
        style={{ margin: "0px auto 12px 0px" }}
        onChange={props.handleSelectChange}
        value={props.selectSiteBy}
      >
        {Object.keys(props.optionMap).map(opt => (
          <option
            id="sselopt"
            className="SiteSel__option"
            key={opt}
            value={opt}
          >
            {props.optionMap[opt]}
          </option>
        ))}
      </select>
    </div>
  </div>
);

SiteSelectorDropdown.propTypes = {
  handleSelectChange: PropTypes.func.isRequired,
  selectSiteBy: PropTypes.string.isRequired,
  optionArray: PropTypes.array.isRequired
};
export default SiteSelectorDropdown;
