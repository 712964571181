import log from "loglevel";
import {
  put,
  take,
  takeEvery,
  call,
  select,
  race,
  spawn,
  cancel
} from "redux-saga/effects";
import addDays from "date-fns/add_days";
import { LOGOUT_SUBMIT, LOGIN_SUCCESS } from "Login/LoginDuck";
import fetchApi from "utils/fetchApi";
import {
  CTADATA_SUBMIT,
  CTADATA_SUCCESS,
  CTADATA_FAILURE,
  CTADATA_REFRESH,
  CTADATA_START_FETCH
} from "./CallToActionDuck";

const POLL_INTERVAL_MINUTES = 5;
const CUTOFF_INTERVAL_DAYS = -7;

const delay = millis =>
  new Promise(resolve => {
    setTimeout(() => resolve(true), millis);
  });

function* fetchCtaCall({ companyId }) {
  try {
    if (!companyId) {
      // likely logged in as super admin and no company selected
      return;
    }
    const authToken = yield select(state => state.login.authToken);
    const url = `/Companies/${companyId}/ctaNotifications`;
    const limitFilterNotifications = {
      where: {createdAt: {gte: addDays(new Date(), CUTOFF_INTERVAL_DAYS)}},
      order: 'dateTimeISO DESC',
    }
    yield put({type: CTADATA_START_FETCH});
    const ctaParsedJson = yield call(fetchApi, url, {
      authToken,
      method: "GET",
      query: {
        filter: JSON.stringify(limitFilterNotifications)
      }
    });

    const limitFilterSiteNotifications = {
      where: {observationTime: {gte: addDays(new Date(), CUTOFF_INTERVAL_DAYS)}},
      order: 'dateTimeISO DESC',
    }
    const urlSN = `/Companies/${companyId}/ctaSiteNotifications`;
    const ctaSiteNotificationParsedJson = yield call(fetchApi, urlSN, {
      authToken,
      method: "GET",
      query: {
        filter: JSON.stringify(limitFilterSiteNotifications)
      }
    });

    if (!ctaParsedJson.error && !ctaSiteNotificationParsedJson.error) {
      yield put({
        type: CTADATA_SUCCESS,
        payload: ctaParsedJson,
        payloadSiteNotification: ctaSiteNotificationParsedJson,
      });
      yield put ({type: CTADATA_REFRESH, date: new Date()});
    } else {
      if(ctaParsedJson.error){
        log.warn(
          ctaParsedJson.error.text._error
            ? ctaParsedJson.error.text._error
            : ctaParsedJson.error.text
        );
        yield put({
          type: CTADATA_FAILURE,
          error: ctaParsedJson.error.text._error
            ? ctaParsedJson.error.text._error
            : ctaParsedJson.error.text
        });
      }
      if(ctaSiteNotificationParsedJson.error){
        log.warn(
          ctaSiteNotificationParsedJson.error.text._error
            ? ctaSiteNotificationParsedJson.error.text._error
            : ctaSiteNotificationParsedJson.error.text
        );
        yield put({
          type: CTADATA_FAILURE,
          error: ctaSiteNotificationParsedJson.error.text._error
            ? ctaSiteNotificationParsedJson.error.text._error
            : ctaSiteNotificationParsedJson.error.text
        });
      }
    }
  } catch (error) {
    log.error("fetchCtaCall", error);
  }
}

function* doRefresh() {
  while (true) {
    let companyId = yield select(state => state.companySelector.companyId);
    if (!companyId) companyId = yield select(state => state.login.companyId);
    yield race([call(fetchCtaCall, {companyId}), take(LOGOUT_SUBMIT)]);
    yield call(delay, POLL_INTERVAL_MINUTES * 60 * 1000);
    yield put ({type: CTADATA_REFRESH, date: new Date()});
  }
}

export default function* ctaSagas() {
  yield takeEvery(CTADATA_SUBMIT, fetchCtaCall);

  while (true) {
    // wait until watch start action arrives and start task
    yield race([take(LOGIN_SUCCESS), take(CTADATA_SUCCESS)]);
    log.debug('Starting new CTA refresh task')
    const refreshTask = yield spawn(doRefresh);

    // wait until Logout action arrives and cancel task
    yield take(LOGOUT_SUBMIT);
    yield cancel(refreshTask);
  }
}
