import log from 'loglevel';
import { put, takeEvery, call, select } from 'redux-saga/effects';
import fetchApi from 'utils/fetchApi.js';
import {
  APISTATS_SUBMIT,
  APISTATS_SUCCESS,
  APISTATS_FAILURE
} from './ApiStatsDuck';
import format from 'date-fns/format';
import addHours from 'date-fns/add_hours';
import addDays from 'date-fns/add_days';
import compareAsc from 'date-fns/compare_asc'
import differenceInDays from 'date-fns/difference_in_days';

function* fetchApiStats({ companyId }) {
  try{
    const url = `/Companies/${companyId}/apiLogs`;
    const authToken = yield select(state => state.login.authToken);
    const parsedJson = yield call(fetchApi, url, { method: 'GET', authToken });

    if (!parsedJson.error) {
      parsedJson.sort((a,b) => compareAsc(a.start, b.start))
      yield put({ type: APISTATS_SUCCESS, data: parsedJson });
    } else {
      yield put({
        type: APISTATS_FAILURE,
        message: parsedJson.error.text._error
          ? parsedJson.error.text._error
          : parsedJson.error.text
      });
    }
  } catch (error) {
    log.error('fetchApiStatsSaga', error);
    yield put({
      type: APISTATS_FAILURE,
      message: 'Network error.'
    })
  }
}

/*
const data = [
  {
    id: 'abcde',
    start: '2018-07-18T14:00:00Z',
    total: 90,
    apiAppTotals: {
      '5b49e92a7e08c52d3a82470d': 72,
      '5b4ce9e8b5d4fc5d2e8fbc3d': 18,
    },
    hours: [
      { 
        start: '2018-07-18T14:00:00Z',
        total: 10,
        apiAppTotals: {
          '5b49e92a7e08c52d3a82470d': 1,
          '5b4ce9e8b5d4fc5d2e8fbc3d': 9,
        }
      },
      { 
        start: '2018-07-18T15:00:00Z',
        total: 25,
        apiAppTotals: {
          '5b49e92a7e08c52d3a82470d': 20,
          '5b4ce9e8b5d4fc5d2e8fbc3d': 5,
        }
      },
      { 
        start: '2018-07-18T16:00:00Z',
        total: 15,
        apiAppTotals: {
          '5b49e92a7e08c52d3a82470d': 12,
          '5b4ce9e8b5d4fc5d2e8fbc3d': 3,
        }
      },
      { 
        start: '2018-07-18T17:00:00Z',
        total: 40,
        apiAppTotals: {
          '5b49e92a7e08c52d3a82470d': 31,
          '5b4ce9e8b5d4fc5d2e8fbc3d': 9,
        }
      },
    ],
    companyId: '5a0087e4b0a7af2f858f009b'
  }
]
*/

function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}
const generateData = (companyId, apiApps, dayStart, days) => { 
  let data = []
  const apiAppTotals = {}
  apiApps.forEach(a => apiAppTotals[a.id] = Math.round(Math.random()*100000))

  for (let day = 0; day <= days; day++){
    const dayData = {
      id: guid(),
      start: addDays(dayStart, day).toString(),
      apiAppTotals,
      companyId,
      hours: generateHours(apiApps, addDays(dayStart, day))
    }

    dayData.total = dayData.hours.reduce((acc,h) => (Number(acc) + Number(h.total)), 0)
    data.push(dayData)
  }
  return data
};

const generateHours = (apiApps, day) => {
  let hours = []
  for (let i=0; i <= 24; i++){
    const start = format(addHours(day, i))
    const total = Math.round(Math.random()*1000)
    const apiAppTotals = {}
    let remainingTotal = total
    apiApps.forEach(a => {
      apiAppTotals[a.id] = remainingTotal - Math.round(Math.random()*remainingTotal)
      remainingTotal -= apiAppTotals[a.id]
    })

    hours.push(
      { 
        start,
        total,
        apiAppTotals
      }
    )
  }
  return hours
}


function* fetchApiStatsDummy({ companyId }) {
  try {
    const apiApps = yield select(state => state.apiApps.data);
    const companyId = yield select(state => state.login.companyId);
    const startDate = '2018-07-18T00:00:00Z'
    const data = generateData(companyId, apiApps, startDate, differenceInDays(new Date(), startDate));
    const nodata = [];
    yield put({ type: APISTATS_SUCCESS, data: Object.keys(apiApps).length ? data : nodata });
  } catch (error) {
    log.error('fetchApiPackagesSaga', error);
  }
}

export default function* apiStatsSaga() {
  yield takeEvery(APISTATS_SUBMIT, fetchApiStats);
  // use this takeEvery for APISTATS_SUBMIT if dummy data instead of real data is wanted:
  yield takeEvery("APISTATS_SUBMIT_IGNOREACTION", fetchApiStatsDummy);
}
