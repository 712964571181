
export const REPORTS_EXECUTE_SUBMIT = 'REPORTS_EXECUTE_SUBMIT';
export const REPORTS_EXECUTE_SUCCESS = 'REPORTS_EXECUTE_SUCCESS';
export const REPORTS_EXECUTE_FAILURE = 'REPORTS_EXECUTE_FAILURE';
export const REPORTS_EXECUTE_SET_COMPANY_ID = 'REPORTS_EXECUTE_SET_COMPANY_ID'

export const executeReport = (companyId, reportTypes, targetUserType, personIds, thirdPartyUserIds) => ({
  type: REPORTS_EXECUTE_SUBMIT,
  companyId,
  reportTypes,
  targetUserType,
  personIds,
  thirdPartyUserIds
})

export const executeResendReport = ( operationHour, overrideMailDrop ) => ({
  type: REPORTS_EXECUTE_SUBMIT,
  operationHour,
  overrideMailDrop
});

export const setSelectedCompany = (companyId) => ({
  type: REPORTS_EXECUTE_SET_COMPANY_ID,
  companyId
})

const initialState = {
  companyId: '',
  isFetching: false,
  error: '',
  success: undefined
}

const reportsExecute = (state = initialState, action) => {
  switch (action.type) {

    case REPORTS_EXECUTE_SET_COMPANY_ID:
      return {
        ...state,
        companyId: action.companyId
      }

    case REPORTS_EXECUTE_SUBMIT:
      return {
        ...state,
        isFetching: true,
        error: '',
        success: undefined
      }

    case REPORTS_EXECUTE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        success: true
      }

    case REPORTS_EXECUTE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.message,
        success: false
      }

    default:
      return state

  }
}

export default reportsExecute;
