// This file implements the "ducks" pattern, i.e. combines action constants,
// action creators and reducers in one file instead of having multiple files per
// function. As long as there are not too many values, this makes it nice and
// compact.v

// Action constants

export const LOCATIONSELECTOR_SET_FEDSTATE = 'LOCATIONSELECTOR_SET_FEDSTATE';
export const LOCATIONSELECTOR_SET_CITY = 'LOCATIONSELECTOR_SET_CITY';
export const LOCATIONSELECTOR_SET_ZIPCODE = 'LOCATIONSELECTOR_SET_ZIPCODE';
export const LOCATIONSELECTOR_RESET = 'LOCATIONSELECTOR_RESET';
export const LOCATIONSELECTOR_SET_RADIO = 'LOCATIONSELECTOR_SET_RADIO';
export const LOCATIONSELECTOR_SET_VALUES = 'LOCATIONSELECTOR_SET_VALUES';

//
// Reducer
//

export const NONE = 'NONE';
export const FEDSTATE = 'FEDSTATE';
export const CITY = 'CITY';
export const ZIPCODE = 'ZIPCODE';

const initialState = {
};

// Action creators

export const selectRadio = (groupId, name) => ({
  type: LOCATIONSELECTOR_SET_RADIO,
  groupId, name
})

export const setFedState = (groupId, fedState, city) => ({
  type: LOCATIONSELECTOR_SET_FEDSTATE,
  groupId, fedState, city
})

export const setCity = (groupId, city) => ({
  type: LOCATIONSELECTOR_SET_CITY,
  groupId, city
})

export const setZipCode = (groupId, zipCode) => ({
  type: LOCATIONSELECTOR_SET_ZIPCODE,
  groupId, zipCode
})

export const resetLocation = (groupId) => ({
  type: LOCATIONSELECTOR_RESET,
  groupId
})

// Reducer

const updateFedState = (slice= {}, {fedState, city}) => {
  const newState = fedState ? (fedState[0] !== '-' ? fedState : undefined) : slice.fedState;
  let newCity = city ? (city[0] !== '-' ? city : undefined) : slice.city;
  // city can only be defined if state defined:
  if (!newState) newCity = undefined;

  return {
    ...slice,
    stateOrZipCode: FEDSTATE,
    fedState: newState,
    city: newCity
  }
}

const updateZipCode = (slice= {}, {zipCode}) => {
  // if zipCode is empty string or only white space, set it to undefined
  let newZip = zipCode.trim();
  newZip = newZip || undefined;
  return {
    ...slice,
    stateOrZipCode: ZIPCODE,
    zipCode: newZip
  }
}

const updateRadio = (slice= {}, {name}) => ({
  ...slice,
  radio: name
})

const locationSelectorReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOCATIONSELECTOR_RESET:
      return {
        ...state,
        [action.groupId]: {stateOrZipCode: NONE, radio: undefined}
      }

    case LOCATIONSELECTOR_SET_FEDSTATE:
      return {
        ...state,
        [action.groupId]: updateFedState(state[action.groupId], action)
      }
    case LOCATIONSELECTOR_SET_CITY:
      return {
        ...state,
        [action.groupId]: updateFedState(state[action.groupId], action)
      }
    case LOCATIONSELECTOR_SET_ZIPCODE:
      return {
        ...state,
        [action.groupId]: updateZipCode(state[action.groupId], action)
      }
    case LOCATIONSELECTOR_SET_RADIO:
      return {
        ...state,
        [action.groupId]: updateRadio(state[action.groupId], action)
      }
    case LOCATIONSELECTOR_SET_VALUES:
      return {
        ...state,
        [action.groupId]: action.values
      }

    default:
      return state

  }
}

export default locationSelectorReducer;


