import log from 'loglevel';
import { call, put, select, takeEvery } from 'redux-saga/effects'
import {
  APIPACKAGEUPSERT_FAILURE,
  APIPACKAGEUPSERT_SUBMIT,
  APIPACKAGEUPSERT_SUCCESS, APIPACKAGEDEL_FAILURE, APIPACKAGEDEL_SUBMIT, APIPACKAGEDEL_SUCCESS,
  APIPACKAGES_BYID_FAILURE,
  APIPACKAGES_BYID_SUBMIT,
  APIPACKAGES_BYID_SUCCESS,
  APIPACKAGES_FAILURE,
  APIPACKAGES_SUBMIT,
  APIPACKAGES_SUCCESS,
} from './ApiPackageDuck'
import fetchApi from 'utils/fetchApi'

function* fetchApiPackagesSaga() {
  try {
    const url = '/ApiPackages';
    const authToken = yield select((state) => state.login.authToken);
    const parsedJson = yield call(fetchApi, url, {method: 'GET', authToken})

    if (!parsedJson.error) {
      yield put({type: APIPACKAGES_SUCCESS, data: parsedJson})
    } else {
      yield put({
        type: APIPACKAGES_FAILURE,
        message: parsedJson.error.text,
      })
    }
  } catch (error) {
    log.error('fetchApiPackagesSaga', error)
  }
}

function* fetchCompaniesByApiPackageIdSaga(action) {
  try {
    const url = '/ApiSubscriptions';
    const authToken = yield select((state) => state.login.authToken);

    const parsedJson = yield call(fetchApi, url, {
      method: 'GET',
      authToken,
      query: {
        filter: JSON.stringify({
          where: {
            apiPackageId: action.apiPackageId
          }
        })
      }
    })

    if (!parsedJson.error) {
      yield put({
        type: APIPACKAGES_BYID_SUCCESS,
        apiPackageId: action.apiPackageId,
        companyIds: parsedJson.map(s => s.companyId)
      })
    } else {
      yield put({
        type: APIPACKAGES_BYID_FAILURE,
        message: parsedJson.error.text,
      })
    }
  } catch (error) {
    log.error('fetchCompaniesByApiPackageIdSaga', error)
  }
}

function* submitApiPackage(action) {
  try {
    const id = action.payload.id;
    const updateMode = id;
    const url = updateMode ? `/ApiPackages/${id}` : '/ApiPackages';
    const authToken = yield select((state) => state.login.authToken);
    const payload = action.payload;
    const parsedJson = yield call(fetchApi, url, {
      authToken,
      payload,
      method: updateMode ? 'PUT' : 'POST'
    })

    if (!parsedJson.error) {
      yield put({
        type: APIPACKAGEUPSERT_SUCCESS,
        data: parsedJson
      })
      yield put({
        type: '@@redux-form/RESET',
        meta: {'form': 'UpsertApiPackageForm'}
      })

    } else {
      yield put({
        type: APIPACKAGEUPSERT_FAILURE,
        message: parsedJson.error.text._error ? parsedJson.error.text._error : parsedJson.error.text,
        payload: parsedJson.error.text._error ? parsedJson.error.text._error : parsedJson.error.text,
      })
    }
  } catch (error) {
    log.error('submitApiPackage', error)
  }
}

function* deleteApiPackage(action) {
  try {
    const authToken = yield select((state) => state.login.authToken);
    const id = action.id;
    const url = `/ApiPackages/${id}`;
    const parsedJson = yield call(fetchApi, url, {authToken, method: 'DELETE'})

    if (!parsedJson.error) {
      yield put({
        type: APIPACKAGEDEL_SUCCESS,
        id
      })
    } else {
      yield put({
        type: APIPACKAGEDEL_FAILURE,
        message: parsedJson.error.text._error ? parsedJson.error.text._error : parsedJson.error.text,
        payload: parsedJson.error.text
      })
    }
  } catch (error) {
    log.error('deleteApiPackage', error)
  }
}

// listen for actions of type ApiPackages_SUBMIT and use them
export default function* apiPackagesSaga() {
  yield takeEvery(APIPACKAGES_SUBMIT, fetchApiPackagesSaga)
  yield takeEvery(APIPACKAGES_BYID_SUBMIT, fetchCompaniesByApiPackageIdSaga)
  yield takeEvery(APIPACKAGEUPSERT_SUBMIT, submitApiPackage)
  yield takeEvery(APIPACKAGEDEL_SUBMIT, deleteApiPackage)
}

