export const SITEEDIT_OPEN = 'SITEEDIT_OPEN';
export const SITEEDIT_CLOSE = 'SITEEDIT_CLOSE';
export const SITEEDIT_SUBMIT = 'SITEEDIT_SUBMIT';
export const SITEEDIT_SUCCESS = 'SITEEDIT_SUCCESS';
export const SITEEDIT_FAILURE = 'SITEEDIT_FAILURE';
export const SITESUBSCRIPTION_SUBMIT = 'SITESUBSCRIPTION_SUBMIT';
export const SITESUBSCRIPTION_SUCCESS = 'SITESUBSCRIPTION_SUCCESS';
export const SITESUBSCRIPTION_FAILURE = 'SITESUBSCRIPTION_FAILURE';
export const SITEDELETE_SUBMIT = 'SITEDELETE_SUBMIT'
export const SITEDELETE_SUCCESS = 'SITEDELETE_SUCCESS'
export const SITEDELETE_FAILURE = 'SITEDELETE_FAILURE'

export const closeEditModal = () => ({
  type: SITEEDIT_CLOSE
})

export const openEditModal = () => ({
  type: SITEEDIT_OPEN
})

export const deleteSite = (id) => ({
  type: SITEDELETE_SUBMIT,
  id
})

export const submitSubscriptions = (siteId, subscriptionId) => ({
  type: SITESUBSCRIPTION_SUBMIT,
  siteId,
  subscriptionId
})

export const setSiteTypes = (companyId, siteId, types) => ({
  type: SITEEDIT_SUBMIT,
  payload: {companyId, id: siteId, types, typeChangeOnly: true}
});

const initialState = {
  showModal: false,
  isFetching: false
}

const siteEditReducer = (state = initialState, action) => {
  switch (action.type) {
    case SITEEDIT_OPEN:
      return {
        ...state,
        showModal: true
      }
    case SITEEDIT_CLOSE:
      return initialState;

    case SITEEDIT_SUBMIT:
      return {
        ...state,
        isFetching: true
      }
    case SITEEDIT_SUCCESS:
      return {
        ...state,
        isFetching: false
      }
    case SITEEDIT_FAILURE:
      return {
        ...state,
        isFetching: false
      }

    case SITEDELETE_SUBMIT:
      return {
        ...state,
        isFetching: true
      };

    case SITEDELETE_SUCCESS:
      return {
        ...state,
        isFetching: false
      };

    case SITEDELETE_FAILURE:
      return {
        ...state,
        isFetching: false
      };

    default:
      return state

  }
}

export default siteEditReducer;
