import imm from "object-path-immutable";
// import log from "loglevel";

export const THREATFILTER_SHOW_INFO_POPUP = "THREATFILTER_SHOW_INFO_POPUP";

const keys = {
  threatFilter: THREATFILTER_SHOW_INFO_POPUP
};

export const onboardingStep = ({ key, step, ...rest }) => ({
  type: keys[key],
  key,
  step,
  ...rest
});

/*
 * state transitions are of the form:
 * state + step => newStep
 */
const calcNewInfoStep = (action, state) => {
  let newStep = 0;
  // log.debug('action=', action);
  // log.debug('state=', state);
  switch (state[action.key]) {
    // 0: initial state, all popups hidden
    case 0:
      if (action.step === 1) {
        newStep = 1;
      } else {
        newStep = 0;
      }
      break;

    // 1: load custom threat filters in background
    case 1:
      // only show the first onboarding popup if user hasn't saved any custom filters
      if (action.step === 2 && !action.hasCustomFilters) {
        newStep = 2;
      } else {
        newStep = 0;
      }
      break;

    // 2: show popup over threat filter icon
    case 2:
      if (action.step === 20) { // hide first popup
        newStep = 20;
      } else if (action.step === 3) { // second popup
        newStep = 3;
      } else if (action.step === 30) {
        // hide second popup, "Choose a preset" clicked, but it might have to be shown
        // later if another preset was saved
        newStep = 30;
      } else {
        newStep = 0;
      }
      break;

    // 3: show popup over "Presets" link
    case 3:
      if (action.step === 20) { // hide first popup
        newStep = 20;
      } else if (action.step === 3) { // second popup
        newStep = 3;
      } else if (action.step === 30) { 
        // hide second popup and don't show it again
        newStep = 0;
      } else {
        newStep = 0;
      }
      break;

    // 20: hide popup over threat filter icon on opening filter view
    // 30: hide popup over "Presets" link
    case 20:
    case 30:
      newStep = action.step;
      break;

    default:
      newStep = 0; // invalid state transition
  }

  // log.debug('newStep=', newStep);
  return newStep;
};

const initialState = {
  threatFilter: 0
};

const onboarding = (state = initialState, action) => {
  switch (action.type) {
    case THREATFILTER_SHOW_INFO_POPUP:
      return imm.set(state, action.key, calcNewInfoStep(action, state));

    default:
      return state;
  }
};
export default onboarding;
