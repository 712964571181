/**
 * @description
 * Extend loglevel with new plugin which will send log information to the log-sever
 *
 * @param {object} logger loglevel instance to be extended
 * @param {object} options
 * @param {string} [options.url='http://localhost:8000/main/log'] Server url which would be used as a log server
 * @param {string|Function} [options.prefix=null] Prefix for all log messages. Either string or function wich should return string and accept log severity and message as parameters
 * @param {Bool} [options.callOriginal=false] If set to true - original loglevel method for logging would be called
 * @example
 * loglevelServerSend(log,{url:'https://example.com/app/log',prefix: function(logSev,message) {
 *     return '[' + new Date().toISOString() + '] ' + logSev + ': ' + message + '\n'
 * }})
 */
var loglevelServerSend = function(logger, options) {
  if (!logger || !logger.methodFactory)
    throw new Error(
      "loglevel instance has to be specified in order to be extended"
    );

  var _logger = logger,
    _url = (options && options.url) || "http://localhost:8000/main/log",
    _callOriginal = (options && options.callOriginal) || false,
    _prefix = options && options.prefix,
    _originalFactory = _logger.methodFactory,
    _sendQueue = [];

  _logger.methodFactory = function(methodName, logLevel, loggerName) {
    var rawMethod = _originalFactory(methodName, logLevel, loggerName);

    return function() {
      var messages = [];
      for (var i = 0; i < arguments.length; i++) {
        messages.push(arguments[i]);
      }

      let message = messages.join(' ');
      if (typeof _prefix === "string") message = _prefix + message;
      else if (typeof _prefix === "function")
        message = _prefix(methodName, message);
      else message = methodName + ": " + message;

      if (_callOriginal) 
        rawMethod.apply(undefined, messages);

      _sendQueue.push(message);
      _sendNextMessage();
    };
  };
  _logger.setLevel(_logger.getLevel()); // Be sure to call setLevel method in order to apply plugin

  var _sendNextMessage = function() {
    const headers = new Headers();
    headers.append("Content-Type", "text/plain");

    if (!_sendQueue.length) return;

    try {
      fetch(_url, {
        method: "POST",
        body: _sendQueue.shift()
      })
        .then(
          response =>
            response.ok ||
            console.error(
              "loglevel-serverSend Error:",
              response.status,
              response.statusTest
            )
        )
        .catch(e => {
          console.error("loglevel-sendServer caught error in fetch:", e);
        });
    } catch (err) {
      console.error("loglevel-sendServer caught error:", err);
    }
  };
};

export default loglevelServerSend;
