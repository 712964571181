import React from "react";
import PropTypes from "prop-types";
import { WwaCheckbox } from "common/components/WwaInputElements";

import "./ThreatTypeSelector.scss";

const ThreatCheckBox = ({ id, checked, label, onClick }) => (
  <div className="wwa_checkbox_and_label">
    <WwaCheckbox id={id} name={id} onChange={onClick} checked={checked} />
    <span className="wwa_checkbox_label">{label}</span>
  </div>
);

const WinterThreatTypeCheckboxes = ({ threats, allSelected, onClick }) => (
  <div>
    <div className="ThreatFilter_Subhead">Threat type</div>

    <div className="ThreatTypeSelector_ThreatTypeCheckboxes">
      <span className="wwa_checkbox_and_label ThreatTypeSelector_all">
        <WwaCheckbox
          id="cb_selectall"
          name="SelectAll"
          onChange={onClick}
          checked={allSelected}
        />
        <span className="wwa_checkbox_label">Select All</span>
      </span>
      <div className="ThreatTypeSelector_left">
        {" "}
        {/*left*/}
        <ThreatCheckBox
          id="IC"
          label="Ice Crystals (IC)"
          checked={threats.IC}
          onClick={onClick}
        />
        <ThreatCheckBox
          id="IF"
          label="Ice Fog (IF)"
          checked={threats.IF}
          onClick={onClick}
        />
        <ThreatCheckBox
          id="IP"
          label="Ice Pellets (IP)"
          checked={threats.IP}
          onClick={onClick}
        />
        <ThreatCheckBox
          id="S"
          label="Snow (S)"
          checked={threats.S}
          onClick={onClick}
        />
        <ThreatCheckBox
          id="SW"
          label="Snow Showers (SW)"
          checked={threats.SW}
          onClick={onClick}
        />
        <ThreatCheckBox
          id="ZF"
          label="Freezing Fog (ZF)"
          checked={threats.ZF}
          onClick={onClick}
        />
        <ThreatCheckBox
          id="ZL"
          label="Freezing Drizzle (ZL)"
          checked={threats.ZL}
          onClick={onClick}
        />
      </div>{" "}
      {/*left*/}
      <div className="ThreatTypeSelector_right">
        {" "}
        {/*right*/}
        <ThreatCheckBox
          id="RS"
          label="Rain/Snow Mix (RS)"
          checked={threats.RS}
          onClick={onClick}
        />
        <ThreatCheckBox
          id="SI"
          label="Snow/Sleet Mix (SI)"
          checked={threats.SI}
          onClick={onClick}
        />
        <ThreatCheckBox
          id="WM"
          label="Wintry Mix (WM)"
          checked={threats.WM}
          onClick={onClick}
        />
        <ThreatCheckBox
          id="ZR"
          label="Freezing Rain (ZR)"
          checked={threats.ZR}
          onClick={onClick}
        />
        <ThreatCheckBox
          id="BS"
          label="Blowing Snow (BS)"
          checked={threats.BS}
          onClick={onClick}
        />
        <ThreatCheckBox
          id="ZY"
          label="Freezing Spray (ZY)"
          checked={threats.ZY}
          onClick={onClick}
        />
      </div>
      {/*right*/}
    </div>
  </div>
);

const NonWinterThreatTypeCheckboxes = ({ threats, allSelected, onClick }) => (
  <div>
    <div className="ThreatFilter_Subhead">Threat type</div>

    <div className="ThreatTypeSelector_ThreatTypeCheckboxes">
      <span className="wwa_checkbox_and_label ThreatTypeSelector_all">
        <WwaCheckbox
          id="cb_selectall"
          name="SelectAll"
          onChange={onClick}
          checked={allSelected}
        />
        <span className="wwa_checkbox_label">Select All</span>
      </span>
      <div className="ThreatTypeSelector_left">
        <ThreatCheckBox
          id="R"
          label="Rain"
          checked={threats.R}
          onClick={onClick}
        />
        <ThreatCheckBox
          id="RW"
          label="Rain Showers"
          checked={threats.RW}
          onClick={onClick}
        />
        <ThreatCheckBox
          id="RS"
          label="Rain/Snow Mix (RS)"
          checked={threats.RS}
          onClick={onClick}
        />
        <ThreatCheckBox
          id="F"
          label="Fog"
          checked={threats.F}
          onClick={onClick}
        />
      </div>{" "}
      {/*left*/}
      <div className="ThreatTypeSelector_right">
        <ThreatCheckBox
          id="H"
          label="Hail"
          checked={threats.H}
          onClick={onClick}
        />
        <ThreatCheckBox
          id="T"
          label="Thunderstorms"
          checked={threats.T}
          onClick={onClick}
        />
        <ThreatCheckBox
          id="WM"
          label="Wintry Mix (WM)"
          checked={threats.WM}
          onClick={onClick}
        />
      </div>
    </div>
  </div>
);

class ThreatTypeSelector extends React.Component {
  constructor(props){
    super(props);
    const { initialValues, selectThreat, resetCheckboxes, selectAllThreats } = props;
    if(initialValues){
      resetCheckboxes();
      selectAllThreats(false);
      Object.keys(initialValues).forEach(threat => {
        if(initialValues[threat]) selectThreat(threat, initialValues[threat])
      })
    }
  }

  handleChangeCheckbox = e => {
    const id = e.currentTarget.id;
    const checked = e.currentTarget.checked;
    switch (id) {
      case "cb_selectall":
        this.props.selectAllThreats(checked);
        break;
      default:
        this.props.selectThreat(id, checked);
    }
  };

  render() {
    const { threatTypes, allSelected, weatherType } = this.props;
    return weatherType === "nonwinter" ? (
      <NonWinterThreatTypeCheckboxes
        onClick={this.handleChangeCheckbox}
        threats={threatTypes}
        allSelected={allSelected}
      />
    ) : (
      <WinterThreatTypeCheckboxes
        onClick={this.handleChangeCheckbox}
        threats={threatTypes}
        allSelected={allSelected}
      />
    );
  }
}

ThreatTypeSelector.propTypes = {
  threatTypes: PropTypes.object,
  allSelected: PropTypes.bool
};

export default ThreatTypeSelector;
