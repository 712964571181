export const COMPANYEDIT_OPEN = 'COMPANYEDIT_OPEN';
export const COMPANYEDIT_CLOSE = 'COMPANYEDIT_CLOSE';
export const COMPANYEDIT_SUBMIT = 'COMPANYEDIT_SUBMIT';
export const COMPANYEDIT_SUCCESS = 'COMPANYEDIT_SUCCESS';
export const COMPANYEDIT_FAILURE = 'COMPANYEDIT_FAILURE';
export const COMPANYEDIT_SET_PAGE = 'COMPANYEDIT_SET_PAGE';
export const COMPANYDELETE_SUBMIT = 'COMPANYDELETE_SUBMIT';
export const COMPANYDELETE_SUCCESS = 'COMPANYDELETE_SUCCESS';
export const COMPANYDELETE_FAILURE = 'COMPANYDELETE_FAILURE';
export const COMPANYDELETE_RESET = 'COMPANYDELETE_RESET';
export const COMPANYSUBSCRIPTION_SUBMIT = 'COMPANYSUBSCRIPTION_SUBMIT';
export const COMPANYSUBSCRIPTION_SUCCESS = 'COMPANYSUBSCRIPTION_SUCCESS';
export const COMPANYSUBSCRIPTION_FAILURE = 'COMPANYSUBSCRIPTION_FAILURE';
export const COMPANYSUBSCRIPTION_NOTFOUND = 'COMPANYSUBSCRIPTION_NOTFOUND';
export const COMPANYROLLUPREPORTSORTCOLUMN_SUBMIT = 'COMPANYROLLUPREPORTSORTCOLUMN_SUBMIT';
export const COMPANYROLLUPREPORTSORTCOLUMN_SUCCESS = 'COMPANYROLLUPREPORTSORTCOLUMN_SUCCESS';
export const COMPANYROLLUPREPORTSORTCOLUMN_FAILURE = 'COMPANYROLLUPREPORTSORTCOLUMN_FAILURE';
export const COMPANY_SHOW_LOGO_SUBMIT = 'COMPANY_SHOW_LOGO_SUBMIT';
export const COMPANY_SHOW_LOGO_SUCCESS = 'COMPANY_SHOW_LOGO_SUCCESS';
export const COMPANY_SHOW_LOGO_FAILURE = 'COMPANY_SHOW_LOGO_FAILURE';

export const closeEditModal = () => ({
  type: COMPANYEDIT_CLOSE
})

export const openEditModal = () => ({
  type: COMPANYEDIT_OPEN
})

export const setPage = (page) => ({
  type: COMPANYEDIT_SET_PAGE,
  page
})

export const deleteCompany = companyId => ({
  type: COMPANYDELETE_SUBMIT,
  companyId
});

export const deleteCompanyReset = () => ({
  type: COMPANYDELETE_RESET
});

export const submitSubscriptions = (companyId, subscriptionId) => ({
  type: COMPANYSUBSCRIPTION_SUBMIT,
  companyId,
  subscriptionId
})

export const submitCompanyRollupReportSortColumn = (companyId, companyRollupReportSortColumn) => ({
  type: COMPANYROLLUPREPORTSORTCOLUMN_SUBMIT,
  companyId,
  companyRollupReportSortColumn
})

export const showLogo = (companyId, showLogo) => ({
  type: COMPANY_SHOW_LOGO_SUBMIT,
  companyId,
  showLogo
});


const initialState = {
  showModal: false,
  page: 1,
  isFetching: false
}

const companyEditReducer = (state = initialState, action) => {
  switch (action.type) {
    case COMPANYEDIT_OPEN:
      return {
        ...state,
        showModal: true
      }
    case COMPANYEDIT_CLOSE:
      return initialState;

    case COMPANYEDIT_SET_PAGE:
      return {
        ...state,
        page: action.page
      }
    case COMPANYEDIT_SUBMIT:
      return {
        ...state,
        isFetching: true
      }
    case COMPANYEDIT_SUCCESS:
      return {
        ...state,
        isFetching: false
      }
    case COMPANYEDIT_FAILURE:
      return {
        ...state,
        isFetching: false
      }

    case COMPANYDELETE_SUBMIT:
      return {
        ...state,
        isFetching: true,
        apiError: undefined // distinguish between this and ""
      }

    case COMPANYDELETE_RESET:
      return {
        ...state,
        isFetching: false,
        apiError: undefined
      };

    case COMPANYDELETE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        apiError: "" // distinguish between this and undefined
      }
    case COMPANYDELETE_FAILURE:
      return {
        ...state,
        isFetching: false,
        apiError: action.apiError
      }

    case COMPANYSUBSCRIPTION_SUBMIT:
    case COMPANYROLLUPREPORTSORTCOLUMN_SUBMIT:
      return {
        ...state,
        isFetching: true,
        message: ""
      }
    case COMPANYSUBSCRIPTION_SUCCESS:
    case COMPANYROLLUPREPORTSORTCOLUMN_SUCCESS:
      return {
        ...state,
        isFetching: false
      }
    case COMPANYSUBSCRIPTION_FAILURE:
    case COMPANYROLLUPREPORTSORTCOLUMN_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: action.message
      }

    case COMPANY_SHOW_LOGO_SUBMIT:
      return {
        ...state,
        isFetching: true,
        apiError: ""
      }
    case COMPANY_SHOW_LOGO_SUCCESS:
      return {
        ...state,
        isFetching: false,
      }
    case COMPANY_SHOW_LOGO_FAILURE:
      return {
        ...state,
        isFetching: false,
        apiError: action.apiError
      }

    default:
      return state

  }
}

export default companyEditReducer;
