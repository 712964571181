import React from 'react';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';
import WrappedField from 'common/components/BootstrapFormFieldsFinalForm';
import * as validate from 'utils/formValidation';
import { createSelector } from 'reselect';
import { onSubmitActions } from 'redux-form-submit-saga';
import { Alert, Button, Modal } from 'react-bootstrap';
import BusySpinner from 'common/components/BusySpinner';

const DEFAULT_DAILY_LIMIT = 200000;
const DEFAULT_MIN_LIMIT = 60;

class UpsertApiPackage extends React.Component {
  HiddenField = () => null;

  render() {
    const {
      onSubmit,
      initialValues,

      modalOpen,
      closeModal,
      id
    } = this.props;

    return (
      <Modal show={modalOpen} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>{id ? 'Update' : 'Add new'} API Package</Modal.Title>
        </Modal.Header>

        <Form 
          onSubmit={onSubmit}
          initialValues={initialValues}
        >
          {({handleSubmit, submitting, invalid, dirty, error}) => (
            <>
            <Modal.Body>
              <form onSubmit={handleSubmit}>
                {error && <Alert variant="danger">{error}</Alert>}

                <Field name="id" component={this.HiddenField} />

                <WrappedField
                  type="text"
                  label="Name"
                  name="name"
                  validate={validate.composeValidators(
                    validate.required, 
                    validate.maxLength30, 
                    validate.startsWithAlphanumeric
                  )}
                  formClass=" "
                  labelClass="UserEdit__label"
                />

                <WrappedField
                  type="text"
                  label="Daily limit (enter -1 for unlimited)"
                  name="dailyLimit"
                  validate={validate.composeValidators(
                    validate.required, 
                    validate.number, 
                    validate.positiveOrMinusOne
                  )}
                  formClass=" "
                  labelClass="UserEdit__label"
                />

                <WrappedField
                  type="text"
                  label="Rate per minute (enter -1 for unlimited)"
                  name="minLimit"
                  validate={validate.composeValidators(
                    validate.required, 
                    validate.number, 
                    validate.positiveOrMinusOne
                  )}
                  formClass=" "
                  labelClass="UserEdit__label"
                />

                {dirty && (
                  <Alert variant="info">
                    <strong>Note:</strong> changes to daily limit and rate per
                    minute can take up to 5 minutes to become active.
                  </Alert>
                )}
              </form>
            </Modal.Body>

            <Modal.Footer>
              <Button
                variant="success"
                onClick={handleSubmit}
                disabled={submitting || invalid}
              >
                {submitting ? (
                  <span>
                    <BusySpinner inButton={true} />Saving
                  </span>
                ) : (
                  'Save and Close'
                )}
              </Button>
            </Modal.Footer>
            </>
          )}
        </Form>
      </Modal>
    );
  }
}

const selectApiPackages = (state, id) => state.apiPackages.data;
const selectId = (state, id) => id;
const getApiPackageToEdit = createSelector(
  selectApiPackages,
  selectId,
  (apiPackages, id) => apiPackages.find(ap => ap.id === id)
);

const mapStateToProps = (state, ownProps) => {
  const existingApiPackage = getApiPackageToEdit(state, ownProps.id);
  if (existingApiPackage)
    return {
      initialValues: {
        name: existingApiPackage.name,
        dailyLimit: existingApiPackage.dailyLimit,
        minLimit: existingApiPackage.minLimit,
        id: existingApiPackage.id
      }
    };
  else
    return {
      initialValues: {
        name: '',
        dailyLimit: DEFAULT_DAILY_LIMIT,
        minLimit: DEFAULT_MIN_LIMIT
      }
    };
};

const mapDispatchToProps = dispatch => ({
  onSubmit: values => onSubmitActions('APIPACKAGEUPSERT')(values, dispatch),
});

const UpsertApiPackageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UpsertApiPackage);

export default UpsertApiPackageContainer;
