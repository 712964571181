import React, { useState } from "react";
import { Formik, Form, ErrorMessage, useField } from "formik";
import { Alert, Button, FormGroup, FormLabel, FormControl } from 'react-bootstrap';
import BusySpinner from 'common/components/BusySpinner';
import ThreatTypeSelector from "common/components/ThreatTypeSelector";
import 'common/css/inputElements.css';
import "./ReportSettings.css";

const WwaFormikRadio = ({ children, ...props }) => {
  const [field] = useField({ ...props, type: "radio" });
  return (
    <span>
      <input id={props.id} type="radio" {...field} {...props} className="wwa-radio" />
      <label htmlFor={props.id} />
      {children}
    </span>
  );
};

const CustomReportForm = props => {
  const [threatTypesKey, setThreatTypesKey] = useState(0);
    
  return (
    <div className="">
      <Formik
        initialValues={props.customReportInitialValues}
        validate={values => {
          const errors = {};
          if(values.name.length > 30) {
            errors.name = 'Max 30 characters'
          } 
          if(+values.totalSnowIN > 500) {
            errors.totalSnowIN = "Max 500''"
          } else if(+values.totalSnowIN < 0) {
            errors.totalSnowIN = "No negative"
          } 
          if(+values.totalIceaccum > 2) {
            errors.totalIceaccum = "Max 2''"
          } else if(+values.totalIceaccum < 0) {
            errors.totalIceaccum = "No negative"
          } 
          if(+values.maxPop < 20) {
            errors.maxPop = "Min 20%";
          }
          return errors;
        }}

        onSubmit={(values, { setSubmitting }) => {
          const {name, ...formValues} = values;
          const allValues = {
            ...formValues,
            threatWeatherPrimaryCodes: { ...props.thrtTypeVal }
          };
          const id = props.customReportInitialValues.id; // use stable ID, reset to empty mustn't change this
          props.saveCustomReportFilterValues(props.companyId, allValues, name || undefined, id);
        }}
      >

        {({ submitForm, values, errors, touched, handleChange, handleBlur, resetForm, dirty }) => (
          <Form>
            <FormGroup 
              controlId="formValidationError1" 
            >
              <FormLabel>
                <span className="ReportSettings__CustomReportLabel">
                  Name (optional){" "}
                </span>
              </FormLabel>
              <FormControl 
                type="text"
                name="name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
              />
              <div style={{color:'#a94442', marginTop:'0.5em'}}>
                {(props.apiError || {}).name}
                <ErrorMessage name="name"/>
              </div>
            </FormGroup>

            <FormGroup 
              controlId="formValidationError4" 
            >
              <FormLabel>
                <span className="ReportSettings__CustomReportLabel">
                  POP (probability of precipitation, min 20%) &ge;{" "}
                </span>
              </FormLabel>
              <FormControl 
                type="number"
                name="maxPop"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.maxPop}
              />
              <div style={{color:'#a94442', marginTop:'0.5em'}}>
                <ErrorMessage name="maxPop"/>
              </div>
            </FormGroup>

            <FormGroup 
              controlId="formValidationError2" 
            >
              <FormLabel>
                <span className="ReportSettings__CustomReportLabel">
                  And Total Snow (inches) &ge;{" "}
                </span>
              </FormLabel>
              <FormControl 
                type="number"
                name="totalSnowIN"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.totalSnowIN}
              />
              <div style={{color:'#a94442', marginTop:'0.5em'}}>
                <ErrorMessage name="totalSnowIN"/>
              </div>
            </FormGroup>

            <FormGroup>
              <FormLabel>
                <span className="ReportSettings__CustomReportLabel"> And </span>
              </FormLabel>{' '}
              <WwaFormikRadio id="iceSnowPredicateAnd" name="iceSnowPredicate"  value='and'/>

              <FormLabel>
                <span className="ReportSettings__CustomReportLabel"> Or </span>
              </FormLabel>{' '}
              <WwaFormikRadio id="iceSnowPredicateOr" name="iceSnowPredicate"  value='or'/>
            </FormGroup>

            <FormGroup 
              controlId="formValidationError3" 
            >
              <FormLabel>
                <span className="ReportSettings__CustomReportLabel">
                  Total Ice Accumulation (inches) &ge;{" "}
                </span>
              </FormLabel>
              <FormControl 
                type="number"
                name="totalIceaccum"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.totalIceaccum}
              />
              <div style={{color:'#a94442', marginTop:'0.5em'}}>
                <ErrorMessage name="totalIceaccum"/>
              </div>
            </FormGroup>

            <div>
              <span className="ReportSettings__CustomReportLabel">
                And any of
              </span>
              {/* When "Reset" button is pressed, change ThreatTypeSelector's key to reinitialize it.
                Initial values: on first render: show previously saved values; on reset: empty values.
              */}
              <ThreatTypeSelector
                key={threatTypesKey}
                cbGroupId="customReportThreatTypes"
                weatherType={"winter"}
                initialValues={threatTypesKey % 2 === 0
                    ? props.customReportInitialValues.threatWeatherPrimaryCodes
                    : props.emptyInitialValues.threatWeatherPrimaryCodes
                }
              />
            </div>

            {props.isSavedCustomReportsSuccess && (
              <Alert variant="success">
                Values saved successfully!
               </Alert>
            )}
            {props.apiError && (typeof props.apiError === "string") && 
              <div style={{color:'#a94442', marginTop:'0.5em'}}>
                {props.apiError}
              </div>
            }
            <div className="ReportSettings_Buttons">
              <Button variant="success" onClick={submitForm} disabled={props.isFetching}>
                {props.isFetching ? (
                  <span>
                    <BusySpinner inButton={true} />Saving
                  </span>
                ) : (
                  'Save'
                )}
              </Button>
              {/* Reset to previously saved values */}
              <Button 
                variant="outline-secondary" 
                onClick={() => {
                  resetForm({values: props.customReportInitialValues});
                  setThreatTypesKey(threatTypesKey * 2);
                }} disabled={props.isFetching}>
                Reset
              </Button>
              {/* Reset to empty values */}
              <Button 
                variant="outline-secondary" 
                onClick={() => {
                  resetForm({values: props.emptyInitialValues});
                  setThreatTypesKey(threatTypesKey *2 + 1)
                }} disabled={props.isFetching}>
                Clear
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
};

export default CustomReportForm;
