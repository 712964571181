import { connect } from 'react-redux'
import ToggleButtonComponent from './ToggleButton'
import { selectButton } from './ToggleButtonDuck'

const mapStateToProps = ({togglebutton}, ownProps) => ({
  selectedButton: togglebutton[ownProps.buttonGroupId] && togglebutton[ownProps.buttonGroupId].btn
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  selectButton: (val, label) => {
    dispatch(selectButton(ownProps.buttonGroupId, val, label))
  },
})

const ToggleButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ToggleButtonComponent)

export default ToggleButtonContainer;


