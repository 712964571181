import React from "react";
import PropTypes from "prop-types";
import ResultWithDelete from "./ResultWithDelete";
import "./ResultWithDelete.css";

const ResultPool = ({ resultList, handleDelete, fieldNames, maxWidth, iconFunc }) => (
  <div className="ResultPool__ResultItem">
    {resultList
      .filter(r => r)
      .sort((a,b) => a.name && b.name
        ? a.name.localeCompare(b.name.toUpperCase()) 
        : a.id.localeCompare(b.id.toUpperCase()))
      .map(r => (
        <ResultWithDelete
          key={r.id}
          obj={r}
          onDeleteClick={handleDelete}
          fieldNames={fieldNames}
          maxWidth={maxWidth}
          iconFunc={iconFunc}
        />
      ))}
  </div>
);

ResultPool.propTypes = {
  resultList: PropTypes.array.isRequired,
  handleDelete: PropTypes.func.isRequired,
  fieldNames: PropTypes.arrayOf(PropTypes.string) // the field names in 'obj' to display
};

export default ResultPool;
