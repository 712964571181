import log from "loglevel";
import { put, takeEvery, call } from "redux-saga/effects";

import {
  CONTACT_SUBMIT,
  CONTACT_SUCCESS,
  CONTACT_FAILURE
} from "./ContactDuck";
import fetchApi from "utils/fetchApi";

const hostname = window && window.location && window.location.hostname;

export function* postContactSaga({
  id,
  name,
  email,
  companyName,
  contactNumber
}) {
  try {
    let url;
    // make sure the Trial Company is created on the demo server:
    let prefix =
      hostname === "localhost"
        ? undefined
        : "https://demo.fmsweather.com/api";
    let payload;
    switch (id) {
      case "form":
        url = "/Contacts/email";
        payload = { email: email.trim(), name: name.trim() };
        break;
      case "demo":
        url = "/Companies/CreateDemoCompany";
        payload = {
          email: email.trim(),
          personName: name.trim(),
          personCompanyName: companyName.trim(),
          primaryPhone: contactNumber.trim()
        };
        break;
      default:
        throw new Error("Unexpected form ID", id);
    }

    // call API
    const parsedJson = yield call(fetchApi, url, { prefix,  payload });

    if (!parsedJson.error) {
      yield put({ type: CONTACT_SUCCESS, id, userObj: parsedJson });
    } else {
      yield put({
        type: CONTACT_FAILURE,
        id,
        error: parsedJson.error.text._error
          ? parsedJson.error.text._error
          : parsedJson.error.text
      });
    }
  } catch (error) {
    log.error("fetchContactSaga error", error); // something happened during the network call
    yield put({
      type: CONTACT_FAILURE,
      id,
      message: "Failed to contact",
      error: "Network error."
    });
  }
}

// listen for actions of type CONTACT_SUBMIT and use them
export default function* contactSaga() {
  yield takeEvery(CONTACT_SUBMIT, postContactSaga);
}
