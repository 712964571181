/*
 * Helper module to manage semaphores which have an integer value that can increase and decrease.
 * Once it reaches 0 after it had been non-zero before, it will dispatch an action to Redux to announce this fact.
 * A semaphore is started with 3 arguments:
 * - a unique ID (string)
 * - an array of arbitrary Redux action types which are listened for to increase the counter
 * - an array of arbitrary Redux action types which are listened for to decrease the counter
 * The action dispatched on reaching 0 will be named <ID>_SEMAPHORE_ZERO
 *
 * Example:
 * start:
 *   runSemaphore('MYSEMA', ['MY_INCREASE'], ['MY_DECREASE']);
 * later:
 *   dispatch increase/decrease actions, e.g. trigger network calls through sagas and wait for the same number of started calls/sagas to finish
 * much later:
 *   // wait for semaphore
 *   yield take('MYSEMA_SEMAPHORE_ZERO')
 *   // all sagas finished!
 */

import { put, takeEvery } from "redux-saga/effects";
import log from "loglevel";

let semaphores = {};

function createSemaphore(id) {
  semaphores[id] = 0;
}

function* incSemaphore(id) {
  semaphores[id]++;
  yield;
}

function* decSemaphore(id) {
  semaphores[id]--;

  if (semaphores[id] === 0) {
    yield put({ type: `${id}_SEMAPHORE_ZERO` });
  } else yield;
}

export function* runSemaphore(id, incActions, decActions) {
  createSemaphore(id);
  try {
    yield takeEvery(incActions, incSemaphore, id);
    yield takeEvery(decActions, decSemaphore, id);
  } catch (err) {
    log.error(`runSemaphore [${id}] Error: ${err}`);
  }
}
