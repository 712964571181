import { PERSONALDATA_SUCCESS, PERSONALDATA_AUTOREFRESH_SUCCESS } from 'common/components/Profile/ProfileDuck'
import { USEREDIT_SUCCESS } from 'Admin/UsersManage/UsersManageDuck'
// This file implements the "ducks" pattern, i.e. combines action constants,
// action creators and reducers in one file instead of having multiple files per
// function. As long as there are not too many values, this makes it nice and
// compact.


// Action constants

export const LOGIN_SUBMIT = 'LOGIN_SUBMIT';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const CLEAR_LOGIN_DATA = 'CLEAR_LOGIN_DATA'
export const USERDATA_SUCCESS = 'USERDATA_SUCCESS';
export const USERDATA_FAILURE = 'USERDATA_FAILURE';

export const LOGOUT_SUBMIT = 'LOGOUT_SUBMIT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const AUTH_EXPIRED_COUNTDOWN = "AUTH_EXPIRED_COUNTDOWN";

// Action creators

export const resetForm = () => {
  return { type: CLEAR_LOGIN_DATA }
}

// redux-form calls this function with its 'values' object, whose keys
// correspond to form fields
export const login = ({username, password}) => {
  return {
    type: LOGIN_SUBMIT,
    username,
    password
  }
}

export const logout = () => {
  return {
    type: LOGOUT_SUBMIT
  }
}

// Reducer

const initialState = {
  username: '',
  userId: undefined,
  ttl: 0,
  isFetching: false,
  authToken: '',
  roles: ['company-user'],
  companyId: undefined,
  isLoggedOut: true
};

const updateOwnData = (state, userObj) => ({
    ...state,
    username: userObj.username,
    email: userObj.email,
    roles: userObj.roles,
    companyId: userObj.companyId,
    title: userObj.title,
    mobilePhone: userObj.mobilePhone,
    officePhone: userObj.officePhone,
    extension: userObj.extension,
    noSmsNotification: userObj.noSmsNotification,
    autoRefresh: userObj.autoRefresh
  }
)

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_LOGIN_DATA:
      return initialState;
    case LOGIN_SUBMIT:
      return {
        ...state,
        isFetching: true,
        submissionErrors: null,
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        userId: action.userObj.userId,
        authToken: action.userObj.id,
        ttl: action.userObj.ttl,
        isLoggedOut: false,
      }


    case LOGOUT_SUCCESS:
    case LOGOUT_FAILURE:
      return initialState;

    case LOGIN_FAILURE:
      return {
        ...state,
        submissionErrors: action.payload,
        isFetching: false
      }

    case LOGOUT_SUBMIT:
      return {
        ...state,
        isFetching: true,
      }

    case USERDATA_SUCCESS:
    case PERSONALDATA_AUTOREFRESH_SUCCESS:
    case PERSONALDATA_SUCCESS:
      return updateOwnData(state, action.userObj)

    case USEREDIT_SUCCESS:
      if (action.userObj.id === state.userId){
        return updateOwnData(state, action.userObj)
      } else 
        return state;

    case AUTH_EXPIRED_COUNTDOWN:
      return {
        ...state,
        authExpiredCounter: action.authExpiredCounter ?? state.authExpiredCounter
      }

    default:
      return state

  }
}

export default loginReducer;
