import React from "react";
import { Modal } from "react-bootstrap";

const texts = {
  customizeReports: [
    "To upload a company Logo, click the 'Choose file' button.",
    "Acceptable file types are PNG and JPG.",
    "To adjust the cutout of the selected image, click and draw the handles.",
    "You can pan the selection by clicking and drawing within the cutout.",
    "A preview of the cutout will be shown below - a green border means that the aspect ratio is good, a red that it will not look good on the reports. The image should be wider than tall at around 16:9.",
    "To upload the preview, click the button."
  ],
  "companyRollupReportSortColumn": [
    "By default, the Company Event Rollup report (CSV/Excel) is sorted by first Event lead time (Sites with soonest Events first).",
    "Alternatives are:",
    "1. Site Name - the report sheet is sorted by name only, not taking into account forecast data.",
    "2. Snowfall - first Event snow'', sorted descending."
  ],
  default: ["???"]
};

const HelpModal = ({ source = "default", openState, onClose }) => {
  return (
    <Modal show={openState} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Help</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="ReportSettings__HelpModal__Container">
          <ul>
            {texts[source].map((t, i) => (
              <li key={i} className="ReportSettings__HelpModal__Text">{t}</li>
            ))}
          </ul>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default HelpModal;
