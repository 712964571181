import immutable from "object-path-immutable";
export const COLLAPSIBLE_TOGGLE = "COLLAPSIBLE_TOGGLE";
export const COLLAPSIBLE_SET = "COLLAPSIBLE_SET";
export const COLLAPSIBLE_TOGGLE_PINNED = "COLLAPSIBLE_TOGGLE_PINNED";
export const COLLAPSIBLE_SET_PINNED = "COLLAPSIBLE_SET_PINNED";
export const COLLAPSIBLE_SET_VISIBLE = "COLLAPSIBLE_SET_VISIBLE";

export const toggleCollapsible = id => ({
  type: COLLAPSIBLE_TOGGLE,
  id
});

export const setCollapsed = (id, collapsed = true) => ({
  type: COLLAPSIBLE_SET,
  id,
  collapsed
});

export const toggleCollapsiblePinned = id => ({
  type: COLLAPSIBLE_TOGGLE_PINNED,
  id
});

export const setPinned = (id, pinned = false) => ({
  type: COLLAPSIBLE_SET_PINNED,
  id,
  pinned
});

export const setVisible = (id, visible = true) => ({
  type: COLLAPSIBLE_SET_VISIBLE,
  id,
  visible
});

const initialState = {};
const collapsible = (state = initialState, action) => {
  switch (action.type) {
    case COLLAPSIBLE_TOGGLE:
      return immutable.set(
        state,
        [action.id, "collapsed"],
        !(state[action.id] || {}).collapsed
      );

    case COLLAPSIBLE_SET:
      return immutable.set(state, [action.id, "collapsed"], action.collapsed);

    case COLLAPSIBLE_TOGGLE_PINNED:
      return immutable.set(
        state,
        [action.id, "pinned"],
        // default if unset: pinned===true
        (state[action.id] || {}).pinned === undefined
          ? false
          : !(state[action.id] || {}).pinned
      );

    case COLLAPSIBLE_SET_PINNED:
      return immutable.set(state, [action.id, "pinned"], action.pinned);

    case COLLAPSIBLE_SET_VISIBLE:
      return immutable.set(state, [action.id, "visible"], action.visible);

    default:
      return state;
  }
};

export default collapsible;
