import React from "react";
import PropTypes from "prop-types";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { CSVLink } from "react-csv";

export const FILE_TYPE_CSV = "CSV";
export const FILE_TYPE_PDF = "PDF";

const getIconForType = fileType => {
  switch (fileType) {
    case FILE_TYPE_PDF:
      return "fa fa-file-pdf-o";
    default:
      return "fa fa-download";
  }
};

// deep mapping of key to value of k in object o
// for object site and keys 'name', 'zipCode', will return [site.name, site.zipCode]
// for object site and keys 'name', 'latLng.lng', will return [site.name, site.latLng.lng]
const obj2arr = (o, keys) =>
  keys.map(k => k.split(".").reduce((p, c) => (p && p[c]) || null, o));

const SitesDownload = props => (
  <OverlayTrigger
    placement="bottom"
    overlay={
      <Tooltip id={props.tooltip || "tooltip"}>
        {props.tooltip || "Download"}
      </Tooltip>
    }
  >
    <CSVLink
      data={props.data ? props.data.map(site => obj2arr(site, props.keys)) : []}
      headers={props.headers}
      separator=";"
      filename={props.fileName || "Sites.csv"}
    >
      <i
        className={getIconForType(props.fileType)}
        style={{ fontSize: "20px", color: "rgb(136, 150, 170)" }}
        aria-hidden="true"
      />
    </CSVLink>
  </OverlayTrigger>
);

SitesDownload.propTypes = {
  tooltip: PropTypes.string,
  fileType: PropTypes.string,
  fileName: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  keys: PropTypes.array
};

export default SitesDownload;
