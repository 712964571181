import log from 'loglevel';

import { put, takeEvery, call, select } from 'redux-saga/effects'
import { WATCHADD_SUCCESS, WATCHADD_SUBMIT, WATCHADD_FAILURE } from './WatchAddDuck.js';
import { WATCHLIST_APPLY_FILTER } from 'Watchlist/WatchListDuck'
import fetchApi from 'utils/fetchApi';

function* postWatchCall(watchCrit){

    try{

      const login = yield select((state) => state.login);

      const parsedJson = yield call(fetchApi, '/WatchLists', {
          authToken: login.authToken,
          method: 'POST',
          payload: watchCrit
      })

      if (!parsedJson.error) {
        return {
          ...parsedJson
        }
      } else {
        yield put({
          type: WATCHADD_FAILURE,
          message: parsedJson.error.text._error ? parsedJson.error.text._error : parsedJson.error.text,
        })
      }
    } catch(error){
      log.error('postWatchSaga error', error)
      yield put({
        type: WATCHADD_FAILURE,
        message: 'Network error.'
      })
    }
}

export function* postWatchlist(action) {

    const parsedJson = yield call(postWatchCall, action.watchCrit);

    if (parsedJson && !parsedJson.error){
      yield put({
          type: WATCHADD_SUCCESS,
          payload: parsedJson
      });
    }

    yield put({
      type: WATCHLIST_APPLY_FILTER
    });
}

export default function* watchAddSaga () {
  yield takeEvery(WATCHADD_SUBMIT, postWatchlist)
}

