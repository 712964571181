import axios from 'axios'
import { put, takeEvery, call, select } from 'redux-saga/effects'
import fetchApi from 'utils/fetchApi';

import {ACTIONS} from './MapActions';
import {AERIS_CLIENT_ID, AERIS_CLIENT_PASSWORD} from './library/config';

const API_URL = 'https://api.aerisapi.com';

function fetchLocationWeatherInfo(latLng) {

    const requestUrl =
    `${API_URL}/batch?p=${latLng.lat},${latLng.lng}` +
    `&filter=&query=&client_id=${AERIS_CLIENT_ID}&client_secret=${AERIS_CLIENT_PASSWORD}` +
    '&requests=' +
    '%2Fforecasts%253Ffilter%3D1hr%2526limit%3D7%26query%3D' +
    '%2C%2Fforecasts%253Ffilter%3Dday%2526limit%3D5%26query%3D%2C' +
    '%2Fobservations%253Ffilter%3Dmesonet%2526query%3Dtemp%3A-999%2C' +
    '%2Fadvisories%253Ffields%3Ddetails.name%2Cdetails.body%2Ctimestamps%2526filter%3D%26query%3D%2C' +
    '%2Fplaces%253Ffilter%3D%2526query%3D';

    return axios.get(requestUrl);
};


export function* findLocation(action) {

    const response = yield call(fetchLocationWeatherInfo, action.payload.location);

    // Instructing middleware to dispatch corresponding action.
    yield put({
        type: ACTIONS.FETCH_LOCATION_WEATHER_SUCCESS,
        payload : {
            location : action.payload.location,
            response : response,
            showPanel: action.payload.showPanel
        }

    });

}


export function* tileLoaded(action) {
    
    const authToken = yield select((state) => state.login.authToken);
    const url = `/Persons/tileCount`
    /*const subscriptions =*/ yield call(fetchApi, url, {
        authToken,
        payload : {
            count : action.payload.count
        },
        method: 'POST'
    })
    
}

export function* watchMap(){
    yield takeEvery(ACTIONS.FETCH_LOCATION_WEATHER, findLocation)
    yield takeEvery(ACTIONS.TILE_LOADED, tileLoaded)
}
