
// This file implements the "ducks" pattern, i.e. combines action constants,
// action creators and reducers in one file instead of having multiple files per
// function. As long as there are not too many values, this makes it nice and
// compact.

import immutable from 'object-path-immutable';
import { winterAdvisoryTypes } from "./advisorytypes";

// Action constants

export const ADVISORYTYPESELECTOR_RESET = 'ADVISORYTYPESELECTOR_RESET';
export const ADVISORYTYPESELECTOR_SETSELECTED = 'ADVISORYTYPESELECTOR_SETSELECTED';
export const ADVISORYTYPESELECTOR_RESETSELECTED = 'ADVISORYTYPESELECTOR_RESETSELECTED';

const allAdvisoriesTrue = {
  'Avalanche Warning': true,
  'Avalanche Watch': true,
  'Blizzard Warning': true,
  'Blowing Snow Advisory': true,
  'Ice Storm Warning': true,
  'Law Enforcement Warning': true,
  'Snowfall Warning': true,
  'Wind Chill Advisory': true,
  'Wind Chill Warning': true,
  'Wind Chill Watch': true,
  'Winter Storm Warning': true,
  'Winter Storm Watch': true,
  'Winter Weather Advisory': true,
  'Severe Thunderstorm Warning': true,
  'Severe Thunderstorm Watch': true,
  'Severe Thunderstorm Advisory': true,
  'Wind Watch': true,
  'Wind Warning': true,
  'Wind Advisory': true
}

export const allAdvisoriesFalse = Object.assign(...Object.entries(allAdvisoriesTrue).map(([k]) => ({[k]: false})));

// Reducer

const initialState = {
};

// Action creators

export const resetCheckboxes = (cbGroupId) => ({
  type: ADVISORYTYPESELECTOR_RESET,
  cbGroupId
})

// "new" 2 stage selection process result: array of advisory type names
export const setAdvisoryTypeArray = (cbGroupId, advisoryTypes) => ({
  type: ADVISORYTYPESELECTOR_SETSELECTED,
  cbGroupId,
  advisoryTypes
});

export const resetAdvisoryTypeArray = (cbGroupId) => ({
  type: ADVISORYTYPESELECTOR_RESETSELECTED,
  cbGroupId
});


// Reducer

const advisoryTypeSelectorReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADVISORYTYPESELECTOR_RESET:
      return immutable.set(state, [action.cbGroupId, 'advisoryTypes'], [...winterAdvisoryTypes]);

    case ADVISORYTYPESELECTOR_SETSELECTED:
      return immutable.set(state, [action.cbGroupId, 'advisoryTypes'], action.advisoryTypes);

    case ADVISORYTYPESELECTOR_RESETSELECTED:
      return immutable.set(state, [action.cbGroupId, 'advisoryTypes'], []);

    default:
      return state

  }
}

export default advisoryTypeSelectorReducer;


