
export const PERSONALDATA_SUBMIT = 'PERSONALDATA_SUBMIT';
export const PERSONALDATA_SUCCESS = 'PERSONALDATA_SUCCESS';
export const PERSONALDATA_FAILURE = 'PERSONALDATA_FAILURE';

export const PERSONALDATA_AUTOREFRESH_SUBMIT = 'PERSONALDATA_AUTOREFRESH_SUBMIT';
export const PERSONALDATA_AUTOREFRESH_SUCCESS = 'PERSONALDATA_AUTOREFRESH_SUCCESS';
export const PERSONALDATA_AUTOREFRESH_FAILURE = 'PERSONALDATA_AUTOREFRESH_FAILURE';

export const REPORTSUBSCRIPTION_SUBMIT = 'REPORTSUBSCRIPTION_SUBMIT';
export const REPORTSUBSCRIPTION_SUCCESS = 'REPORTSUBSCRIPTION_SUCCESS';
export const REPORTSUBSCRIPTION_FAILURE = 'REPORTSUBSCRIPTION_FAILURE';
export const REPORTSUBSCRIPTION_RESET = 'REPORTSUBSCRIPTION_RESET';

export const AVATAR_STATUS_RESET = 'AVATAR_STATUS_RESET';
export const AVATAR_GET_SUBMIT = 'AVATAR_GET_SUBMIT';
export const AVATAR_GET_SUCCESS = 'AVATAR_GET_SUCCESS';
export const AVATAR_GET_FAILURE = 'AVATAR_GET_FAILURE';

export const resetSubscriptionsMeta = () => ({
  type: REPORTSUBSCRIPTION_RESET
});

export const updateSubscriptions =(data) => ({
  type: REPORTSUBSCRIPTION_SUBMIT,
  data
})

export const resetAvatarStatus = () => ({
  type: AVATAR_STATUS_RESET
})

export const fetchAvatar = (userId) => ({
  type: AVATAR_GET_SUBMIT,
  userId
})

export const setAutoRefresh = (userId, autoRefresh) => ({
  type: PERSONALDATA_AUTOREFRESH_SUBMIT,
  userId,
  autoRefresh
})

const initialState = {
  userDataError: undefined,
  reportSubscriptionError: undefined,
  isFetching: false,
  avatarError: undefined,
  isDownloaded: false,
  avatars: {},
  autoRefreshError: false
}

const personalData = (state = initialState, action) => {
  switch (action.type) {
    case PERSONALDATA_SUBMIT:
      return {
        ...state,
        isFetching: true,
        userDataError: undefined
      }
    case PERSONALDATA_SUCCESS:
      return {
        ...state,
        isFetching: false,
        userDataError: undefined
      }
    case PERSONALDATA_FAILURE:
      return {
        ...state,
        isFetching: false,
        userDataError: action.payload
      }

    case PERSONALDATA_AUTOREFRESH_SUBMIT:
      return {
        ...state,
        isFetching: true,
        autoRefreshError: false
      }
    case PERSONALDATA_AUTOREFRESH_SUCCESS:
      return {
        ...state,
        isFetching: false,
      }
    case PERSONALDATA_AUTOREFRESH_FAILURE:
      return {
        ...state,
        isFetching: true,
        autoRefreshError: action.apiError
      }

    case REPORTSUBSCRIPTION_RESET:
      return {
        ...state,
        isFetching: false,
        reportSubscriptionError: undefined
      }

    case REPORTSUBSCRIPTION_SUBMIT:
      return {
        ...state,
        isFetching: true,
        reportSubscriptionError: undefined
      }
    case REPORTSUBSCRIPTION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        reportSubscriptionError: ''
      }
    case REPORTSUBSCRIPTION_FAILURE:
      return {
        ...state,
        isFetching: false,
        reportSubscriptionError: action.message
      }

    case AVATAR_STATUS_RESET:
      return {
        ...state,
        avatarError: false,
        avatarUploaded: false
      }

    case AVATAR_GET_SUBMIT:
      return {
        ...state,
        isDownloading: true,
        avatarError: false,
      }
    case AVATAR_GET_SUCCESS:
      return {
        ...state,
        isDownloading: false,
        avatarError: false,
        avatars: {...state.avatars, [action.userId]: action.data}
      }
    case AVATAR_GET_FAILURE:
      return {
        ...state,
        isDownloading: false,
        avatarError: action.payload
      }

    default:
      return state

  }
}

export default personalData;
