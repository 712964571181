export const WATCH_DELETE = 'WATCH_DELETE';
export const WATCH_UNDO_DELETE = 'WATCH_UNDO_DELETE';
export const WATCHMANAGE_OPEN = 'WATCHMANAGE_OPEN';
export const WATCHMANAGE_CLOSE = 'WATCHMANAGE_CLOSE';
export const WATCHMANAGE_PURGE = 'WATCHMANAGE_PURGE';
export const WATCHDEL_SUCCESS = 'WATCHDEL_SUCCESS';
export const WATCHDEL_FAILURE = 'WATCHDEL_FAILURE';
export const WATCHDEL_SUBMIT = 'WATCHDEL_SUBMIT';

export const deleteWatch = (watchListItem) => ({
  type: WATCH_DELETE,
  watchListItem
})

export const undoDeleteWatch = (id) => ({
  type: WATCH_UNDO_DELETE,
  id
})

export const purgeWatches = () => ({
  type: WATCHMANAGE_PURGE
})


export const closeModal = () => ({
  type: WATCHMANAGE_CLOSE
})

export const openModal = () => ({
  type: WATCHMANAGE_OPEN
})

const initialState = {
  deletedWatches: [],
  showModal: false
}


const watchManageReducer = (state = initialState, action) => {
  switch (action.type) {
    case WATCH_DELETE:
      return {
        ...state,
        deletedWatches: [...state.deletedWatches].concat(action.watchListItem)
      }
    case WATCH_UNDO_DELETE:
      return {
        ...state,
        deletedWatches: state.deletedWatches.filter(w => w !== action.id)
      }
    case WATCHMANAGE_OPEN:
      return {
        ...state,
        showModal: true
      }
    case WATCHMANAGE_CLOSE:
      return {
        ...state,
        showModal: false
      }
    case WATCHDEL_SUCCESS:
      return {
        ...state,
        deleteWatches: []
      }
    default:
      return state

  }
}

export default watchManageReducer;
