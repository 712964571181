// This file implements the "ducks" pattern, i.e. combines action constants,
// action creators and reducers in one file instead of having multiple files per
// function. As long as there are not too many values, this makes it nice and
// compact.

// Action constants

export const FILTERSLIDER_SELECT = 'FILTERSLIDER_SELECT';
export const FILTERSLIDER_RESET = 'FILTERSLIDER_RESET';

// Reducer

// N.B.: the slider's values are always in the range 0..100! So for inches, they
// have to be recomputed: value=75 => 10*75/100 = 7.5" for a range of 0"..10"
// or value=75 => 2*75/100 = 1.5" for a range of 0"..2"
const initialState = {
  sliders: {}
};

// Action creators

export const setValue = (sliderId, value) => ({
  type: FILTERSLIDER_SELECT,
  sliderId, value
})

export const resetSlider = (sliderId) => ({
  type: FILTERSLIDER_RESET,
  sliderId
})

// Reducer

const updateGroup = (slice= {}, {value, label}) => (
  {
    ...slice, 
    value, 
    label
  }
)

const filterSliderReducer = (state = initialState, action) => {
  switch (action.type) {
    case FILTERSLIDER_RESET:
      return {
        ...state,
        [action.sliderId]: updateGroup(state[action.sliderId], {sliderId: action.sliderId, value: 0})
      }

    case FILTERSLIDER_SELECT:
      return {
        ...state,
        [action.sliderId]: updateGroup(state[action.sliderId], action)
      }
    default:
      return state

  }
}

export default filterSliderReducer;


