import log from "loglevel";
import { put, takeEvery, call } from "redux-saga/effects";
import fetchApi from "utils/fetchApi";
import {
  ACTV_TRIAL_SUBMIT,
  ACTV_TRIAL_SUCCESS,
  ACTV_TRIAL_FAILURE
} from "./ActivateTrialCompanyDuck";

export function* postActivateTrialCompany({
  emailToken: authToken,
  companyId
}) {
  if (!companyId) {
    yield put({
      type: ACTV_TRIAL_FAILURE,
      apiError: "No company ID."
    });
    return;
  }
  if (!authToken) {
    yield put({
      type: ACTV_TRIAL_FAILURE,
      apiError: "No email token."
    });
    return;
  }
  try {
    const json = yield call(
      fetchApi,
      `/Subscriptions/activateCompanyFromEmail`,
      {
        authToken,
        payload: {companyId}
      }
    );
    if (!json.error) {
      yield put({
        type: ACTV_TRIAL_SUCCESS,
        data: json,
        activateSuccess: true
      });
    } else {
      log.error(
        "postActivateTrialCompany api error:",
        JSON.stringify(json.error)
      );
      yield put({
        type: ACTV_TRIAL_FAILURE,
        isCompanyAlreadyActive: (json.error.text || {}).isCompanyAlreadyActive,
        apiError: json.error.text._error
          ? json.error.text._error
          : json.error.text
      });
    }
  } catch (err) {
    log.warn("postActivateTrialCompany err", err);
    yield put({ type: ACTV_TRIAL_FAILURE, apiError: "Network error" });
  }
}

export default function* activateTrialSaga() {
  yield takeEvery(ACTV_TRIAL_SUBMIT, postActivateTrialCompany);
}
