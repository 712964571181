import { connect } from 'react-redux'
import SearchBoxComponent from './SearchBoxComponent'
import { setSearchTerm, setSearchBoxState, CLOSED, FOCUSED } from './SearchBoxDuck'

const mapStateToProps = ({searchbox}, ownProps) => ({
  searchBoxState: ownProps.alwaysOpen ? FOCUSED : (searchbox[ownProps.boxId] ? searchbox[ownProps.boxId].boxState : CLOSED),
  searchTerm: searchbox[ownProps.boxId] ? (searchbox[ownProps.boxId].value ? searchbox[ownProps.boxId].value : '') : '',
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setSearchTerm: (searchTerm) => {
    dispatch(setSearchTerm(ownProps.boxId, searchTerm))
    // if the parent component supplies an onChange() function, call this
    ownProps.onChange && ownProps.onChange()
  },
  setSearchBoxState: (boxState) => {
    dispatch(setSearchBoxState(ownProps.boxId, boxState))
  },
})

const SearchBoxContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchBoxComponent)

export default SearchBoxContainer;


