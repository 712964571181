export const SITESELECT_ADD_SITE = "SITESELECT_ADD_SITE";
export const SITESELECT_DEL_SITE = "SITESELECT_DEL_SITE";
export const SITESELECT_SET_SELECT_BY = "SITESELECT_SET_SELECT_BY";
export const SITESELECT_SET_ORDER_BY = "SITESELECT_SET_ORDER_BY";
export const SITESELECT_SET_INPUT_VALUE = "SITESELECT_SET_INPUT_VALUE";
export const SITESELECT_RESET = "SITESELECT_RESET";

export const ALL_FIELDS = "All fields";
export const NAME = "Name";
export const CITY_STATE = "City, State";
export const STATE = "State";
export const ZIP_CODE = "Zip Code";

export const ORDER_OPTIONS = {
  name: "Name",
  city: "City",
  state: "State",
  zipCode: "Zip Code"
};

export const reset = () => ({
  type: SITESELECT_RESET
});

export const addSite = id => ({
  type: SITESELECT_ADD_SITE,
  id
});

export const delSite = id => ({
  type: SITESELECT_DEL_SITE,
  id
});

export const setSiteSelectedBy = selectSiteBy => ({
  type: SITESELECT_SET_SELECT_BY,
  selectSiteBy
});

export const setSiteSelectedOrderBy = selectSiteOrderBy => ({
  type: SITESELECT_SET_ORDER_BY,
  selectSiteOrderBy
});

export const setInputValue = value => ({
  type: SITESELECT_SET_INPUT_VALUE,
  value
});

const initialState = {
  selectSiteBy: ALL_FIELDS,
  selectedSites: [],
  inputValue: "",
  selectSiteOrderBy: "name"
};

const updateSelectedSites = (currentlySelectedSites, newSite) => {
  if (newSite !== "-select" && !currentlySelectedSites.includes(newSite)) {
    return [...currentlySelectedSites, newSite];
  }
  return currentlySelectedSites;
};

const siteSelectReducer = (state = initialState, action) => {
  switch (action.type) {
    case SITESELECT_RESET:
      return initialState;
    case SITESELECT_ADD_SITE:
      return {
        ...state,
        selectedSites: updateSelectedSites(state.selectedSites, action.id)
      };
    case SITESELECT_DEL_SITE:
      return {
        ...state,
        selectedSites: state.selectedSites.filter(s => s !== action.id)
      };
    case SITESELECT_SET_SELECT_BY:
      return {
        ...state,
        selectSiteBy: action.selectSiteBy,
        inputValue: ""
      };
    case SITESELECT_SET_ORDER_BY:
      return {
        ...state,
        selectSiteOrderBy: action.selectSiteOrderBy
      };
    case SITESELECT_SET_INPUT_VALUE:
      return {
        ...state,
        inputValue: action.value
      };

    default:
      return state;
  }
};

export default siteSelectReducer;
