import log from 'loglevel';
import { call, put, select, takeEvery } from 'redux-saga/effects'

import { LOGOUT_FAILURE, LOGOUT_SUBMIT, LOGOUT_SUCCESS, } from '../Login/LoginDuck'
import fetchApi from 'utils/fetchApi';

export function* fetchLogoutSaga(action) {
  try {
    const url = '/Persons/logout';
    const authToken = yield select((state) => state.login.authToken);

    // call API
    const data = yield call(fetchApi, url, {authToken})
    if (!data.error) {
      yield put({type: LOGOUT_SUCCESS})
    } else {
      yield put({
        type: LOGOUT_FAILURE,
        message: data.error.text._error ? data.error.text._error : data.error.text,
      })
    }
  } catch (error) {
    log.error('fetchLogoutSaga error', error)
    yield put({
      type: LOGOUT_FAILURE,
      message: 'Network error.'
    })
  }
}

// listen for actions of type LOGOUT_SUBMIT and use them
export default function* logoutSaga() {
  yield takeEvery(LOGOUT_SUBMIT, fetchLogoutSaga)
}

