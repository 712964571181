import log from "loglevel";
import { put, takeEvery, call, select } from "redux-saga/effects";
import fetchApi from 'utils/fetchApi';
import {
  SERVER_FEATURES_SUBMIT,
  SERVER_FEATURES_SUCCESS,
  SERVER_FEATURES_FAILURE,
  SET_SERVER_FEATURES_SUBMIT,
  SET_SERVER_FEATURES_SUCCESS,
  SET_SERVER_FEATURES_FAILURE
} from "common/ducks/pollNewDataDuck";

export function* queryServerFeatures(action) {
  try {
    const login = yield select(state => state.login);
    const { authToken } = login;

    const json = yield call(fetchApi, `/ServerStates/serverFeatures`, {
      authToken,
      method: "GET"
    });

    if (!json.error) {
      yield put({
        type: SERVER_FEATURES_SUCCESS,
        features: json.features
      });
    } else {
      console.log("queryServerFeatures Error:", json.error);
      yield put({
        type: SERVER_FEATURES_FAILURE,
        feature: "useCustomLogo",
        message: json.error.text._error || json.error.text.error
      });
    }
  } catch (err) {
    log.warn("queryServerFeatures err", err);
  }
}

export function* setServerFeatures(action) {
  try {
    const login = yield select(state => state.login);
    const { authToken } = login;
    const { field, value } = action;

    let json;
    if(field === 'liveDemoMode'){
      json = yield call(fetchApi, `/ServerStates/setLiveDemoMode`, { 
        authToken,
        payload: { liveDemoMode: value } 
      });
      yield put({
        type: SERVER_FEATURES_SUBMIT
      });
    } else {
      yield put({
        type: SET_SERVER_FEATURES_FAILURE,
        message: "unknown feature"
      })
    }

    if (!json.error) {
      yield put({
        type: SET_SERVER_FEATURES_SUCCESS
      });
    } else {
      log.debug("queryServerFeatures Error:", json.error);
      yield put({
        type: SET_SERVER_FEATURES_FAILURE,
        message: json.error.text._error || json.error.text.error
      });
    }
  } catch (err) {
    log.warn("queryServerFeatures err", err);
  }
}

export default function* loginSaga() {
  yield takeEvery(SERVER_FEATURES_SUBMIT, queryServerFeatures);
  yield takeEvery(SET_SERVER_FEATURES_SUBMIT, setServerFeatures);
}
