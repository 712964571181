import immutable from "object-path-immutable";

export const SELECTOR_RESET = "SELECTOR_RESET";
export const SELECTOR_SET_VALUE = "SELECTOR_SET_VALUE";

export const setSelector = (selectorId, value) => ({
  type: SELECTOR_SET_VALUE,
  selectorId,
  value,
});

const selectorReducer = (state = {}, action) => {
  switch (action.type) {
    case SELECTOR_SET_VALUE:
      return immutable.set(state, [action.selectorId], action.value);

    default:
      return state;
  }
};

export default selectorReducer;
