import imm from "object-path-immutable";

export const CTADATA_SUBMIT = "CTADATA_SUBMIT";
export const CTADATA_SUCCESS = "CTADATA_SUCCESS";
export const CTADATA_FAILURE = "CTADATA_FAILURE";
export const CTADATA_REFRESH = "CTADATA_REFRESH";
export const CTADATA_START_FETCH = "CTADATA_START_FETCH";

export const loadCallToAction = companyId => ({
  type: CTADATA_SUBMIT,
  companyId
});

const initialState = {
  data: [],
  meta: { isFetching: false, error: undefined }
};

export const callToAction = (state = initialState, action) => {
  switch (action.type) {
    case CTADATA_START_FETCH:
    case CTADATA_SUBMIT:
      return imm(state)
        .set("meta.isFetching", true)
        .value();

    case CTADATA_SUCCESS:
      return imm(state)
        .set("meta.isFetching", false)
        .set("meta.error", undefined)
        .set("data", action.payload)
        .set("dataSiteNotifications", action.payloadSiteNotification)
        .value();

    case CTADATA_FAILURE:
      return imm(state)
        .set("meta.isFetching", false)
        .set("meta.error", action.error)
        .value();

    case CTADATA_REFRESH:
      return imm.set(state, "meta.refreshDate", action.date);

    default:
      return state;
  }
};

export default callToAction;
