import {ACTIONS, ANIMATION_STATE} from './MapActions';
import itemsConfig from './ControlPanel/items.json';
import immutable from 'object-path-immutable';

function getInitialMapAnimation(){

    return {
        animState : ANIMATION_STATE.PAUSED,
        interval : -1
    }
}



export function mapAnimation(state = null, action){

    if(!state){
        return getInitialMapAnimation();
    }

    switch(action.type){

        case ACTIONS.START_ANIMATION_LOAD :
            return {
              ...state,
              animState: ANIMATION_STATE.LOADING
            };

        case ACTIONS.ANIMATION_STARTED :
            return {
              ...state,
              animState: ANIMATION_STATE.PLAYING
            };

        case ACTIONS.ANIMATION_PAUSED :
            return {
              ...state,
              animState: ANIMATION_STATE.PAUSED
            };

        case ACTIONS.ANIMATION_INTERVAL_CHANGED :
            return {
              ...state,
              interval: action.payload
            }

        default :
            return state;
    }
}


export function mapSiteFilter(state = null, action){

    if(!state){
        return {
            selectedSites : []
        }
    }

    let siteId = action.payload;
    const index = state.selectedSites.indexOf(siteId);
    switch(action.type){

        case ACTIONS.ADD_SITE_TO_SELECTED_SITES :            
            if(index < 0){
              return immutable.push(state, "selectedSites", siteId);
            } else return state;
        case ACTIONS.REMOVE_SITE_FROM_SELECTED_SITES :
            if(index >= 0){
                return immutable.del(state, `selectedSites.${index}`);
            } else return state;
        case ACTIONS.RESET_SELECTED_SITES :
            return immutable.set(state, "selectedSites", []);
        default :
            return state;
    }
}




function getInitialMapControls(){

    for(let item in itemsConfig){
        itemsConfig[item].opacity = itemsConfig[item].opacity ? itemsConfig[item].opacity : 1;
        itemsConfig[item].selected = !!itemsConfig[item].selected;
    }


    return {
        expanded : true,
        fullScreen : false,
        userLocation : null,
        zoom : null,
        zoomTime : null,
        items : itemsConfig,
        dotLegendsState : 'expanded',
    }
}

export function mapControls(state = null, { type, payload }){

    if(!state){
        return getInitialMapControls();
    }

    switch(type){

        case ACTIONS.CONTROL_PANEL_CHANGED :
          return immutable.set(state, "expanded", payload);

        case ACTIONS.SCREEN_CHANGED :
          return immutable.update(state, "fullScreen", v => !v);

        case ACTIONS.ITEM_SELECTED :
          return immutable(state)
            .set(['items', payload.id, 'selected'], payload.selected)
            .update(['items', 'winterAdvisories', 'selected'], selected => payload.id === 'winterAdvisories' ? payload.selected : selected)
            .update(['items', 'nonwinterAdvisories', 'selected'], selected => payload.id === 'nonwinterAdvisories' ? payload.selected : selected)
            .value();

        case ACTIONS.CHILD_ITEM_SELECTED :
          return immutable.set(
            state,
            ["items", payload.parentId, "childItems", payload.childId, "selected"],
            payload.selected
          );

        case ACTIONS.LAYER_OPACITY_CHANGED :
          return immutable.set(state, ["items", payload.id, "opacity"], payload.opacity);

        case ACTIONS.USER_LOCATION_FOUND:
          return immutable.set(state, "userLocation", payload.userLocation);

        case ACTIONS.MAP_ZOOM_CHANGED :
          return immutable(state)
            .set('zoom', payload.value)
            .set('zoomTime', Date.now())
            .value();
        
        case ACTIONS.DOT_LEGENDS_EXPANDED :
          return immutable.set(state, "dotLegendsState", "expanded");
        
        case ACTIONS.DOT_LEGENDS_COLLAPSED :
          return immutable.set(state, "dotLegendsState", "collapsed");

        default:
            return state;
    }
}

function getWeatherData(responseObservation){

    if(!responseObservation){
        return null;
    }

    return {
        date : new Date(responseObservation.dateTimeISO),
        temp : responseObservation.tempF,
        tempMin : responseObservation.minTempF,
        tempMax : responseObservation.maxTempF,
        code : responseObservation.weatherPrimaryCoded,
        weather : responseObservation.weather,
        icon : responseObservation.icon,
        winds : responseObservation.windMPH,
        dewPoint : responseObservation.dewpointF,
        humidity : responseObservation.humidity,
        pressure : responseObservation.pressureIN
    }
}

export function locationWeather(state = null, action){

    if(!state){

        return {
            location : null,
            time : null,
            weatherData : null
        };
    }

    switch(action.type){

        case ACTIONS.FETCH_LOCATION_WEATHER_SUCCESS:

            const responses = action.payload.response.data.response.responses;

            // Check if locaton is a valid place
            if(responses[4].error && responses[4].error.code === 'invalid_location'){

                return {
                    location : action.payload.location,
                    time : Date.now(),
                    weatherData : null
                }
            }

            // Parsing weather data

            const weatherData = {};

            if(responses[0].success){
                const forecastHours = responses[0].response[0].periods;
                weatherData.forecastHours = forecastHours.map(getWeatherData);
            }

            if(responses[1].success){
                const forecastDays = responses[1].response[0].periods;
                weatherData.forecastDays = forecastDays.map(getWeatherData)
            }

            if(responses[2].success){
                const observation =  responses[2].response.ob;
                weatherData.current = getWeatherData(observation);
            }

            if(responses[3].success){

                weatherData.advisories = responses[3].response.map(advisory => {

                    return {
                        details : advisory.details,
                        added : new Date(advisory.timestamps.addedISO),
                        begins : new Date(advisory.timestamps.beginsISO),
                        expires : new Date(advisory.timestamps.expires),
                    }
                })
            }

            const place = responses[4].response;
            weatherData.place = {
                latLng : {
                    lat : place.loc.lat,
                    lng : place.loc.long
                },
                country : place.place.country,
                name : place.place.name,
                state : place.place.state
            }


            return {
                location : action.payload.location,
                time : Date.now(),
                weatherData,
                showPanel: action.payload.showPanel
            }

        case ACTIONS.RESET_LOCATION_WEATHER:
            return {
                showPanel: false
            }

        default:
            return state;
    }
}



/*
 *  selectionType : 'select' or 'hover'
 *  selected : true or false
 */

export function selectedSite(state = {}, action){

    switch(action.type){

        case ACTIONS.SELECT_SITE :

            return {
                source : action.payload.source,
                siteId : action.payload.siteId,
                selectionType : action.payload.selectionType,
                selected : action.payload.selected,
                time : Date.now()
            }

        default :
            return state;
    }
}
