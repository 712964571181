import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Alert } from 'react-bootstrap';
import { percentToIn, INCHES10, INCHES2 } from 'common/components/FilterSlider';
import './ThreatFilter.css'
import { isAllThreatTypesTrue } from 'common/helpers/datahelpers';
import { NOTIME } from 'common/components/TimingSelector/TimingSelectorDuck';

const MAX_NAME_LENGTH = 70;

export const getThreatTypes = (typeVal = {}) => (
  isAllThreatTypesTrue(typeVal)
    ? 'All'
    : Object.keys(typeVal)
      .filter(t => typeVal[t] === true)
      .map(t => type2name(t))
      .join(', ')
)

const type2name = type => {
  switch(type) {
    case 'IC': return 'Ice Crystals'
    case 'IF': return 'Ice Fog'
    case 'IP': return 'Ice Pellets / Sleet'
    case 'S': return 'Snow'
    case 'SW': return 'Snow Showers'
    case 'ZF': return 'Freezing Fog'
    case 'ZL': return 'Freezing Drizzle'
    case 'RS': return 'Rain/Snow Mix'
    case 'SI': return 'Snow/Sleet Mix'
    case 'WM': return 'Wintry Mix'
    case 'ZR': return 'Freezing Rain'
    case 'BS': return 'Blowing Snow'
    case 'ZY': return 'Freezing Spray'
    case 'R': return 'Rain'
    case 'RW': return 'Rain Showers'
    case 'F': return 'Fog'
    case 'H': return 'Hail'
    case 'T': return 'Thunderstorms'
    default: return ''
  }
}

export const getLocation = locVal => {
  if (!locVal) return ''

  switch (locVal.radio) {
    case 'zipcode':
      return `Zip Code ${locVal.zipCode}`

    case 'statecity':
      return `${locVal.city}, ${locVal.fedState}`

    default: return ''
  }
}

class SaveThreatFilter extends React.Component {

  state = {
    filterName: '',
    touched: false,
    focused: false
  }



  handleNameChange = e => {
    let value = e.target.value
    this.setState({filterName: value.substring(0, MAX_NAME_LENGTH), touched: true})
  }

  handleFocus = e => this.setState({focused: true, touched: true})
  handleBlur = e => this.setState({focused: false})

  render() {
    const {popSliderVal, snowSliderVal, iceSliderVal, timingVal, locVal, typeVal} = this.props.filterOptions;

    return (
      <Modal show={this.props.showModal} onHide={this.props.closeModal}>

        <Modal.Header closeButton>
          <Modal.Title>Save filters as preset</Modal.Title>
        </Modal.Header>

        <Modal.Body>

          {this.props.error &&
            <Alert variant='warning'>{this.props.error}</Alert>
          }

          <div className='ThreatFilter_Subhead'>Enter a name</div>
          {!this.state.filterName && this.state.touched && !this.state.focused &&
            <div className='SaveThreatFilter__warn'>The filter must be named</div>
          }
          <input
            className='WA__name SaveThreatFilter--spacing'
            value={this.state.filterName}
            onChange={this.handleNameChange}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
          />

          <div className='ThreatFilter_Subhead'>Filters selected</div>

          {!!popSliderVal && <div className='SaveThreatFilter__item'>Event probability = {popSliderVal}%</div>}
          {!!snowSliderVal && <div className='SaveThreatFilter__item'>Event snow Amount = {percentToIn(snowSliderVal, INCHES10)} inches</div>}
          {!!iceSliderVal && <div className='SaveThreatFilter__item'>Event ice Amount = {percentToIn(iceSliderVal, INCHES2)} inches</div>}
          {timingVal !== NOTIME && <div className='SaveThreatFilter__item'>Threat Timing = {timingVal}</div>}
          {!!locVal && !!locVal.radio && <div className='SaveThreatFilter__item'>Location = {getLocation(locVal)}</div>}
          {!!typeVal && <div className='SaveThreatFilter__item'>Threat Type = {getThreatTypes(typeVal)}</div>}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary"
                  onClick={() => this.props.handleSave(this.state.filterName)}
                  disabled={!this.state.filterName}
          >Save &amp; Apply</Button>
          <Button variant="default" onClick={this.props.closeModal}>Cancel</Button>
        </Modal.Footer>

      </Modal>
    )
  }
}

SaveThreatFilter.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  filterOptions: PropTypes.object.isRequired
}

export default SaveThreatFilter
