import immutable from "object-path-immutable";

import { THREATFILTER_RESET } from "../ThreatFilter/ThreatFilterDuck";

export const ADD_QUICK_FILTER = "ADD_QUICK_FILTER";
export const TOGGLE_QUICK_FILTER = "TOGGLE_QUICK_FILTER";
const QUICKFILTER_SET_BOUNDS = "QUICKFILTER_SET_BOUNDS";

export const toggleQuickFilter = id => ({
  type: TOGGLE_QUICK_FILTER,
  id
});

export const addQuickFilter = (id, data, selected) => ({
  type: ADD_QUICK_FILTER,
  id: data.id,
  data,
  selected
});

export const setBounds = bounds => ({
  type: QUICKFILTER_SET_BOUNDS,
  bounds
});

const initialState = {
  bounds: {}
};

const quickFilters = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_QUICK_FILTER:
      return immutable(state)
        .update([action.id, "selected"], v => !v)
        .value();

    case THREATFILTER_RESET:
      // reset buttons but keep geometry
      return {
        bounds: state.bounds
      };

    case QUICKFILTER_SET_BOUNDS:
      return {
        ...state,
        bounds: action.bounds
      };

    default:
      return state;
  }
};

export default quickFilters;
