export const APIPACKAGE_SELECT_PACKAGE = 'APIPACKAGE_SELECT_PACKAGE';
export const APIPACKAGES_SUBMIT = 'APIPACKAGES_SUBMIT';
export const APIPACKAGES_FAILURE = 'APIPACKAGES_FAILURE';
export const APIPACKAGES_SUCCESS = 'APIPACKAGES_SUCCESS';

export const APIPACKAGES_BYID_SUBMIT = 'APIPACKAGES_BYID_SUBMIT';
export const APIPACKAGES_BYID_SUCCESS = 'APIPACKAGES_BYID_SUCCESS';
export const APIPACKAGES_BYID_FAILURE = 'APIPACKAGES_BYID_FAILURE';

export const APIPACKAGE_MODAL_STATE = 'APIPACKAGE_MODAL_STATE';

export const APIPACKAGEUPSERT_SUBMIT = 'APIPACKAGEUPSERT_SUBMIT';
export const APIPACKAGEUPSERT_SUCCESS = 'APIPACKAGEUPSERT_SUCCESS';
export const APIPACKAGEUPSERT_FAILURE = 'APIPACKAGEUPSERT_FAILURE';

export const APIPACKAGEDEL_SUBMIT = 'APIPACKAGEDEL_SUBMIT';
export const APIPACKAGEDEL_SUCCESS = 'APIPACKAGEDEL_SUCCESS';
export const APIPACKAGEDEL_FAILURE = 'APIPACKAGEDEL_FAILURE';

export const MODAL_CLOSED = 'closed';
export const MODAL_OPEN = 'open';
export const IS_ON_THE_FLY = true;

export const fetchApiPackages = () => ({
  type: APIPACKAGES_SUBMIT
});

export const fetchCompaniesForApiPackage = (apiPackageId) => ({
  type: APIPACKAGES_BYID_SUBMIT,
  apiPackageId
});

export const setSelectedApiPackage = (id) => ({
  type: APIPACKAGE_SELECT_PACKAGE,
  id
});

export const openUpsertModal = (id, isOnTheFly) => ({
  type: APIPACKAGE_MODAL_STATE,
  modalState: MODAL_OPEN,
  id,
  isOnTheFly
});

export const closeUpsertModal = () => ({
  type: APIPACKAGE_MODAL_STATE,
  modalState: MODAL_CLOSED
});

export const deleteApiPackage = (id) => ({
  type: APIPACKAGEDEL_SUBMIT,
  id
});

const initialState = {
  selectedApiPackageId: undefined,
  isFetching: false,
  data: [], // array of ApiPackages
  meta: {},
  companiesById: {}, // maps packageId to array of companies
  modalState: MODAL_CLOSED,
};

const apiPackages = (state = initialState, action) => {
  switch (action.type) {
    case APIPACKAGE_SELECT_PACKAGE:
      return {
        ...state,
        meta: {...state.meta, selectedApiPackageId: action.id}
      };

    case APIPACKAGES_SUBMIT:
      return {
        ...state,
        isFetching: true,
      }

    case APIPACKAGES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data
      }

    case APIPACKAGES_BYID_SUBMIT:
      return {
        ...state,
        isFetching: true
      }

    case APIPACKAGES_BYID_SUCCESS:
      return {
        ...state,
        isFetching: false,
        companiesById: {...state.companiesById, [action.apiPackageId]: action.companyIds},
      }

    case APIPACKAGES_BYID_FAILURE:
    case APIPACKAGES_FAILURE:
    case APIPACKAGEDEL_FAILURE:
      return {
        ...state,
        isFetching: false,
        meta: {...state.meta, fetchError: action.message}
      }

    case APIPACKAGE_MODAL_STATE:
      return {
        ...state,
        meta: {...state.meta, modalState: action.modalState, idToEdit: action.id, isOnTheFly: action.isOnTheFly}
      }

    case APIPACKAGEUPSERT_SUCCESS:
      return {
        ...state,
        data: [action.data, ...state.data]
          .reduce((acc,ap) => {
            // if updating (not adding) element, remove old element
            if (!acc.find(a=>a.id===ap.id)) acc.push(ap);
            return acc;
          }, []),
        meta: {
          ...state.meta, 
          modalState: MODAL_CLOSED,
        }
      }

    case APIPACKAGEDEL_SUBMIT:
      return {
        ...state,
        isFetching: true
      }

    case APIPACKAGEDEL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: state.data.filter(a => a.id !== action.id)
      }

    default :
      return state;
  }
};

export default apiPackages

