import React from 'react';
import PropTypes from 'prop-types';
import './SearchBox.scss';
import { CLOSED, HOVERED, FOCUSED, BLURRED } from './SearchBoxDuck'

class SearchBox extends React.Component {

  searchBoxStateClass = (boxState, searchTerm) => {
    if (boxState === HOVERED) return ' SearchBox_Hovered';
    if (boxState === FOCUSED) return ' SearchBox_Focused';
    if (boxState === BLURRED) return ' SearchBox_Hovered';
    return '';
  }

  // what to do with the styling of search box depending on current state, mouse
  // action and box content
  changedSearchBoxState = (boxState= CLOSED, mouseState, searchTerm) => {
    //console.log(boxState, mouseState, searchTerm)
    switch (mouseState) {
      case 'enter':
        if (boxState === CLOSED) return HOVERED;
        return boxState;

      case 'out': // don't minimize the box if something is in there
        if (boxState === HOVERED && !searchTerm) return CLOSED;
        return boxState;

      case 'blur':
        if (!searchTerm) {return CLOSED} else {return BLURRED}
      case 'focus':
        return FOCUSED;

      default: return boxState;
    }
  }

  render () {
    const { searchBoxState, searchTerm, setSearchTerm, setSearchBoxState, placeHolder, width=230, boxId } = this.props;
    return (
      <React.Fragment>
        <style>{`
          .SearchBox_Hovered {
            width: ${width}px;
          }
          .SearchBox_Focused {
            width: ${width}px;
          }
        `}</style>
          <input 
            id={boxId}
            className={'SearchBox' + this.searchBoxStateClass(searchBoxState, searchTerm)}
            type='search' 
            placeholder={searchBoxState !== CLOSED ? (placeHolder||'Enter search term'):''}
            value={searchTerm}
            onClick={() => setSearchBoxState(FOCUSED)} 
            onMouseEnter={() => setSearchBoxState(this.changedSearchBoxState(searchBoxState, 'enter', searchTerm))}
            onMouseOut={() => setSearchBoxState(this.changedSearchBoxState(searchBoxState, 'out', searchTerm))}
            onBlur={() => setSearchBoxState(this.changedSearchBoxState(searchBoxState, 'blur', searchTerm))}
            onFocus={() => setSearchBoxState(this.changedSearchBoxState(searchBoxState, 'focus', searchTerm))}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </React.Fragment>
    )
  }
}

SearchBox.propTypes = {
  searchBoxState: PropTypes.string,
  searchTerm: PropTypes.string,
  placeHolder: PropTypes.string,
  setSearchTerm: PropTypes.func.isRequired,
  setSearchBoxState: PropTypes.func.isRequired,
  onChange: PropTypes.func, // being called after setSearchTerm() in container
  alwaysOpen: PropTypes.bool
}
export default SearchBox;
