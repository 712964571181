// This file implements the "ducks" pattern, i.e. combines action constants,
// action creators and reducers in one file instead of having multiple files per
// function. As long as there are not too many values, this makes it nice and
// compact.

// Action constants

import { NOTIFICATION_SET_READ } from "../NotificationTile/NotificationTileDuck";
import uniq from "lodash/uniq";
import intersection from "lodash/intersection";

export const NOTIFICATIONLIST_TOGGLE = "NOTIFICATIONLIST_TOGGLE";
export const NOTIFICATIONLIST_SET_VISIBLENOTIFICATIONS =
  "NOTIFICATIONLIST_SET_VISIBLENOTIFICATIONS";
export const NOTIFICATIONLIST_APPLY_FILTER = "NOTIFICATIONLIST_APPLY_FILTER";
export const NOTIFICATIONLIST_OPEN_SITES_MODAL =
  "NOTIFICATIONLIST_OPEN_SITES_MODAL";
export const NOTIFICATIONLIST_CLOSE_SITES_MODAL =
  "NOTIFICATIONLIST_CLOSE_SITES_MODAL";
export const NOTIFICATIONLIST_SET_INDICATOR = "NOTIFICATIONLIST_SET_INDICATOR";
export const NOTIFICATIONLIST_SET_ALL_READ = "NOTIFICATIONLIST_SET_ALL_READ";

// Action creators

export const toggleFilterView = () => ({
  type: NOTIFICATIONLIST_TOGGLE
});

export const applyFilters = () => ({
  type: NOTIFICATIONLIST_APPLY_FILTER
});

export const setNotificationIndicator = count => ({
  type: NOTIFICATIONLIST_SET_INDICATOR,
  count
});

export const openModal = () => ({
  type: NOTIFICATIONLIST_OPEN_SITES_MODAL
});

export const closeModal = () => ({
  type: NOTIFICATIONLIST_CLOSE_SITES_MODAL
});

export const setAllRead = ids => ({
  type: NOTIFICATIONLIST_SET_ALL_READ,
  ids
});

// Reducer

const initialState = {
  isFilterViewOpen: false,
  showModal: false,
  visibleNotifications: [], // IDs of notifications that should be visible by all filter criteria
  indicatorCount: 0,
  unreadCount: 0,
  readIds: []
};

const notificationListReducer = (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATIONLIST_TOGGLE:
      return {
        ...state,
        isFilterViewOpen: !state.isFilterViewOpen
      };

    case NOTIFICATIONLIST_SET_VISIBLENOTIFICATIONS:
      return {
        ...state,
        visibleNotifications: action.visibleNotifications
      };

    case NOTIFICATIONLIST_OPEN_SITES_MODAL:
      return {
        ...state,
        showModal: true
      };

    case NOTIFICATIONLIST_CLOSE_SITES_MODAL:
      return {
        ...state,
        showModal: false
      };

    case NOTIFICATIONLIST_SET_INDICATOR:
      const newReadIds = intersection(action.ids, state.readIds);
      return {
        ...state,
        indicatorCount: action.count,
        unreadCount: action.count - newReadIds.length,
        readIds: newReadIds
      };

    case NOTIFICATION_SET_READ:
      const updatedReadIds = uniq([...state.readIds, action.tileId]);
      return {
        ...state,
        readIds: updatedReadIds,
        unreadCount: state.indicatorCount - updatedReadIds.length 
      };

    default:
      return state;
  }
};

export default notificationListReducer;
