import { connect } from 'react-redux'
import FilterSliderComponent from './FilterSlider'
import { setValue } from './FilterSliderDuck'

const mapStateToProps = ({filterslider}, ownProps) => ({
  value: (filterslider[ownProps.sliderId] && filterslider[ownProps.sliderId].value) || 0
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setValue: (value) => {
    dispatch(setValue(ownProps.sliderId, value))
  },
})

const FilterSliderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterSliderComponent)

export default FilterSliderContainer;


