export const CLOSED = 'CLOSED';
export const HOVERED = 'HOVERED';
export const BLURRED = 'BLURRED';
export const FOCUSED = 'FOCUSED';


// Action constants

export const SEARCHBOX_SET_SEARCHTERM = 'SEARCHBOX_SET_SEARCHTERM';
export const SEARCHBOX_SET_SEARCHBOXSTATE = 'SEARCHBOX_SET_SEARCHBOXSTATE';
export const SEARCHBOX_RESET = 'SEARCHBOX_RESET';

// Reducer

const initialState = {
};

// Action creators

export const setSearchTerm = (boxId, value) => ({
  type: SEARCHBOX_SET_SEARCHTERM,
  boxId, value
})

export const setSearchBoxState = (boxId, boxState) => ({
  type: SEARCHBOX_SET_SEARCHBOXSTATE,
  boxId, boxState
})


export const reset = (boxId) => ({
  type: SEARCHBOX_RESET,
  boxId
})

// Reducer

const searchBoxReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCHBOX_RESET:
      return {
        ...state,
        [action.boxId]: {value: '', boxState: CLOSED}
      }

    case SEARCHBOX_SET_SEARCHTERM:
      return {
        ...state,
        [action.boxId]: {...state[action.boxId], value: action.value}
      }
    case SEARCHBOX_SET_SEARCHBOXSTATE:
      return {
        ...state,
        [action.boxId]: {...state[action.boxId], boxState: action.boxState}
      }
    default:
      return state

  }
}

export default searchBoxReducer;


