import log from 'loglevel';
import { put, takeEvery, call } from 'redux-saga/effects'

import {
  FORGOTPASS_SUBMIT,
  FORGOTPASS_SUCCESS,
  FORGOTPASS_FAILURE
} from './ForgotPassDuck'
import fetchApi from 'utils/fetchApi'

export function* fetchForgotPassSaga (action) {
  try {
    // redux-form-submit-saga injects 'payload' into action
    const { email } = action.payload;
    const url = '/persons/reset';
    const payload = { email: email.trim() };

    // call API
    const data = yield call(fetchApi, url, { payload })
    if (!data.error){
      yield put({ type: FORGOTPASS_SUCCESS })
    } else {
      yield put({
        type: FORGOTPASS_FAILURE,
        message: data.error.text._error
          ? data.error.text._error
          : data.error.text.email
      })
    }

  } catch (error) {
    log.error('fetchForgotPassSaga error', error)
    yield put({ type: FORGOTPASS_FAILURE,
      message: 'Network error.',
    })
  }
}


// listen for actions of type FORGOTPASS_SUBMIT and use them
export default function* forgotPassSaga () {
  yield takeEvery(FORGOTPASS_SUBMIT, fetchForgotPassSaga)
}

