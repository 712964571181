import React, { Component, Suspense } from "react";
import log from "loglevel";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import AsyncLoadingSpinner from "common/components/AsyncLoadingSpinner";
import { isDemoSystem } from 'common/helpers/helpers';

import "./App.scss";
import iOS from "common/helpers/ios-detector";

const MarketingMainLazy = React.lazy(() => import('Marketing'));
const MarketingMain = () => {
  return (
    <div>
      <Suspense fallback={<AsyncLoadingSpinner />}>
        <MarketingMainLazy />
      </Suspense>
    </div>
  );
}

const AppLazy = React.lazy(() => import('App'));
const App = () => {
  return (
    <div>
      <Suspense fallback={<AsyncLoadingSpinner />}>
        <AppLazy />
      </Suspense>
    </div>
  );
}

/*
 * Here, the site's routes are defined and split for async loading:
 * "/" for marketing page ("fmsweather.com") and
 * everything else for the app itself.
 */

class Site extends Component {
  constructor(props) {
    super(props);
    if (iOS) {
      log.info(`iOS version: ${iOS}`);
      this.state = { isUnsupported: Math.floor(+iOS) < 9, iOS: iOS };
    } else {
      this.state = { isUnsupported: false };
    }
  }

  render() {
    document
      .getElementById("viewport")
      .setAttribute(
        "content",
        "width=device-width, initial-scale=1, shrink-to-fit=no"
      );

    return (
      <div>
        {this.state.isUnsupported ? (
          <div>This version of iOS {this.state.iOS} is unsupported.</div>
        ) : (
          <Router>
            <div className="App">
              <Switch>
                <Route path="/" exact >
                  {
                    isDemoSystem()
                      ? <Redirect to='/login' />
                      : <MarketingMain/>
                  }
                </Route>
                <Route path="/" component={App} />
              </Switch>
            </div>
          </Router>
        )}
      </div>
    );
  }
}

export default Site;
