export const SITE_ACTIVATE_SUBMIT = "SITE_ACTIVATE_SUBMIT";
export const SITE_ACTIVATE_SUCCESS = "SITE_ACTIVATE_SUCCESS";
export const SITE_ACTIVATE_FAILURE = "SITE_ACTIVATE_FAILURE";
export const BULK_SITES_ACTIVATE_SUBMIT = "BULK_SITES_ACTIVATE_SUBMIT";
export const BULK_SITES_ACTIVATE_SUCCESS = "BULK_SITES_ACTIVATE_SUCCESS";
export const BULK_SITES_ACTIVATE_FAILURE = "BULK_SITES_ACTIVATE_FAILURE";

export const setSiteActive = (siteId, active) => ({
  type: SITE_ACTIVATE_SUBMIT,
  siteId,
  active
});

export const setAllActive = (companyId, active, siteIds) => ({
  type: BULK_SITES_ACTIVATE_SUBMIT,
  companyId,
  active,
  siteIds
});

const handleUploadErrors = data => {
  switch (data.code) {
    case "INTERNAL":
      return {
        _error: "Internal server error."
      };
    case "NO_COMPANY":
      return {
        _error: "No company found."
      };
    case "NO_USER":
      return {
        _error: "No user found."
      };
    case "NOT_AUTHORIZED":
      return {
        _error: "Not authorized."
      };
    case "NO_ACTIVE_SUBS":
      return {
        _error: "Company doesn't have an active subscription."
      };
    case "NO_SITE_SLOT":
      return {
        _error: `Not enough free spaces left in Subscription for active Sites. 
        Subscription Limit: ${data.limit},
        Needed: ${data.targetActiveCount}`
      };

    default:
      return "";
  }
};

const initialState = {
  isFetching: false,
  errorMessage: undefined,
  activateSuccess: undefined,
  activateError: undefined,
};

const siteAdmin = (state = initialState, action) => {
  switch (action.type) {
    case SITE_ACTIVATE_SUBMIT:
      return {
        ...state,
        isFetching: true
      };

    case SITE_ACTIVATE_SUCCESS:
      return initialState;

    case SITE_ACTIVATE_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.message
      };

    case BULK_SITES_ACTIVATE_SUBMIT:
      return {
        ...state,
        isFetching: true,
        errorMessage: undefined,
        activateSuccess: undefined,
        activateError: undefined
      };

    case BULK_SITES_ACTIVATE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        activateSuccess: !action.data.code,
        activateError: handleUploadErrors(action.data),
        invalidSiteIds: action.data.invalidSiteIds || [],
        alreadyActiveSiteIds: action.data.alreadyActiveSiteIds || [],
        activatedSiteIds: action.data.activatedSiteIds || [],
      };

    case BULK_SITES_ACTIVATE_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.message,
        activateSuccess: false,
      };

    default:
      return state;
  }
};

export default siteAdmin;
