// This file implements the "ducks" pattern, i.e. combines action constants,
// action creators and reducers in one file instead of having multiple files per
// function. As long as there are not too many values, this makes it nice and
// compact.

import { DATA_SUCCESS } from 'common/dataRetrieval/DataDuck';

// Action constants

export const THREATLIST_TOGGLE = 'THREATLIST_TOGGLE';
export const THREATLIST_SHOW_EXPANDED_TILE = 'THREATLIST_SHOW_EXPANDED_TILE';
export const THREATLIST_EXPANDED_TILE_CLICKED = 'THREATLIST_EXPANDED_TILE_CLICKED';
export const THREATLIST_SET_VISIBLESITES = 'THREATLIST_SET_VISIBLESITES';
export const THREATLIST_APPLY_FILTER = 'THREATLIST_APPLY_FILTER';
export const THREATLIST_SET_PAGE = 'THREATLIST_SET_PAGE';
const THREATLIST_SET_HEADER_BOUNDS = 'THREATLIST_SET_HEADER_BOUNDS';
export const THREATLIST_SET_OFFSET_Y = 'THREATLIST_SET_OFFSET_Y';
export const THREATLIST_TOGGLE_HIDDENSITES = 'THREATLIST_TOGGLE_HIDDENSITES';

// Action creators

export const toggleFilterView = () => ({
  type: THREATLIST_TOGGLE
})


// sets the threat id of the expanded tile, i.e. whether the user has clicked
// into it - so other components can read this state and react on it
export const expandedTileClicked = (siteId) => ({
  type: THREATLIST_EXPANDED_TILE_CLICKED,
  siteId
})

export const setVisibleSites = (visibleSites) => ({
  type: THREATLIST_SET_VISIBLESITES,
  visibleSites
})

export const applyFilters = () => ({
  type: THREATLIST_APPLY_FILTER
})

export const setPage = (pageNo) => ({
  type: THREATLIST_SET_PAGE,
  pageNo
})

export const setBounds = bounds => ({
  type: THREATLIST_SET_HEADER_BOUNDS,
  bounds
});

export const toggleHiddenSitesVisibility = _ => ({
  type: THREATLIST_TOGGLE_HIDDENSITES
});

// Reducer

const initialState = {
  visibleSites: [],
  hiddenSites: [],
  isHiddenSitesVisible: false,
  manualSelectionSites: undefined,
  isFilterViewOpen: false,
  expandedThreatClicked: undefined,
  currentPage: 0,
  pageSize: 10,
  bounds: {}
};

const threatListReducer = (state = initialState, action) => {
  switch (action.type) {
    case THREATLIST_TOGGLE:
      return {
        ...state,
        isFilterViewOpen: !state.isFilterViewOpen
      }

    case THREATLIST_EXPANDED_TILE_CLICKED:
      return {
        ...state,
        expandedThreatClicked: action.siteId
      }

    case THREATLIST_SET_VISIBLESITES:
      return {
        ...state,
        visibleSites: action.visibleSites,
        hiddenSites: action.hiddenSites,
        sitesWithEventsCount: action.sitesWithEventsCount,
        manualSelectionSites: action.manualSelectionSites
      }

    case THREATLIST_SET_PAGE:
      return {
        ...state,
        currentPage: action.pageNo
      }

    case THREATLIST_SET_HEADER_BOUNDS:
      return {
        ...state,
        bounds: action.bounds
      }

    case DATA_SUCCESS:
      return {
        ...state,
        currentPage: 0
      }

    case THREATLIST_SET_OFFSET_Y:
      return {
        ...state,
        offsetY: action.offsetY
      }

    case THREATLIST_TOGGLE_HIDDENSITES:
      return {
        ...state,
        isHiddenSitesVisible: !state.isHiddenSitesVisible
      }

    default:
      return state

  }
}

export default threatListReducer;

