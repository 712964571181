export const APIAPPS_SUBMIT = 'APIAPPS_SUBMIT';
export const APIAPPS_SUCCESS = 'APIAPPS_SUCCESS';
export const APIAPPS_FAILURE = 'APIAPPS_FAILURE';

export const APIAPPSUPSERT_SUBMIT = 'APIAPPSUPSERT_SUBMIT';
export const APIAPPSUPSERT_SUCCESS = 'APIAPPSUPSERT_SUCCESS';
export const APIAPPSUPSERT_FAILURE = 'APIAPPSUPSERT_FAILURE';

export const APIAPP_SELECT_APIAPP = 'APIAPP_SELECT_APIAPP';
export const APIAPP_MODAL_STATE = 'APIAPP_MODAL_STATE';

export const MODAL_CLOSED = 'closed';
export const MODAL_OPEN = 'open';

export const APIAPPDEL_SUBMIT = 'APIAPPDEL_SUBMIT';
export const APIAPPDEL_SUCCESS = 'APIAPPDEL_SUCCESS';
export const APIAPPDEL_FAILURE = 'APIAPPDEL_FAILURE';

export const loadApiApps = companyId => ({
  type: APIAPPS_SUBMIT,
  companyId
})

export const setSelectedApiApp = id => ({
  type: APIAPP_SELECT_APIAPP,
  id
})

export const openUpsertModal = (id) => ({
  type: APIAPP_MODAL_STATE,
  modalState: MODAL_OPEN,
  id
});

export const closeUpsertModal = () => ({
  type: APIAPP_MODAL_STATE,
  modalState: MODAL_CLOSED
});

export const deleteApiApp = (id) => ({
  type: APIAPPDEL_SUBMIT,
  id
})

const initialState = {
  isFetching: false,
  data: [], // array of ApiApps
  meta: {},
  modalState: MODAL_CLOSED,
  selectedApiApp: undefined,
};

const apps = (state = initialState, action) => {
  switch (action.type) {
    case APIAPP_SELECT_APIAPP:
      return {
        ...state,
        meta: {...state.meta, selectedApiApp: action.id}
      };

    case APIAPPS_SUBMIT:
      return {
        ...state,
        isFetching: true
      }

    case APIAPPS_SUCCESS:
      return {
        ...state,
        data: action.data,
        isFetching: false,
        meta: {...state.meta, fetchError: undefined}
      }

    case APIAPPS_FAILURE:
      return {
        ...state,
        isFetching: false,
        meta: {...state.meta, fetchError: action.message}
      }

    case APIAPP_MODAL_STATE:
      return {
        ...state,
        meta: {...state.meta, modalState: action.modalState, idToEdit: action.id}
      }

    case APIAPPSUPSERT_SUBMIT:
      return {
        ...state,
        isFetching: true
      }

    case APIAPPSUPSERT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: [action.data, ...state.data]
          .reduce((acc,ap) => {
            // if updating (not adding) element, remove old element
            if (!acc.find(a=>a.id===ap.id)) acc.push(ap);
            return acc;
          }, []),
        meta: {...state.meta, modalState: MODAL_CLOSED, idToEdit: undefined}
      }

    case APIAPPSUPSERT_FAILURE:
      return {
        ...state,
        isFetching: false,
        meta: {...state.meta, modalState: MODAL_CLOSED, fetchError: action.message}
      }

    case APIAPPDEL_SUBMIT:
      return {
        ...state,
        isFetching: true
      }

    case APIAPPDEL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: state.data.filter(a => a.id !== action.id)
      }


    default:
      return state;
  }
};

export default apps;
