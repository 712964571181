// This file implements the "ducks" pattern, i.e. combines action constants,
// action creators and reducers in one file instead of having multiple files per
// function. As long as there are not too many values, this makes it nice and
// compact.

export const COMPANY_SITES = "Company Sites";
export const MY_SITES = "My Sites";

export const ACTIVE_WINTER_SITES = "Active Winter Sites";
export const ACTIVE_NONWINTER_SITES = "Active Non-Winter Sites";

// Action constants

export const SITEFILTER_SELECT = 'SITEFILTER_SELECT';

// Reducer

const initialState = {
  selectedFilter: COMPANY_SITES,
};


// Action creators

export const selectSiteFilter = (filter) => ({
  type: SITEFILTER_SELECT,
  filter
})

// Reducer

const siteFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case SITEFILTER_SELECT:
      return {
        ...state,
        selectedFilter: action.filter
      }
    default:
      return state

  }
}

export default siteFilterReducer;


