export const IMAGE_UPLOAD_SUBMIT = 'IMAGE_UPLOAD_SUBMIT';
export const IMAGE_UPLOAD_SUCCESS = 'IMAGE_UPLOAD_SUCCESS';
export const IMAGE_UPLOAD_FAILURE = 'IMAGE_UPLOAD_FAILURE';

export const IMAGE_FETCH_SUBMIT = 'IMAGE_FETCH_SUBMIT';
export const IMAGE_FETCH_SUCCESS = 'IMAGE_FETCH_SUCCESS';
export const IMAGE_FETCH_FAILURE = 'IMAGE_FETCH_FAILURE';

export const uploadImage = (imgType, file, fileName, companyId) => ({
  type: IMAGE_UPLOAD_SUBMIT,
  file,
  fileName,
  imgType, // 'avatar', 'companyLogo'
  companyId
})

export const fetchImage = (imgType, id) => ({
  type: IMAGE_FETCH_SUBMIT,
  imgType,
  id
});

const initialState = {
  isUploading: false,
  imageUploaded: false,
  isFetching: false,
  imageFound: false,
  imageFetchError: '',
  noImageFound: undefined
}

const imageUpload = (state = initialState, action) => {
  switch (action.type) {
    case IMAGE_UPLOAD_SUBMIT:
      return {
        ...state,
        isUploading: true,
        imageUploaded: false,
        imgType: action.imgType
      }
    case IMAGE_UPLOAD_SUCCESS:
      return {
        ...state,
        isUploading: false,
        imageError: undefined,
        imageUploaded: true
      }
    case IMAGE_UPLOAD_FAILURE:
      return {
        ...state,
        isUploading: false,
        imageError: action.payload,
        imageUploaded: false
      }

    case IMAGE_FETCH_SUBMIT:
      return {
        ...state,
        isFetching: true,
        imageFound: false,
        noImageFound: undefined
      }

    case IMAGE_FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        imageFound: true,
        data: action.data,
        id: action.id,
        noImageFound: false
      }

    case IMAGE_FETCH_FAILURE:
      return {
        ...state,
        isFetching: false,
        imageFound: false,
        imageFetchError: action.apiError,
        noImageFound: action.noImageFound
      }

    default:
      return state

  }
}

export default imageUpload;
