import immutable from 'object-path-immutable';
export const NEW_DATA_AVAILABLE = 'NEW_DATA_AVAILABLE';
export const NEW_DATA_RESET = 'NEW_DATA_RESET';
export const SERVER_FEATURES_SUBMIT = 'SERVER_FEATURES_SUBMIT';
export const SERVER_FEATURES_SUCCESS = 'SERVER_FEATURES_SUCCESS';
export const SERVER_FEATURES_FAILURE = 'SERVER_FEATURES_FAILURE';
export const SET_SERVER_FEATURES_SUBMIT = 'SET_SERVER_FEATURES_SUBMIT';
export const SET_SERVER_FEATURES_SUCCESS = 'SET_SERVER_FEATURES_SUCCESS';
export const SET_SERVER_FEATURES_FAILURE = 'SET_SERVER_FEATURES_FAILURE';

export const newDataAvailable = category => {
  return {
    type: NEW_DATA_AVAILABLE,
    category
  };
};

export const newDataReset = category => {
  return {
    type: NEW_DATA_RESET,
    category
  };
};

export const fetchServerFeatures = () => ({
  type: SERVER_FEATURES_SUBMIT
});

export const setLiveDemoMode = liveDemoMode => ({
  type: SET_SERVER_FEATURES_SUBMIT, 
  field: 'liveDemoMode',
  value: liveDemoMode
});

const initialState = {
  sites: {date: undefined, isReadyToRefresh: false},
  code: {version: undefined, isReadyToRefresh: false},
  serverFeatures: {},
  meta: {isFetching: false}
};

const pollNewData = (state = initialState, action) => {
  switch (action.type) {
    case NEW_DATA_AVAILABLE:
      return {
        ...state,
        [action.category]: {version: action.version, date: action.date, isReadyToRefresh: action.isReadyToRefresh}
      };

    case NEW_DATA_RESET:
    return {
      ...state,
      [action.category]: {...state[action.category], isReadyToRefresh: false}
    }

    case SERVER_FEATURES_SUCCESS:
      return immutable(state)
        .set(`serverFeatures`, {...action.features})
        .set('meta.isFetching', false)
        .set('meta.apiError', undefined)
        .value();

    case SERVER_FEATURES_FAILURE:
      return immutable(state)
        .set(`meta.isFetching`, false)
        .set('meta.apiError', action.message)
        .value();

    case SET_SERVER_FEATURES_SUBMIT:
      return immutable.set(state, 'meta.isFetching', true);

    case SET_SERVER_FEATURES_SUCCESS:
      return immutable(state)
        .set('meta.isFetching', false)
        .set('meta.liveDemoDataError', undefined)
        .value();

    case SET_SERVER_FEATURES_FAILURE:
      return immutable(state)
        .set('meta.isFetching', false)
        .set('meta.liveDemoDataError', action.message)
        .value();

    default:
      return state;
  }
};

export default pollNewData;
