import log from 'loglevel';
import { put, takeEvery, call, select } from 'redux-saga/effects';
import { SITE_PERSONDATA_SUBMIT } from 'common/dataRetrieval/SiteDuck';
import { BULK_RESET } from 'Admin/BulkSiteAssignment/BulkSiteAssignmentDuck';
import { isThirdPartyUser } from 'common/helpers/helpers';

import {
  USERUNASSIGN_CLOSE,
  USERUNASSIGN_SUBMIT,
  USERUNASSIGN_SUCCESS,
  USERUNASSIGN_FAILURE,
  USERUNASSIGN_BULK_SUBMIT,
  USERUNASSIGN_BULK_SUCCESS,
  USERUNASSIGN_BULK_FAILURE
} from './UserUnassignDuck';
import fetchApi from 'utils/fetchApi';

function* fetchUserUnassignSaga(action) {
  try {
    const { person, siteId } = action.payload;
    const personId = person.id;
    const companyId = person.companyId;

    const url = isThirdPartyUser(person)
      ? `/ThirdPartyUsers/${personId}/Sites/rel/${siteId}`
      : `/Persons/${personId}/Sites/rel/${siteId}`;

    const authToken = yield select(state => state.login.authToken);
    const data = yield call(fetchApi, url, { method: 'DELETE', authToken });

    if (!data.error){
      yield put({ type: SITE_PERSONDATA_SUBMIT, id: siteId, companyId });
      yield put({ type: USERUNASSIGN_SUCCESS, siteId: siteId, userId: personId });
      yield put({ type: USERUNASSIGN_CLOSE });
      yield put({
        type: '@@redux-form/RESET',
        meta: { form: 'UserUnassignForm' }
      });
    } else {
      yield put({
        type: USERUNASSIGN_FAILURE,
        message: data.error.text._error ? data.error.text._error : data.error.text,
        payload: data.error.text,
      });
    }

  } catch (error) {
    log.error('fetchUserUnassignSaga error', error);
    yield put({
      type: USERUNASSIGN_FAILURE,
      message: 'Network error.'
    });
  }
}

// for a single site, remove associations to list of users (objects)
function* bulkUserUnassignSaga(action) {
  try {
    const { siteId, userArr } = action;
    const authToken = yield select(state => state.login.authToken);

    let fetchError;
    for (let user of userArr) {
      const url = isThirdPartyUser(user)
        ? `/ThirdPartyUsers/${user.id}/Sites/rel/${siteId}`
        : `/Persons/${user.id}/Sites/rel/${siteId}`;
      const data = yield call(fetchApi, url, { method: 'DELETE', authToken });
      if (data.error) {
        fetchError = data.error
        break;
      }
    }

    if (!fetchError){
      for (let user of userArr)
        yield put({
          type: USERUNASSIGN_SUCCESS,
          siteId,
          userId: user.id
        });

      yield put({ type: USERUNASSIGN_BULK_SUCCESS });

      yield put({ type: BULK_RESET });
    } else {
      yield put({
        type: USERUNASSIGN_BULK_FAILURE,
        message: fetchError.text._error ? fetchError._error : fetchError.text,
        payload: fetchError.text._error ? fetchError._error : fetchError.text,
      });
    }
  } catch (error) {
    log.error('bulkUserUnassignSaga error', error);
    yield put({
      type: USERUNASSIGN_BULK_FAILURE,
      message: 'Network error.'
    });
  }
}

// listen for actions of type UserUnassign_SUBMIT and use them
export default function* userUnassignSaga() {
  yield takeEvery(USERUNASSIGN_SUBMIT, fetchUserUnassignSaga);
  yield takeEvery(USERUNASSIGN_BULK_SUBMIT, bulkUserUnassignSaga);
}
