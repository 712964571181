import log from 'loglevel';
import { call, put, takeEvery } from 'redux-saga/effects'

import { RESETPASS_FAILURE, RESETPASS_SUBMIT, RESETPASS_SUCCESS } from './ResetPassDuck'
import fetchApi from 'utils/fetchApi'

export function* fetchResetPassSaga(action) {
  try {
    // redux-form-submit-saga injects 'payload' into action
    const {newPassword, accessToken} = action.payload;
    const url = '/persons/reset-password';
    const payload = {newPassword};

    const data = yield call(fetchApi, url, {payload, authToken: accessToken})

    if (!data.error) {
      yield put({type: RESETPASS_SUCCESS})
    } else {
      yield put({
        type: RESETPASS_FAILURE,
        message: data.error.text._error ? data.error.text._error : data.error.text,
      })
    }

  } catch (error) {
    log.error('fetchResetPassSaga error', error);
    yield put({
      type: RESETPASS_FAILURE,
      message: 'Network error.'
    });
  }
}

// listen for actions of type RESETPASS_SUBMIT and use them
export default function* resetPassSaga() {
  yield takeEvery(RESETPASS_SUBMIT, fetchResetPassSaga)
}

