
import { connect } from 'react-redux'
import ThreatTypeSelectorComponent from './ThreatTypeSelector'
import { selectAllThreats, selectThreat, allThreatsFalse, resetCheckboxes } from './ThreatTypeSelectorDuck'

const mapStateToProps = ({threattypeselector}, ownProps) => ({
  id: ownProps.cbGroupId,
  threatTypes: threattypeselector[ownProps.cbGroupId] 
                ? threattypeselector[ownProps.cbGroupId].threatTypes 
                : allThreatsFalse,
  allSelected: threattypeselector[ownProps.cbGroupId] && threattypeselector[ownProps.cbGroupId].allSelected,
  initialValues: ownProps.initialValues
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  selectAllThreats: (value) => {
    dispatch(selectAllThreats(ownProps.cbGroupId, value))
  },
  selectThreat: (threat, value) => {
    dispatch(selectThreat(ownProps.cbGroupId, threat, value))
  },
  resetCheckboxes: () => dispatch(resetCheckboxes(ownProps.cbGroupId)),
})

const ThreatTypeSelectorContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ThreatTypeSelectorComponent)

export default ThreatTypeSelectorContainer;


