export const SUPPORTEMAIL_SUBMIT = 'SUPPORTEMAIL_SUBMIT';
export const SUPPORTEMAIL_SUCCESS = 'SUPPORTEMAIL_SUCCESS';
export const SUPPORTEMAIL_FAILURE = 'SUPPORTEMAIL_FAILURE';

const initialState = {
  isFetching: false,
  files: []
};

const supportEmailForm = (state = initialState, action) => {
  switch (action.type) {
    case SUPPORTEMAIL_SUBMIT:
      return {
        ...state,
        isFetching: true
      };

    case SUPPORTEMAIL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        message: '',
        files: []
      };

    case SUPPORTEMAIL_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: action.message
      };

    default: 
      return state;
  }
};

export default supportEmailForm;
