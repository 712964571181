export const WATCHADD_OPEN = 'WATCHADD_OPEN';
export const WATCHADD_CLOSE = 'WATCHADD_CLOSE';
export const WATCHADD_SET_PAGE = 'WATCHADD_SET_PAGE';
export const WATCHADD_SET_INITIALIZED = 'WATCHADD_SET_INITIALIZED';
export const WATCHADD_SAVE = 'WATCHADD_SAVE';
export const WATCHADD_SET_NAME = 'WATCHADD_SET_NAME';

export const WATCHADD_SUBMIT = 'WATCHADD_SUBMIT';
export const WATCHADD_SUCCESS = 'WATCHADD_SUCCESS';
export const WATCHADD_FAILURE = 'WATCHADD_FAILURE';

export const closeModal = () => ({
  type: WATCHADD_CLOSE
})

export const openModal = () => ({
  type: WATCHADD_OPEN
})

export const setPage = (page) => ({
  type: WATCHADD_SET_PAGE,
  page
})

export const setInitialized = () => ({
  type: WATCHADD_SET_INITIALIZED
})

export const setName = (name) => ({
  type: WATCHADD_SET_NAME,
  name
})


export const save = (watchCrit) => ({
  type: WATCHADD_SUBMIT,
  watchCrit
})

const initialState = {
  showModal: false,
  page: 1,
  isFresh: true,
  name: '',
  nameTouched: false
}


const watchAddReducer = (state = initialState, action) => {
  switch (action.type) {
    case WATCHADD_OPEN:
      return {
        ...state,
        showModal: true,
        isFresh: true
      }
    case WATCHADD_CLOSE:
      return initialState;
    case WATCHADD_SET_PAGE:
      return {
        ...state,
        page: action.page
      }
    case WATCHADD_SET_INITIALIZED:
      return {
        ...state,
        isFresh: false
      }
    case WATCHADD_SET_NAME:
      return {
        ...state,
        name: action.name,
        nameTouched: true
      }
    default:
      return state

  }
}

export default watchAddReducer;
