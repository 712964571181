import log from 'loglevel';
import { put, takeEvery, call, select } from 'redux-saga/effects'
import { SITEPAGE_SET_PULSING_RUBRIC } from 'Admin/SitesManage/SitesManageDuck';

import {
  SITE_ACTIVATE_SUBMIT,
  SITE_ACTIVATE_SUCCESS,
  SITE_ACTIVATE_FAILURE,
  BULK_SITES_ACTIVATE_SUBMIT,
  BULK_SITES_ACTIVATE_SUCCESS,
  BULK_SITES_ACTIVATE_FAILURE,
} from './SiteAdminDuck'
import { SITEPAGE_SET_BULK_TOGGLE } from 'Admin/SitesManage/SitesManageDuck';
import fetchApi from 'utils/fetchApi';

export function* activateSiteAdminSaga (action) {
  try {
    // redux-form-submit-saga injects 'payload' into action
    const {siteId, active} = action;
    const url = `/Sites/${siteId}/${active?'activate':'deactivate'}`;
    const authToken = yield select((state) => state.login.authToken);

    const parsedJson = yield call(fetchApi, url, { method:'POST', authToken })

    if (!parsedJson.error){
      yield put({ type: SITE_ACTIVATE_SUCCESS, siteId, active })
      yield put({ type: '@@redux-form/RESET', meta: {'form': 'SiteAdminForm'}  })
      yield put({ 
        type: 'SITEPAGE_SET_PULSING_RUBRIC', 
        rubric: active ? 'syncwait' : 'inactive' 
      })
    } else {
      yield put({
        type: SITE_ACTIVATE_FAILURE,
        message: parsedJson.error.text._error ? parsedJson.error.text._error : parsedJson.error.text,
        payload: parsedJson.error.text
      })
    }
  } catch (error) {
    log.error('activateSiteAdminSaga error', error)
    yield put({
      type: SITE_ACTIVATE_FAILURE,
      message: 'Network error.'
    })
  }
}

// const delay = (ms) => new Promise(res => setTimeout(res, ms))
export function* activateAllSitesAdminSaga(action) {
  try {
    // redux-form-submit-saga injects 'payload' into action
    const {companyId, siteIds, active} = action;
    const authToken = yield select((state) => state.login.authToken);

    if (!companyId || !siteIds || !siteIds.length){
      yield put({
        type: BULK_SITES_ACTIVATE_FAILURE,
        message: 'not all params set'
      })
    }

    // log.debug(`saga before '${active}' ${siteIds.length} sites for companyId`, companyId,
    // 'siteIds', siteIds)

    const url = `/Companies/${companyId}/sites/${active ? 'activate' : 'deactivate'}`;

    const parsedJson = yield call(fetchApi, url, { authToken, payload: {siteIds} })

    if (!parsedJson.error){

      const handledSiteIds = active ? parsedJson.activatedSiteIds : siteIds;

      yield put({ type: BULK_SITES_ACTIVATE_SUCCESS, companyId, active, 
        siteIds: handledSiteIds,
        data: parsedJson 
      })

      yield put({ type: SITEPAGE_SET_BULK_TOGGLE, bulkToggleState: undefined })

      if(handledSiteIds.length)
        yield put({ 
          type: SITEPAGE_SET_PULSING_RUBRIC, 
          rubric: active ? 'syncwait' : 'inactive' 
        })

    } else {
      log.error('activateAllSitesAdminSaga error', JSON.stringify(parsedJson.error.text))
      yield put({
        type: BULK_SITES_ACTIVATE_FAILURE,
        message: parsedJson.error.text._error ? parsedJson.error.text._error : parsedJson.error.text,
        payload: parsedJson.error.text
      })
    }
  } catch (error) {
    log.error('activateAllSitesAdminSaga error', error)
    yield put({
      type: BULK_SITES_ACTIVATE_FAILURE,
      message: 'Network error.'
    })
  }
}


// listen for actions of type SITEADMIN_SUBMIT and use them
export default function* siteAdminSaga () {
  yield takeEvery(SITE_ACTIVATE_SUBMIT, activateSiteAdminSaga)
  yield takeEvery(BULK_SITES_ACTIVATE_SUBMIT, activateAllSitesAdminSaga)
}

