import log from 'loglevel';
import * as io from 'socket.io-client';
import { eventChannel } from 'redux-saga';
import {
  LOGVIEWER_ADD_ROOM,
  LOG_RECORD,
  LOG_BATCH
} from 'Admin/LogViewer/LogViewerDuck';

const SOCKET_CONNECTED = 'connect';
const SOCKET_DISCONNECTED = 'disconnect';
const AUTH_SUCCESS = 'auth_succeeded';
const RCV_LOGRECORD = 'logrecord';
const RCV_BATCH = 'batch';
const ROOMS_LIST = 'list_rooms';
const ROOMS_RESULT = 'rooms';
const ROOM_JOIN = 'join_room';
const ROOM_JOIN_SUCCESS = 'joined_room';

export const BACKLOG_SIZE = 300;

export function createSocketConnection(url) {
  const socket = io(`${url}/journalctl`);
  socket.url = url || 'localhost';
  return socket;
}

export function createSocketChannel(socket, authToken) {
  // `eventChannel` takes a subscriber function
  // the subscriber function takes an `emit` argument to put messages onto the channel
  return eventChannel(emit => {
    socket.on(SOCKET_CONNECTED, () => {
      log.debug('Socket connected');
      socket.emit('auth', authToken);
    });

    socket.on(SOCKET_DISCONNECTED, () => {
      log.debug('Socket disconnected');
    });

    socket.on(AUTH_SUCCESS, msg => {
      log.debug('Socket auth success');
      socket.emit(ROOMS_LIST);
    });

    socket.on(ROOMS_RESULT, rooms => {
      log.debug(`Socket rooms socket ID ${socket.id}`, rooms);
      Object.keys(rooms).forEach(room => socket.emit(ROOM_JOIN, room));
    });

    socket.on(ROOM_JOIN_SUCCESS, room => {
      emit({
        type: LOGVIEWER_ADD_ROOM,
        room: { [room.roomId]: room }
      });
      socket.emit('getbatch', { roomId: room.roomId, size: BACKLOG_SIZE });
    });

    socket.on(RCV_LOGRECORD, logrec => {
      emit({ type: LOG_RECORD, socketId: socket.id, logrec });
    });

    socket.on(RCV_BATCH, ( batchArr, roomId ) => {
      emit({ type: LOG_BATCH, socketId: socket.id, batchArr, roomId });
    });

    // the subscriber must return an unsubscribe function
    // this will be invoked when the saga calls `channel.close` method
    const unsubscribe = () => {
      log.debug('Socket unsubscribe');
      socket.disconnect();
    };

    return unsubscribe;
  });
}
