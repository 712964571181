import log from 'loglevel';
import { put, takeEvery, call, select } from 'redux-saga/effects'
import fetchApi from 'utils/fetchApi'
import { WATCHLISTDATA_SUBMIT, WATCHLISTDATA_SUCCESS } from './WatchlistDuck'

function* fetchWatchlistsCall(){
    try{
      const authToken = yield select((state) => state.login.authToken);
      const url = `/WatchLists`
      const watchlistsParsedJson = yield call(fetchApi, url, {
          authToken,
          method: 'GET'
      })

      if (!watchlistsParsedJson.error){
        yield put({
            type: WATCHLISTDATA_SUCCESS,
            payload : watchlistsParsedJson
        });
      } else {
        log.warn(watchlistsParsedJson.error.text._error ? watchlistsParsedJson.error.text._error : watchlistsParsedJson.error.text)
      }
    } catch(error){
      log.error('fetchWatchlistsCall', error)
    }
}

export default function* personSaga () {
  yield takeEvery(WATCHLISTDATA_SUBMIT, fetchWatchlistsCall)
}
